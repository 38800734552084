const auctionMsgReducer = (state = { auctionMessage: {} }, action) => {
    switch (action.type) {
        case 'AUCTIONMSG':
            return {
                ...state,
                auctionMessage: action.list.allmsg,
            }
        default:
            return state
    }
}

export default auctionMsgReducer
