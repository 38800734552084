const lotSearchReducer = (state = { allLotdetails: {} }, action) => {
    switch (action.type) {
        case 'LOTSEARCH':
            return {
                ...state,
                allLotdetails: action.list,
            }
        default:
            return state
    }
}

export default lotSearchReducer
