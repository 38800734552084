const rapidSearchReducer = (
    state = {
        rapidSearch: [],
    },
    action,
) => {
    switch (action.type) {
        case 'RAPIDSEARCH':
            console.log('action types', action.list)
            return {
                ...state,
                rapidSearch: action.list,
            }
        default:
            return state
    }
}

export default rapidSearchReducer
