import axios from '../Utility/axiosconfig'
const createBankStripe = async (body) => {
    const data_res = await axios.post(`/plugin/ach/stripe/customer/createCustomerDetails`, body)
    return data_res
}
const BuyerAddBank = async (body) => {
    const data_res = await axios.post(`plugin/ach/stripe/bank/createBankDetails`, body)
    return data_res
}
const verifyBankdetails = async (body) => {
    const data_res = await axios.post(`plugin/ach/stripe/payment/verifyBankDetails`, body)
    return data_res
}
export { createBankStripe, BuyerAddBank, verifyBankdetails }
