import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from '../../Utils/Utility/axiosconfig'
import Swal from 'sweetalert2'
import './verification.css'
var qs = require('qs')

const Verification = (props) => {
    const history = useHistory()

    const userid = qs.parse(props.location.search, { ignoreQueryPrefix: true }).userId
    console.log(userid)

    const userVerification = async () => {
        const res = await axios.get(`activate/${userid}`)
        if (res.data.success === true) {
            Swal.fire({
                title: res.data.message,
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })
            setTimeout(() => {
                history.push('/login')
            }, 1000)
        }
    }

    useEffect(() => {
        userVerification()
    })

    return <div></div>
}

export default Verification
