import axios from 'axios'
import isAuthenticate from './auth'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_PAY,
    responseType: 'json',
    headers: {
        domain: 'coinsauction.moblearn.net',
    },
})

export default axiosInstance
