import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton/index'
import { Formik } from 'formik'
import * as yup from 'yup'
import valid from 'card-validator'
import axios from '../../Utils/Utility/axiosconfig'
import AuthContainer from '../../components/organisms/AuthContainer'
import Swal from 'sweetalert2'
import isAuthenticated from '../../Utils/Utility/auth'
import './carddetails.css'
import Country from './Country.json'
import State from './State.json'
import cities from './cities.json'
import SimpleSelect from '../../components/atoms/DropDown/index'
import SimpleSelect1 from '../../components/atoms/DropDown/Dropdown1'
import SimpleSelect2 from '../../components/atoms/DropDown/Dropdown2'
import payAmountStripe from '../../Utils/API/payamountstripe'

const CardDetails = (props) => {
    useEffect(() => {
        if (isAuthenticated()) {
            history.push('/')
        }
    }, [])

    const [Id, setID] = useState('')
    const [sId, setStateId] = useState('')
    const [Countrycode, setCountrycode] = useState('')

    const cardDetailSchema = yup.object({
        cardnumber: yup
            .string()
            .min(15, 'Credit Card number is invalid')
            .max(16, 'Credit Card number is invalid')
            .required(),
        mm: yup
            .string()
            .typeError('Not a valid expiration date')
            .matches(/^(0?[1-9]|1[012])$/, 'Not a valid expiration date')
            .required('Expiration month is required'),
        yyyy: yup
            .string()
            .typeError('Not a valid expiration date')
            .matches(/^(20)\d{2}$/, 'Not a valid Expiration year')
            .required('Expiration year is required'),
        Name: yup
            .string()
            .required()
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
        // cvv: yup.string().required().min(3, 'Invalid Cvv').max(4, 'Invalid Cvv'),
        cvv: yup
            .string()
            .required()
            .matches(/^([0-9]{3,4})$/, 'Invalid Cvv'),
        // zipcode: yup
        //     .string()
        //     .min(5, 'Invalid Zip Code')
        //     .max(6, 'Invalid Zip Code')
        //     .matches(/^[0-9]+$/, 'Only numbers are allowed for this field')
        //     .required('Zip Code Required'),
        // addressline1: yup.string().required('Enter your Address1'),
        // addressline2: yup.string(),
        // city: yup.string(),
        // state: yup.string().required('State is required'),
        // country: yup.string().required('Country is required'),
    })

    const history = useHistory()

    const UserRegistration = async (data) => {
        try {
            const { cardnumber, mm, yyyy, Name, cvv } = data

            const det = history?.location?.state?.personal
                ? history.location.state.personal
                : localStorage.getItem('signup')
                ? JSON.parse(localStorage.getItem('signup'))
                : {}

            const { country, state, city, zipcode, address1 } = det
            let body = {
                account_id: process.env.REACT_APP_AUCTIONPAYKEY,
                card_number: cardnumber,
                card_name: Name,
                card_cvc: cvv,
                card_exp_month: mm,
                card_exp_year: yyyy,
                card_address_line1: address1,
                card_address_line2: address1,
                card_address_city: city,
                card_address_state: state,
                card_address_zip: zipcode,
                card_address_country: country,
                mode: process.env.REACT_APP_PAYMENTMODE,
                isSave: true,
                email: det.email,
            }

            const isSuccess = await payAmountStripe(body)

            if (isSuccess.data.status === 'success') {
                // console.log(
                //     'iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii',
                //     isSuccess.data.data.responseData.customer,
                // )
                det.card_paymentid = isSuccess.data.data.responseData.customer
                const res = await axios.post('mobileapi/register', det)
                if (res.data.success === 'no') {
                    return Swal.fire({
                        icon: 'error',
                        title: res.data.message,
                        timer: 1500,
                        showConfirmButton: false,
                        position: 'center',
                    })
                }
                localStorage.clear('signup')
                await history.push('/login')
                return Swal.fire({
                    icon: 'success',
                    title: 'Thanks for Registration',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            } else {
                Swal.fire({
                    title: isSuccess.data.message,
                    icon: 'error',
                    timer: 2500,
                    showConfirmButton: false,
                    position: 'center',
                })
            }
        } catch (error) {
            console.log(error)
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Try again later!',
            //     timer: 1500,
            //     showConfirmButton: false,
            //     position: 'center',
            // })
        }
    }

    return (
        <AuthContainer authPage="signup-page">
            <Formik
                initialValues={{
                    cardnumber: '',
                    mm: '',
                    yyyy: '',
                    Name: '',
                    cvv: '',
                    zipcode: '',
                    addressline1: '',
                    addressline2: '',
                    country: '',
                    state: '',
                    city: '',
                }}
                validationSchema={cardDetailSchema}
                onSubmit={(values) => UserRegistration(values)}
            >
                {({ touched, errors, values, handleChange, handleSubmit, handleBlur }) => (
                    <form onSubmit={handleSubmit}>
                        <h1 className="auth-title">Card Details</h1>
                        <div className="cardInput">
                            <div className="row">
                                <div
                                    className={`col-md-6 ${
                                        errors.cardnumber && touched.cardnumber ? 'error-input' : ''
                                    }`}
                                >
                                    <CustomInput
                                        label="Card Number"
                                        variant="standard"
                                        name="cardnumber"
                                        onChange={(e) => {
                                            if (e.target.value.length > 16) {
                                                e.preventDefault()
                                            } else {
                                                handleChange(e)
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        value={values.cardnumber}
                                        inputProps={{ maxLength: 16 }}
                                        helperText={
                                            errors.cardnumber && touched.cardnumber
                                                ? 'Incorrect Card Number'
                                                : ''
                                        }
                                        type="number"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <CustomInput
                                        label="Name On the Card"
                                        variant="standard"
                                        name="Name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={
                                            errors.Name && touched.Name
                                                ? 'Please Enter your Name'
                                                : ''
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <CustomInput
                                        label="MM"
                                        variant="standard"
                                        name="mm"
                                        onChange={(e) => {
                                            if (e.target.value.length > 2) {
                                                e.preventDefault()
                                            } else {
                                                handleChange(e)
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        value={values.mm}
                                        inputProps={{ maxLength: 2 }}
                                        helperText={
                                            errors.mm && touched.mm ? 'Invalid Expiry Month' : ''
                                        }
                                        type="number"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <CustomInput
                                        label="YYYY"
                                        variant="standard"
                                        name="yyyy"
                                        onChange={(e) => {
                                            if (e.target.value.length > 4) {
                                                e.preventDefault()
                                            } else {
                                                handleChange(e)
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        inputProps={{ maxLength: 4 }}
                                        helperText={
                                            errors.yyyy && touched.yyyy ? 'Invalid Expiry Year' : ''
                                        }
                                        value={values.yyyy}
                                        type="number"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <CustomInput
                                        label="CVV"
                                        variant="standard"
                                        name="cvv"
                                        onChange={(e) => {
                                            const re = /^[0-9\b]+$/
                                            if (
                                                e.target.value.length > 4 ||
                                                !re.test(e.target.value)
                                            ) {
                                                e.preventDefault()
                                            } else {
                                                handleChange(e)
                                            }
                                        }}
                                        value={values.cvv}
                                        onBlur={handleBlur}
                                        inputProps={{ maxLength: 4 }}
                                        helperText={errors.cvv && touched.cvv ? 'Invalid cvv' : ''}
                                        type="text"
                                    />
                                </div>

                                {/* <div className="registration-inp col-sm-6">
                                    <SimpleSelect
                                        dropCountr={Country}
                                        onChange={handleChange}
                                        countryID={(data) => setID(data)}
                                        ctrcode={(data) => setCountrycode(data)}
                                        value={values.country}
                                        name="country"
                                        label="Country"
                                    />

                                   
                                    {errors.country && touched.country ? (
                                        <div className="errorMessage-2 errorTxt">
                                            {errors.country}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="registration-inp col-sm-6">
                                    <SimpleSelect1
                                        value={values.state}
                                        onChange={handleChange}
                                        countryID={(data) => setID(data)}
                                        ctrcode={(data) => setCountrycode(data)}
                                        name="state"
                                        label="State"
                                        dropState={State.filter(
                                            (data) => data.country_id === Id.toString(),
                                        )}
                                        stateID={(data) => setStateId(data)}
                                    />
                                   
                                    {errors.state && touched.state ? (
                                        <div className="errorMessage-2 errorTxt">
                                            {errors.state}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="registration-inp col-sm-6">
                                   
                                    <SimpleSelect2
                                        value={values.city}
                                        onChange={handleChange}
                                        name="city"
                                        label="City"
                                        dropCity={cities.filter(
                                            (data) => data.state_id === sId.toString(),
                                        )}
                                    />
                                    {errors.city && touched.city ? (
                                        <div className="errorMessage-2 errorTxt">{errors.city}</div>
                                    ) : null}
                                </div>

                                <div className="registration-inp col-sm-6">
                                    <CustomInput
                                        variant="standard"
                                        label="Zip Code"
                                        name="zipcode"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.zipcode}
                                        inputProps={{ maxLength: 6 }}
                                    />
                                    {errors.zipcode && touched.zipcode ? (
                                        <div className="errorMessage-2 errorTxt">
                                            {errors.zipcode}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="registration-inp col-sm-6">
                                    <CustomInput
                                        variant="standard"
                                        label="Address Line 1"
                                        name="addressline1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.addressline1}
                                    />
                                    {errors.addressline1 && touched.addressline1 ? (
                                        <div className="errorMessage-1 errorTxt">
                                            {errors.addressline1}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="registration-inp col-sm-6">
                                    <CustomInput
                                        variant="standard"
                                        label="Address Line 2"
                                        name="addressline2"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.addressline2}
                                    />
                                    {errors.addressline2 && touched.addressline2 ? (
                                        <div className="errorTxt">{errors.addressline2}</div>
                                    ) : null}
                                </div> */}
                            </div>
                        </div>
                        <PrimaryButton label="Sign Up" type="submit" />
                        <div className="bottomText signin-Instead">
                            <a href="/login">Sign in Instead</a>
                        </div>
                    </form>
                )}
            </Formik>
        </AuthContainer>
    )
}

export default CardDetails
