import React, { useState, useEffect } from 'react'
import moment from 'moment'
import axios from 'axios'
import { socket } from './socket.js'
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers'
import CustomSelect from '../components/atoms/Inputs/CustomSelect'
import CustomMultiSelect from '../components/atoms/Inputs/CustomMultiSelect'
import MenuItem from '@material-ui/core/MenuItem'
import CustomInput from '../components/atoms/Inputs/CustomInput'
import CustomPhone from '../components/atoms/Inputs/CustomPhone.js'

import CheckBox from '../components/atoms/CheckBox'
import CustomTextArea from '../components/atoms/Inputs/CustomTextArea'
import CKEditor from 'ckeditor4-react'

// import { CKEditor } from '@ckeditor/ckeditor5-react'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

let serverTime = new Date()
const dateFormat = 'MM-DD-YYYY'
const dateTimeFormat = 'MM-DD-YYYY h:mm a'

// if (socket) {
//     socket.on('sliservertime', (data) => {
//         if (moment(data.dTime).isValid()) {
//             serverTime = new Date(data.dTime)
//         }
//     })
// }

export const handleRedirectInternal = (history, path, search) => {
    history.push({ pathname: `/${path}`, search: search ? search : '' })
    window.scrollTo(0, 0)
}

export const mapData = (page) => {
    let data = page.map((data, index) => (
        <div key={index} className={data.class}>
            {data.type === 'select' ? (
                <>
                    <CustomSelect
                        variant="standard"
                        label={data.label}
                        id={data.id}
                        value={data.formik.values[data.name]}
                        autoFocus={data.autoFocus}
                        name={data.name}
                        onChange={data.onChange ? data.onChange : data.formik.handleChange}
                        placeholder={data.placeholder}
                        onBlur={data.formik.handleBlur}
                        type={data.type}
                        error={data.formik.touched[data.name] && data.formik.errors[data.name]}
                        helperText={
                            data.formik.errors[data.name] &&
                            data.formik.touched[data.name] &&
                            data.formik.errors[data.name]
                        }
                    >
                        {data.options.map((opt, optindex) => (
                            <option key={optindex} value={opt.name}>
                                {opt.name}
                            </option>
                        ))}
                    </CustomSelect>
                </>
            ) : data.type === 'phone' ? (
                <>
                    <CustomPhone
                        id={data.id}
                        value={data.formik.values[data.name]}
                        autoFocus={data.autoFocus}
                        name={data.name}
                        disabled={data.disabled}
                        shrink={data.formik.values[data.name] && true}
                        onBlur={data.formik.handleBlur}
                        onChange={(phone) => data.formik.setFieldValue(data.name, phone)}
                        label={data.label}
                        placeholder={data.placeholder}
                        type={data.type}
                        error={data.formik.touched[data.name] && data.formik.errors[data.name]}
                        helperText={
                            data.formik.errors[data.name] &&
                            data.formik.touched[data.name] &&
                            data.formik.errors[data.name]
                        }
                    />
                </>
            ) : data.type === 'multiselect' ? (
                <>
                    <CustomMultiSelect
                        label={data.label}
                        id={data.id}
                        value={data.formik.values[data.name]}
                        autoFocus={data.autoFocus}
                        name={data.name}
                        onChange={data.onChange ? data.onChange : data.formik.handleChange}
                        placeholder={data.placeholder}
                        onBlur={data.formik.handleBlur}
                        type={data.type}
                        error={data.formik.touched[data.name] && data.formik.errors[data.name]}
                        helperText={
                            data.formik.errors[data.name] &&
                            data.formik.touched[data.name] &&
                            data.formik.errors[data.name]
                        }
                    >
                        {data.options.map((opt, optindex) => (
                            <MenuItem key={optindex} value={opt.value}>
                                {opt.show}
                            </MenuItem>
                        ))}
                    </CustomMultiSelect>
                </>
            ) : data.type === 'ckeditor' ? (
                <>
                    {/* <CKEditor
                        editor={ClassicEditor}
                        data={data.formik.values[data.name] ? data.formik.values[data.name] : ''}
                        onChange={(event, editor) => {
                            data.formik.setFieldValue(data.name, editor.getData())
                        }}
                    /> */}
                    <CKEditor
                        data={data.formik.values[data.name]}
                        onChange={(event, editor) => {
                            data.formik.setFieldValue(data.name, event.editor.getData())
                        }}
                    />
                </>
            ) : data.type === 'date' ? (
                <>
                    <KeyboardDatePicker
                        margin="0"
                        autoOk={true}
                        showTodayButton={true}
                        id={data.name}
                        inputVariant="outlined"
                        label={data.label}
                        format={dateFormat}
                        disabled={data.disabled}
                        className="customDatepicker"
                        value={moment()}
                        inputValue={data.formik.values[data.name]}
                        onChange={(val) => {
                            data.formik.setFieldValue(data.name, moment(val).format(dateFormat))
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </>
            ) : data.type === 'datetime' ? (
                <>
                    <KeyboardDateTimePicker
                        margin="0"
                        autoOk={true}
                        showTodayButton={true}
                        id={data.name}
                        inputVariant="outlined"
                        label={data.label}
                        format={dateTimeFormat}
                        disabled={data.disabled}
                        className="customDatepicker"
                        value={moment()}
                        inputValue={data.formik.values[data.name]}
                        onChange={(val) => {
                            data.formik.setFieldValue(data.name, moment(val).format(dateTimeFormat))
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </>
            ) : data.type === 'textarea' ? (
                <>
                    <CustomTextArea
                        id={data.id}
                        value={data.formik.values[data.name]}
                        autoFocus={data.autoFocus}
                        name={data.name}
                        disabled={data.disabled}
                        shrink={data.formik.values[data.name] && true}
                        onBlur={data.formik.handleBlur}
                        onChange={data.onChange ? data.onChange : data.formik.handleChange}
                        label={data.label}
                        placeholder={data.placeholder}
                        type={data.type}
                        error={data.formik.touched[data.name] && data.formik.errors[data.name]}
                        helperText={
                            data.formik.errors[data.name] &&
                            data.formik.touched[data.name] &&
                            data.formik.errors[data.name]
                        }
                    />
                </>
            ) : data.type === 'checkbox' ? (
                <>
                    <CheckBox
                        checked={data.formik.values[data.name]}
                        label={data.label}
                        value="true"
                        onChange={() => {
                            data.formik.setFieldValue(data.name, !data.formik.values[data.name])
                        }}
                        name={data.name}
                    />
                </>
            ) : (
                <>
                    <CustomInput
                        id={data.id}
                        value={data.value ? data.value : data.formik.values[data.name]}
                        autoFocus={data.autoFocus}
                        name={data.name}
                        disabled={data.disabled}
                        shrink={data.formik.values[data.name] && true}
                        onBlur={data.formik.handleBlur}
                        onChange={data.onChange ? data.onChange : data.formik.handleChange}
                        label={data.label}
                        placeholder={data.placeholder}
                        type={data.type}
                        startAdornment={data.startAdornment}
                        endAdornment={data.endAdornment}
                        error={data.formik.touched[data.name] && data.formik.errors[data.name]}
                        helperText={
                            data.formik.errors[data.name] &&
                            data.formik.touched[data.name] &&
                            data.formik.errors[data.name]
                        }
                    />
                </>
            )}
        </div>
    ))
    return data
}

// export const handleRedirectInternal = (history, path) => {
//     history.push(`/${path}`)
//     window.scrollTo(0, 0)
// }
export const dateFormatFront = (data) => {
    return moment(data).isValid()
        ? `${moment(data).format('MMM Do')}, ${moment(data).format('h:mm a')} PT`
        : '-'
}

export const dateFormatFrontDay = (data) => {
    return `${moment(data).format('MMM Do YYYY')}`
}

export const capitalize = (data) => {
    return data.charAt(0).toUpperCase() + data.slice(1)
}

export const currencyFormat = (data) => {
    return `US $${parseFloat(data).toFixed(2).toLocaleString('en-US')}`
}

export const dateFormatFunction = (data) => {
    return `${moment(data).format(dateFormat)}`
}

export const dateTimeFormatFunction = (data) => {
    return `${moment(data).format(dateTimeFormat)}`
}

export const checkProductOpen = (date_added, user_role) => {
    let startDate = new Date(serverTime)
    let addDate = new Date(date_added)
    let milliSeconds = 0
    // if (parseInt(user_role) !== 3) {
    //   milliSeconds = 1 * 3600000;
    // } else {
    // }
    let incrementedTime = addDate.getTime() + parseInt(milliSeconds)
    let newaddDate = new Date(incrementedTime)
    if (newaddDate > startDate) {
        return false
        // let incrementedTime = addDate.getTime() + parseInt(milliSeconds);
        // let timerTime = incrementedTime - startDate.getTime();
        // if (timerTime <= 0) {
        //   return true;
        // } else {
        //   return false;
        // }
    } else {
        return true
    }
}

export const maskEmailFront = (data) => {
    data = data.toString()
    var first4 = data.substring(0, 4)
    var last5 = data.substring(data.length - 5)
    var mask = data.substring(4, data.length - 5).replace(/[^]/g, '*')
    return first4 + mask + last5
}

export const DirectAPICAll = (method, url) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (method === 'get') {
                let res = await axios.get(`${url}`)
                console.log('responsode from api', res)
                resolve(res)
            } else if (method === 'post') {
                let res = await axios.post(`${url}`)
                resolve(res)
            }
        } catch (err) {
            console.log('responsode error from api', err)
            resolve(err)
        }
    })
}

export const useCustomMediaQuery = (queryString) => {
    const [isMatch, setIsMatch] = useState(false)
    const mqChange = (mq) => {
        setIsMatch(mq.matches)
    }

    useEffect(() => {
        const mq = window.matchMedia(queryString)
        mq.addListener(mqChange)
        mqChange(mq)

        return () => {
            mq.removeListener(mqChange)
        }
    }, [queryString])

    return isMatch
}
