import axios from 'axios'
import isAuthenticate from './auth'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_DOMAIN,
    responseType: 'json',
    headers: {
        // Authorization: `Bearer ${
        //     localStorage.getItem('userToken') !== null ? localStorage.getItem('userToken') : ''
        // }`,
        domain: 'coinsauction.moblearn.net',
    },
})

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('userToken')
        if (token) {
            config.headers.authorization = 'Bearer ' + token
        }
        return config
    },
    (error) => Promise.reject(error),
)

export default axiosInstance
