import axios from '.././Utils/Utility/axiosconfig'
import isAuthenticate from '.././Utils/Utility/auth'

export const rapidSearchAction = (list) => ({
    type: 'RAPIDSEARCH',
    list,
})

const rapidSearch = (body) => {
    return async (dispatch) => {
        try {
            const res = await axios.post('mobileapi/get_search_keyword_title', body)
            if (res.data.success === true) {
                dispatch(rapidSearchAction(res.data.results))
            } else {
                return `something went wrong`
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export default rapidSearch
