import React, { useContext, useEffect, useState } from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, Popover, ListItem } from '@material-ui/core'
import isAuthenticate from '../../../Utils/Utility/auth'
function Footer() {
    const [anchormenu, setanchormenu] = useState(null)
    const [display_submenu, setSubmenu] = useState(true)

    const state_loc = useSelector((state) => state.storeDetails)

    if (state_loc.storeDetails.nav_menu) {
        var nav_menu = state_loc.storeDetails.nav_menu
    } else {
        var nav_menu = []
    }
    if (nav_menu.length > 0) {
        var footer_menu = nav_menu.filter((val) => val.menu_type == 'footer')
    } else {
        var footer_menu = []
    }

    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleClose = () => {
        setanchormenu(null)
    }

    return (
        <>
            <div className="footerCnt">
                <div className="hmcustomContainer d-flex align-items-start justify-content-between flex-wrap">
                    <div className="lftFtrLgnCntn">
                        <a href={'/'} className="footlogo">
                            <img className="Logo" src="/images/Logowhite.png" alt="Kzoobullion" />
                        </a>
                        {/* <p className="pgTgInfoLn">LICENSED | BONDED | INSURED</p> */}
                        <div className="scmlMdaLnks">
                            <div className="mdaWrpr fbLnk">
                                <a
                                    href="https://www.facebook.com/people/Kzoo-Bullion/100078354872857/?_ga=2.106915843.1820215765.1675491310-2039143036.1675491310&_gac=1.56975064.1675507339.Cj0KCQiA8t2eBhDeARIsAAVEga1i8jSYx9BrMh1m5YbbdnEq3x0SUVYTkTuWHKsypYZ3a9HwqgH5cTUaArcSEALw_wcB"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src="/images/fb_footer.svg" />
                                </a>
                            </div>
                            {/* <div className="mdaWrpr twLnk">
                                <a
                                    href="https://twitter.com/CoinsSince1994?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src="/images/twitter_footer.svg" />
                                </a>
                            </div> */}
                        </div>
                    </div>
                    <div className="rtCntIntoWrpr">
                        <div className="termWrp">
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/contactus">Contact Us</Link>
                                </li>

                                <>
                                    {footer_menu ? (
                                        <>
                                            {footer_menu.length > 0
                                                ? footer_menu.map((val) =>
                                                      val.subLinks.length > 0 ? (
                                                          <li>
                                                              <div className="nav-menu-from-store">
                                                                  <a
                                                                      //   target={'_blank'}
                                                                      //   rel="noreferrer"
                                                                      className="active"
                                                                      href={val.path}
                                                                  >
                                                                      {val.label}
                                                                  </a>

                                                                  <Button
                                                                      aria-controls="simple-menu"
                                                                      aria-haspopup="true"
                                                                      onClick={handleMenu}
                                                                  >
                                                                      {!Boolean(anchormenu) ? (
                                                                          <span className="material-icons-outlined">
                                                                              expand_more
                                                                          </span>
                                                                      ) : (
                                                                          <span className="material-icons-outlined">
                                                                              expand_less
                                                                          </span>
                                                                      )}
                                                                  </Button>
                                                              </div>
                                                              <Popover
                                                                  id={'simple-menu'}
                                                                  open={Boolean(anchormenu)}
                                                                  anchorEl={anchormenu}
                                                                  onClose={handleClose}
                                                                  anchorOrigin={{
                                                                      vertical: 'bottom',
                                                                      horizontal: 'right',
                                                                  }}
                                                                  transformOrigin={{
                                                                      vertical: 'top',
                                                                      horizontal: 'right',
                                                                  }}
                                                              >
                                                                  <ul className="headerDropMenu">
                                                                      {val.subLinks.map((data) => (
                                                                          <a
                                                                              rel="noreferrer"
                                                                              //target={'_blank'}
                                                                              //className="active"
                                                                              href={data.path}
                                                                          >
                                                                              <ListItem>
                                                                                  {data.label}
                                                                              </ListItem>
                                                                          </a>
                                                                      ))}
                                                                  </ul>
                                                              </Popover>
                                                          </li>
                                                      ) : (
                                                          <li>
                                                              <a
                                                                  //   target={'_blank'}
                                                                  //   rel="noreferrer"
                                                                  className="active"
                                                                  href={val.path}
                                                              >
                                                                  {val.label}
                                                              </a>
                                                          </li>
                                                      ),
                                                  )
                                                : ''}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </>
                                {/* <li>
                                    <Link to="/aboutus">About Us</Link>
                                </li> */}
                            </ul>
                            <div className="mlInfoTxt text-left">
                                <a href="mailto:info@kzoobullion.com">info@kzoobullion.com</a>
                            </div>
                        </div>
                        <div className="adrsInfoWrpr text-left">
                            <p className="stnTtle">FIND US HERE</p>
                            {/* <div className="flxGrdVwRp">
                                <img src="/images/footer_user.svg" />
                                <div className="rtCrsTxt">
                                    <p>Ken & Takumi</p>
                                </div>
                            </div> */}
                            <div className="flxGrdVwRp">
                                <img src="/images/footer_company.svg" />
                                <div className="rtCrsTxt">
                                    <p>Kzoobullion</p>
                                </div>
                            </div>
                            <div className="flxGrdVwRp">
                                <img src="/images/footer_location.svg" />
                                <div className="rtCrsTxt">
                                    <p>
                                        3333 E Patrick Rd
                                        <br />
                                        Midland, MI 48642
                                    </p>
                                </div>
                            </div>
                            <div className="flxGrdVwRp">
                                <img src="/images/footer_phone.svg" />
                                <div className="rtCrsTxt">
                                    <p>
                                        Call us: <a href="tel:9894231018">989-423-1018</a>
                                    </p>
                                    {/* <p>
                                        Text: <a href="tel:3602815588">(360) 281-5588</a>
                                    </p>
                                    <p>
                                        Local: <a href="tel:3602535565">(360) 253-5565</a>
                                    </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={isAuthenticate() != null ? 'cpyRtFtr pb-76' : 'cpyRtFtr'}>
                <p>
                    ©{new Date().getFullYear()} Kzoobullion |{' '}
                    <Link to="/TermsandConditions">Terms & Conditions</Link>
                </p>
            </div>
        </>
    )
}

export default Footer
