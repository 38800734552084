const storeDetailsReducer = (
    state = {
        storeDetails: [],
    },
    action,
) => {
    switch (action.type) {
        case 'STOREDETAILS':
            console.log('action types', action)
            return {
                ...state,
                storeDetails: action.list,
            }
        default:
            return state
    }
}

export default storeDetailsReducer
