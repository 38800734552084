import axios from '.././Utils/Utility/axiosconfig'
import isAuthenticate from '.././Utils/Utility/auth'

export const auctionPercentageAuction = (list) => ({
    type: 'AUCTIONPERCENTAGE',
    list,
})

const auctionPercentage = (body) => {
    return async (dispatch) => {
        try {
            const res = await axios.post('api/getliveauctionpercentage', body)
            console.log('auctionpercentage details------>', res.data.data)
            if (res.data.status === 'success') {
                dispatch(auctionPercentageAuction(res.data.data))
            } else {
                return `something went wrong`
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export default auctionPercentage
