import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import './MobileListCard.css'
import { onImageError, setDecimalPoint } from '../../../Utils/Utility'
import { Chip } from '@material-ui/core'
import { currencyFormat } from '../../../common/components'
import Timer from '../Timer'

const MobileListCard = (props) => {
    return (
        <div className="mobileLstCard">
            <div className="imgNmCntnr d-flex align-items-start">
                <div className="imgCntnr">
                    <LazyLoadImage
                        className="cardImage"
                        src={props.imgSrc}
                        onError={onImageError}
                        onClick={props.onClick ? props.onClick : null}
                        effect="blur"
                        placeholderSrc="/images/imageLoading.svg"
                        height="100%"
                        width="100%"
                        alt="Next Lot"
                    />
                </div>
                <div className="rtSdInfo text-left w-100">
                    <h1
                        className="pdtNm"
                        onClick={props.onClick ? props.onClick : null}
                        dangerouslySetInnerHTML={{
                            __html: props.Name,
                        }}
                    ></h1>
                    <div className="d-flex align-items-center flex-wrap">
                        {props.lotId ? (
                            <Chip
                                className="mt-2 mr-1"
                                label={<p className="m-0">Lot Id: {props.lotId}</p>}
                            />
                        ) : (
                            ''
                        )}
                        {(props.lowest || props.highest) && (
                            <Chip
                                className="mt-2"
                                label={
                                    <p className="m-0">
                                        Est: {currencyFormat(props.lowest) || 100} -{' '}
                                        {currencyFormat(props.highest) || 1000}
                                    </p>
                                }
                            />
                        )}
                    </div>
                    <p
                        className="pdtDescrp mt-3"
                        dangerouslySetInnerHTML={{ __html: props.description }}
                    ></p>
                </div>
            </div>
            <div className="timerContainer">
                <hr />
                <Timer
                    productTime={{ start: props.startTime, end: props.endTime }}
                    timerBasic={props.timerBasic}
                />
                <hr />
            </div>
            {props.lotBidding && (
                <>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <p className="currPrice mb-0">
                            Current Price: <span> $ {setDecimalPoint(props.currentPrice, 2)}</span>
                        </p>
                        {props.bidCount !== 0 && (
                            <p
                                className="currPrice mb-0 cursorDecoy"
                                onClick={props.handleBidHistory}
                            >
                                {props.bidCount} Bids
                            </p>
                        )}
                    </div>
                    {/* {props.lotBidding} */}
                </>
            )}
        </div>
    )
}

export default MobileListCard
