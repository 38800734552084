const auctionSearchReducer = (state = { auctionData: {}, pagecount: {} }, action) => {
    switch (action.type) {
        case 'AUCTIONSEARCH':
            return {
                ...state,
                auctionData: action.list,
                pagecount: action.total_pagecnt,
            }
        default:
            return state
    }
}

export default auctionSearchReducer
