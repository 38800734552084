import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Loader from '../../components/molecules/Loader'

const TermsandConditions = () => {
    const state = useSelector((state) => state.storeDetails)
    console.log('state?.storeDetails?.storeDetails', state?.storeDetails)
    return (
        <div className="p-4 descFldDynTxt">
            <h1>Terms & Conditions</h1>
            <div
                className="container text-left mt-4"
                dangerouslySetInnerHTML={{
                    __html: state?.storeDetails?.results?.terms_condition,
                }}
            ></div>
        </div>
    )
}

export default TermsandConditions
