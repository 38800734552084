import React, { useState, useEffect } from 'react'
import SecondaryButton from '../../components/atoms/SecondaryButton/index'
import { isEmpty, findIndex } from 'lodash'
import Timer from '../../components/molecules/Timer/index'
import { useHistory } from 'react-router-dom'
import Pagination from '@material-ui/lab/Pagination'
import { getPaginationCount } from '../../Utils/Utility'
import Swal from 'sweetalert2'
import './ActiveAuction.css'

const ActiveAuction = (props) => {
    const history = useHistory()
    return (
        <div>
            <div className="Auction-Table table-responsive">
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Auction Name</th>
                            <th scope="col">Title</th>
                            {/* <th scope="col">Time left</th> */}
                            <th scope="col">Current Price</th>
                            <th scope="col">My Bid</th>
                            <th scope="col">My Max Bid</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isEmpty(props.data.results) ? (
                            props.data.results.map((data, index) => (
                                <tr>
                                    <td data-title="ID" scope="row" className="frstHdrRow">
                                        {data.sku}
                                    </td>
                                    <td data-title="Auction Name">{data.auctionlot_title}</td>
                                    <td data-title="Title">{data.title}</td>
                                    {/* <td className="timer-dashboard">
                                        <Timer
                                            productTime={{
                                                start: data.date_added,
                                                end: data.date_closed,
                                            }}
                                        />
                                    </td> */}
                                    <td data-title="Current Price" style={{ whiteSpace: 'pre' }}>
                                        US $ {data.latestbid}
                                    </td>
                                    <td data-title="My Bid" style={{ whiteSpace: 'pre' }}>
                                        $ {data.mybid}
                                    </td>
                                    <td data-title="My Max Bid" style={{ whiteSpace: 'pre' }}>
                                        $ {data.lastproxtamt}
                                    </td>
                                    <td data-title="Status" style={{ whiteSpace: 'pre' }}>
                                        {data.highest_bidder_user_id ==
                                        props.userDetails.userdetails.id
                                            ? 'Winning'
                                            : 'Losing'}
                                    </td>
                                    <td data-title="Action" className="vwBtnWrpr">
                                        <div className="dashActiveTable">
                                            <SecondaryButton
                                                label="View"
                                                onClick={() =>
                                                    history.push(
                                                        // encodeURI(`/auction/${data.lotof}?page=1`),
                                                        encodeURI(
                                                            data.con_check
                                                                ? `lotview/${data.lotof}/${
                                                                      data.id
                                                                  }/${
                                                                      props.userDetails.userdetails
                                                                          .id || 0
                                                                  }`
                                                                : `timedlotView/${data.lotof}/${
                                                                      data.id
                                                                  }/${
                                                                      props.userDetails.userdetails
                                                                          .id || 0
                                                                  }`,
                                                        ),
                                                    )
                                                }
                                            />
                                            {data.highest_bidder_user_id ==
                                                props.userDetails.userdetails.id &&
                                                new Date() < new Date(data.date_added) && (
                                                    <SecondaryButton
                                                        label="Retract"
                                                        onClick={() => {
                                                            Swal.fire({
                                                                title: 'Do you want to retract this bid?',
                                                                showCancelButton: true,
                                                                confirmButtonText: 'Retract',
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    props.retractBid(data)
                                                                }
                                                            })
                                                        }}
                                                    />
                                                )}
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colspan="12" className="border-none">
                                    <div className="card card-body text-center mt-2">
                                        <b>No Auction Found</b>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="active-pgn">
                <Pagination
                    onChange={(e, value) => {
                        props.paramsupd(value)
                    }}
                    count={
                        props.data.total_pagecnt
                            ? getPaginationCount(props.data.total_pagecnt, 10)
                            : '1'
                    }
                />
            </div>
        </div>
    )
}

export default ActiveAuction
