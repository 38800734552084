const liveLotsReducer = (state = { liveLots: {} }, action) => {
    switch (action.type) {
        case 'LIVELOTS':
            return {
                ...state,
                liveLots: action,
            }
        default:
            return state
    }
}

export default liveLotsReducer
