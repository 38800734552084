import React, { useEffect, useState } from 'react'
import CustomInput from '../../atoms/Inputs/CustomInput'
import PrimaryButton from '../../atoms/PrimaryButton'
import { chatarray, chatarrayclient } from '../../../Utils/API/chatapi'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import { socket } from '../../../common/socket'
import './Chat.css'

function Chat(props) {
    const [livechat, setlivechat] = useState([])
    const [Text, setText] = useState('')
    const setting = async () => {
        let data = await chatarray(props.pid)
        setlivechat(data)
    }

    useEffect(() => {
        setting()
    }, [])

    useEffect(() => {
        scollBottom()
    })

    function scollBottom() {
        var div = document.getElementById('inrWrppr')
        document.getElementById('otrWrpr').scrollBy(0, div.scrollHeight)
    }

    useEffect(() => {
        check()
    }, [props.messagesocket.chat])

    const check = () => {
        if (Array.isArray(props.messagesocket.chat)) {
            setlivechat((prevData) => [...prevData, ...props.messagesocket.chat])
        } else {
            props.messagesocket.chat.pro_id == props.pid &&
                setlivechat((prevData) => [...prevData, props.messagesocket.chat])
        }
    }

    const onChange = (e) => setText(e.target.value)

    const clientMessage = () => {
        const data = {
            from_id: props.Profile.userdetails.id,
            to_id: '0',
            pro_id: props.pid,
            to_email: 'coins.com',
            message: Text,
        }
        chatarrayclient(data)

        setText('')
    }

    return (
        <div className="chatContainer">
            <div className="chatContent" id="otrWrpr">
                <div id="inrWrppr">
                    {!isEmpty(livechat) &&
                        livechat.map((data) =>
                            data.from_id !== props.Profile.userdetails.id ? (
                                <div className="adminMsgContainer">
                                    <div className="adminAccount d-flex">
                                        <span className="material-icons">account_circle</span>
                                        <p className="adminAccountName">{data.from_user}</p>
                                    </div>
                                    <div className="adminMsgCnt">
                                        <p className="adminMsg">{data.message}</p>
                                    </div>
                                    <p className="adminMsgTimeDate">
                                        {moment(data.date_sent).format('MM/DD/YYYY hh:mm a')}
                                    </p>
                                </div>
                            ) : (
                                <div className="publicMsgContainer">
                                    <div className="publicAccount d-flex">
                                        <span className="material-icons">account_circle</span>
                                        <p className="publicAccountName">{data.from_user}</p>
                                    </div>
                                    <div className="publicMsgCnt">
                                        <p className="publicMsg">{data.message}</p>
                                    </div>
                                    <p className="publicMsgTimeDate">
                                        {' '}
                                        {moment(data.date_sent).format('MM/DD/YYYY hh:mm a')}
                                    </p>
                                </div>
                            ),
                        )}
                </div>
            </div>
            <div className="textArea d-flex">
                <CustomInput
                    placeholder="Type your message here"
                    onChange={onChange}
                    value={Text}
                    onKeyPress={(e) => {
                        if (e.keyCode == 13 || e.which == 13) {
                            clientMessage()
                        }
                    }}
                />
                <PrimaryButton label="Send" btnSize="large" onClick={clientMessage} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        Profile: state.User,
        messagesocket: state.chatsocket,
    }
}

export default connect(mapStateToProps, null)(Chat)
