import React, { useState, useEffect } from 'react'
import SecondaryButton from '../../components/atoms/SecondaryButton/index'
import PrimaryButton from '../../components/atoms/PrimaryButton/index'
import { isEmpty, findIndex } from 'lodash'
import Pagination from '@material-ui/lab/Pagination'
import { getPaginationCount } from '../../Utils/Utility'
import { useHistory } from 'react-router-dom'
import './wonAuction.css'
import { handleRedirectInternal } from '../../common/components'
import Switch from '@material-ui/core/Switch'
import { currencyFormat } from '../../common/components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Tooltip from '@material-ui/core/Tooltip'
import Swal from 'sweetalert2'
const WonAuction = (props) => {
    const data = ['Paid', 'Unpaid', 'Unpaid', 'Unpaid', 'Paid', 'Paid']
    const history = useHistory()
    const [invoice_id, setInvoice] = useState([])
    const [unpaid_count, setInvoicecount] = useState(0)
    const [checkout_disable, setCheckoutdisable] = useState(false)
    const [total_amount, setTotalamount] = useState(0)
    const [include_trasactionfee, setTrasactionfee] = useState(true)
    const add_to_card = (data) => {
        var arr = invoice_id
        var temp = []
        if (arr.includes(data.common_invoice)) {
            temp = arr.filter((val) => val != data.common_invoice)
        } else {
            temp = [...arr, data.common_invoice]
        }
        setInvoice(temp)
    }

    useEffect(() => {
        if (!isEmpty(props.data.results)) {
            var temp = [],
                arr = [],
                unpaid_count_local = 0,
                total_amount = 0
            props.data.results.map((data) => {
                arr = temp
                if (data.paid == 0) {
                    unpaid_count_local = unpaid_count_local + 1
                    if (arr.includes(data.common_invoice)) {
                        // temp = arr.filter((val) => val != data.common_invoice)
                    } else {
                        temp = [...arr, data.common_invoice]
                    }
                    if (parseInt(data.buynow_check) == 0) {
                        setCheckoutdisable(true)
                    }
                }

                data.transaction_fee = parseFloat(
                    parseFloat(
                        parseFloat((parseFloat(data.buynowamount) * 3.1) / 100) + 0.3,
                    ).toFixed(2),
                )
                data.total_amount = parseFloat(data.buynowamount) + parseFloat(data.transaction_fee)

                if (data.paid == 0) {
                    total_amount = parseFloat(total_amount) + parseFloat(data.total_amount)
                }
            })
            setInvoice(temp)
            setInvoicecount(unpaid_count_local)
            setTotalamount(total_amount)
        }
    }, [props.data])

    const MovetoCheckout = () => {
        // if (parseFloat(total_amount) < 1000) {
        //     Swal.fire({
        //         title: 'Amount Should be greater than to $1000',
        //         icon: 'error',
        //         timer: 1500,
        //         showConfirmButton: false,
        //         position: 'center',
        //     })
        // } else {
        window.location.href = `Checkout?id=${invoice_id.join('&id=')}`
        //}
    }

    return (
        <div>
            <div className="wonAuction-Table">
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Auction Name</th>
                            <th scope="col">Title</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Date Ended</th>
                            <th scope="col">Won Amount</th>
                            {/* <th scope="col">
                                {' '}
                                <div className="row">
                                    <p>Transaction Fee</p>
                                    <Tooltip
                                        placement="top"
                                        title={
                                            <div
                                                style={{
                                                    width: '300px',
                                                    padding: '5px',
                                                    fontSize: '18px',
                                                    color: 'white',
                                                }}
                                            >
                                                (3.1% + 30 cents) of won amount.
                                            </div>
                                        }
                                        arrow
                                    >
                                        <span className="material-icons">info</span>
                                    </Tooltip>
                                </div>{' '}
                            </th> */}
                            {/* <th colSpan="5">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div>Total</div>
                                    <Tooltip
                                        placement="top"
                                        title={
                                            <div
                                                style={{
                                                    width: '300px',
                                                    padding: '5px',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Switch off to view total amount without transaction
                                                fee.
                                            </div>
                                        }
                                        arrow
                                    >
                                        <span className="material-icons ml-2">info</span>
                                    </Tooltip>
                                    <div className="das-togle-switch">
                                        <Switch
                                            checked={include_trasactionfee}
                                            onChange={(event) =>
                                                setTrasactionfee(event.target.checked)
                                            }
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                    </div>
                                </div>
                            </th> */}
                            <th scope="col">Action</th>
                            {/* <th scope="col">Invoice</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {!isEmpty(props.data.results) ? (
                            <>
                                {props.data.results.map((data, index) => (
                                    <tr>
                                        <td data-title="ID" className="frstHdrRow">
                                            {data.sku}
                                        </td>
                                        <td data-title="Auction Name">{data.auctionlot_title}</td>
                                        <td data-title="Title">
                                            {' '}
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: data.title,
                                                }}
                                            ></p>
                                        </td>
                                        <td data-title="Quantity">{data.qty}</td>
                                        <td data-title="Date Ended">{data.dateclosing}</td>
                                        <td data-title="Won Amount">
                                            {currencyFormat(parseFloat(data.buynowamount))}
                                        </td>
                                        {/* <td>{currencyFormat(parseFloat(data.transaction_fee))}</td> */}
                                        {/* <td colSpan="5">
                                            {currencyFormat(
                                                parseFloat(
                                                    include_trasactionfee
                                                        ? data.total_amount
                                                        : data.buynowamount,
                                                ),
                                            )}
                                        </td> */}
                                        {/* <td className={data}>{data.dateclosing}</td> */}
                                        <td data-title="Action">
                                            {data.paid === 0 ? (
                                                // <SecondaryButton
                                                //     btnSize="addsbBtn"
                                                //     label={'Added To Checkout'}
                                                //     disabled={true}
                                                //     onClick={() => {
                                                //         add_to_card(data)
                                                //     }}
                                                // />
                                                <p>Added To Checkout</p>
                                            ) : (
                                                <SecondaryButton
                                                    label="Invoice"
                                                    onClick={() => {
                                                        history.push(
                                                            `/Invoice/${data.common_invoice}`,
                                                        )
                                                    }}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td data-title="" colSpan="6"></td>
                                    <td data-title="checkBtn" colSpan="2">
                                        <SecondaryButton
                                            label={`Checkout (${unpaid_count})`}
                                            disabled={
                                                parseInt(unpaid_count) > 0 && !checkout_disable
                                                    ? 0
                                                    : 1
                                            }
                                            onClick={() => {
                                                // history.push({
                                                //     pathname: `/Checkout?id=${invoice_id.join('&id=')}`,
                                                //     state: {},
                                                // })
                                                MovetoCheckout()

                                                // handleRedirectInternal(
                                                //     history,
                                                //     `Checkout?id=${invoice_id.join('&id=')}`,
                                                // )
                                            }}
                                        />
                                    </td>
                                </tr>
                            </>
                        ) : (
                            <tr>
                                <td data-title="" colspan="12" className="border-none">
                                    <div className="card card-body text-center mt-2">
                                        <b>No Auction Found</b>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="won-pgn">
                <Pagination
                    onChange={(e, value) => {
                        props.paramsupd(value)
                    }}
                    count={
                        props.data.total_pagecnt
                            ? getPaginationCount(props.data.total_pagecnt, 10)
                            : '1'
                    }
                />
            </div>
        </div>
    )
}

export default WonAuction
