import React from 'react'
import './auth.css'

const AuthContainer = (props) => {
    return (
        <div className="auth-container-wrapper">
            <div className={`loginField auth-container ${props.authPage}`}>
                <div className="auth-wrapper">
                    <div className="logo">
                        <a href="/">
                            <img src="/images/Logo.png" className="cmlftogo sd" alt="logo" />
                        </a>
                    </div>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default AuthContainer
