import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router'
import CustomInput from '../../atoms/Inputs/CustomInput'
import PrimaryButton from '../../atoms/PrimaryButton'
import { currencyFormat, handleRedirectInternal } from '../../../common/components'
import AuthContext from '../../../context/auth/authContext'
import BuyerContext from '../../../context/buyer/buyerContext'
import AlertContext from '../../../context/alert/alertContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import isAuthenticate from '../../../Utils/Utility/auth'
import { connect } from 'react-redux'
import bidding from '../../../Utils/API/bidding'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import preAuctionBid from '../../../Utils/API/preAuctionBid'

function BiddingItem({
    type,
    size,
    lotdetails,
    auctionId,
    listOfCards,
    setViewAddCredit,
    user,
    is_not_approved,
}) {
    // const { isAuthenticated, user } = useContext(AuthContext)
    // const { bidConfirm } = useContext(BuyerContext)
    const { setAlert } = useContext(AlertContext)
    const history = useHistory()
    const [offerbtnDisable, setOfferbtnDisable] = useState(false)

    // const validationArray = Yup.object({
    //     amount: Yup.number()
    //         .min(
    //             lotdetails ? lotdetails.next_bid : 0,
    //             `Min Bid ${lotdetails ? currencyFormat(lotdetails.next_bid) : currencyFormat(0)}`,
    //         )
    //         // .test("is-decimal", "Cannot be decimal", (value) =>
    //         //   value ? typeof value === "number" && value % 1 === 0 : true
    //         // )
    //         .required('Enter bid amount'),
    // })

    const formik = useFormik({
        initialValues: {
            amount: '',
            lotid: '',
            bidding_type: '',
            user_id: '',
            auction_id: '',
            hard_bid: 0,
            auction_io: 1,
            my_bid: '',
        },
        // validationSchema: validationArray,
        onSubmit: async (values) => {
            values.amount = lotdetails ? lotdetails.next_bid : ''
            setOfferbtnDisable(true)
            if (values.bidding_type === 'hard') {
                values.hard_bid = 1
                const result = await bidding(values)
                if (result) {
                    setOfferbtnDisable(false)
                    formik.setFieldValue('amount', '')
                    formik.setFieldTouched('amount', false)
                    formik.setFieldValue('my_bid', values.amount)
                    lotdetails.cancel_bid = 0
                    toast.success('Bid Submitted Successfully')
                }
            } else {
                values.hard_bid = 0
                const result = await preAuctionBid({
                    wsprice: values.amount,
                    userid: values.user_id,
                    id: values.lotid,
                    lotid: values.lotid,
                    bidplan: 'auto',
                    auctionid: values.auction_id,
                })
                if (result) {
                    setOfferbtnDisable(false)
                    formik.setFieldValue('amount', '')
                    formik.setFieldTouched('amount', false)
                    toast.success('Bid Submitted Successfully')
                }
            }

            console.log('values  ---- on submit bid', values)
            // }
        },
    })

    useEffect(() => {
        formik.setFieldValue('bidding_type', type)
    }, [type])

    useEffect(() => {
        formik.setFieldValue('auction_id', auctionId)
    }, [auctionId])

    useEffect(() => {
        if (lotdetails && lotdetails.lotDetails) {
            formik.setFieldValue('lotid', lotdetails.lotDetails.id)
        }
    }, [lotdetails])

    useEffect(() => {
        if (user) {
            formik.setFieldValue('user_id', user.id)
        }
    }, [user])

    return (
        <>
            <ToastContainer autoClose={5000} hideProgressBar={true} pauseOnFocusLoss={false} />
            {lotdetails &&
            lotdetails.lotDetails &&
            lotdetails.lotDetails.market_status === 'open' ? (
                <form onSubmit={formik.handleSubmit}>
                    <div className="biddingCnt">
                        {/* <CustomInput
                            placeholder={`Enter bid`}
                            label={`Enter bid`}
                            variant="standard"
                            onChange={formik.handleChange}
                            pattern="^\d*(.\d{0,2})?$"
                            name="amount"
                            type="number"
                            value={formik.values.amount}
                            placeholder={
                                // lotdetails && lotdetails.lotDetails && lotdetails.highbid === true
                                //     ? `You are the high bid`
                                //     :
                                `Min bid ${currencyFormat(
                                    lotdetails.current_bid + lotdetails.increment,
                                )}`
                            }
                        />
                        <p className="text-danger text-left my-2">
                            {formik.errors && formik.errors.amount}
                        </p> */}
                        {isAuthenticate() ? (
                            parseInt(is_not_approved) === 1 ? (
                                <PrimaryButton
                                    label={'REGISTRATION PENDING'}
                                    type="submit"
                                    btnSize={size ? size : 'small'}
                                    className="fs-16"
                                    disabled={true}
                                />
                            ) : (
                                <PrimaryButton
                                    label={
                                        type === 'hard'
                                            ? lotdetails.highbid
                                                ? 'You are the highest bidder'
                                                : lotdetails &&
                                                  formik.values.my_bid == lotdetails.next_bid &&
                                                  formik.values.my_bid !=
                                                      Number(lotdetails.cancel_bid)
                                                ? 'Your ' +
                                                  currencyFormat(formik.values.my_bid) +
                                                  ' bid is pending'
                                                : `Place Bid - ${
                                                      lotdetails
                                                          ? currencyFormat(lotdetails.next_bid)
                                                          : ''
                                                  }`
                                            : 'Place Max bid'
                                    }
                                    // label={'Submit bid'}
                                    type="submit"
                                    btnSize={size ? size : 'small'}
                                    className="fs-16"
                                    disabled={
                                        offerbtnDisable ||
                                        lotdetails.highbid ||
                                        (lotdetails &&
                                            formik.values.my_bid == lotdetails.next_bid &&
                                            formik.values.my_bid != Number(lotdetails.cancel_bid))
                                    }
                                    // disabled={offerbtnDisable || lotdetails.highbid}
                                />
                            )
                        ) : (
                            <PrimaryButton
                                label="Login to Bid"
                                btnSize={size ? size : 'small'}
                                onClick={(e) => {
                                    e.preventDefault()
                                    handleRedirectInternal(history, 'login')
                                }}
                            />
                        )}
                    </div>
                </form>
            ) : (
                ''
            )}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.User ? state.User : {},
        bidding: state.bidding ? state.bidding : {},
        serverTime: state.serverTime ? state.serverTime.time : '',
        liveLots: state.liveLots ? state.liveLots : '',
        auctionLots: state.auctionLots ? state.auctionLots : '',
        auctionMsg: state.auctionMsg ? state.auctionMsg : '',
    }
}

export default connect(mapStateToProps, null)(BiddingItem)
