const auctionPercentageReducer = (state = { auctionPercent: {} }, action) => {
    switch (action.type) {
        case 'AUCTIONPERCENTAGE':
            return {
                ...state,
                auctionPercent: action.list,
            }
        default:
            return state
    }
}

export default auctionPercentageReducer
