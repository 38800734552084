import React, { useState, useEffect } from 'react'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton/index'
import AuthContainer from '../../components/organisms/AuthContainer'
import * as yup from 'yup'
import { Formik, useFormik } from 'formik'
import axios from '../../Utils/Utility/axiosconfig'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import Country from './Country.json'
import SimpleSelect from '../../components/atoms/DropDown/index'
import SimpleSelect1 from '../../components/atoms/DropDown/Dropdown1'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import State from './State.json'
import './signup.css'
import { IconButton, InputAdornment } from '@material-ui/core'

const Signup = (props) => {
    const history = useHistory()

    const [showPassword, setShowPassword] = useState(false)
    const [showPassword1, setShowPassword1] = useState(false)

    const [telephoneValid, setTelephoneValid] = useState(true)
    const setValidity = (valid) => {
        console.log('validity changessssssssssss', valid)
        setTelephoneValid(valid)
    }

    // const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)

    const signupSchema = yup.object({
        firstname: yup
            .string()
            .required('Enter your First Name...!')
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
        lastname: yup
            .string()
            .required('Enter your Last Name...!')
            .matches(/^[aA-zZ'\s]+$/, 'Only alphabets are allowed for this field'),
        email: yup.string().trim().email('Invalid email format!').required('Enter your Email...!'),
        phone: yup
            .string()
            .min(8, 'Minimum 8 characters')
            .max(20, 'Maximum 20 characters')
            .required('Phone No is required'),
        password: yup
            .string()
            .min(8, 'Minimum 8 characters')
            .max(20, 'Maximum 20 characters')
            .required('Password is required'),
        confirmpassword: yup
            .string()
            .min(8, 'Minimum 8 characters')
            .max(20, 'Maximum 20 characters')
            .oneOf([yup.ref('password'), null], 'Password must match')
            .required('Confirm password is required'),
        zipcode: yup
            .string()
            .min(5, 'Invalid Zip Code')
            .max(6, 'Invalid Zip Code')
            .required('Zip Code Required')
            .matches(/^[0-9]+$/, 'Only numbers are allowed for this field'),
        addressline1: yup.string().required('Enter your Address1'),
        addressline2: yup.string(),
        city: yup
            .string()
            .required('City is required')
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
        state: yup.string().required('State is required'),
        country: yup.string().required('Country is required'),
    })

    useEffect(() => {
        console.log(Country)
    }, [])

    const registration = async (data) => {
        try {
            const {
                firstname,
                lastname,
                email,
                phone,
                password,
                country,
                state,
                city,
                zipcode,
                addressline1,
                addressline2,
            } = data

            const formData = {
                first_name: firstname,
                last_name: lastname,
                email: email,
                phone: phone,
                password: password,
                address1: addressline1,
                address2: addressline2,
                country,
                state,
                city,
                zip: zipcode,
                zipcode: zipcode,
                role: 'buyer',
                status: '',
                redirectURL: `${window.location.origin}/verifyUser`,
            }

            // const res = await axios.post('mobileapi/checkEmailExistance', { email: formData.email })
            formData.community = 'auction_io'
            formData.is_auctionio = 1
            // const res = await axios.post('mobileapi/register', formData)
            // if (res.data.success === 'no') {
            //     return Swal.fire({
            //         icon: 'error',
            //         title: res.data.message || res.data.failedreason,
            //         timer: 1500,
            //         showConfirmButton: false,
            //         position: 'center',
            //     })
            // }
            // await history.push('/login')
            // return Swal.fire({
            //     icon: 'success',
            //     title: 'Thanks for Registration',
            //     timer: 1500,
            //     showConfirmButton: false,
            //     position: 'center',
            // })

            const res = await axios.post('mobileapi/checkEmailExistance', { email: formData.email })

            if (!res.data.status) {
                return Swal.fire({
                    title: res.data.message,
                    icon: 'error',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            }
            localStorage.setItem('signup', JSON.stringify(formData))
            history.push({
                pathname: '/cardDetails',
                state: { personal: formData, card: null },
            })

            // history.push({
            //     pathname: '/login',
            //     // state: { personal: formData, card: null },
            // })
        } catch (error) {
            console.log(error)
        }
    }

    const [Id, setID] = useState('231')
    const [sId, setStateId] = useState('')
    const [Countrycode, setCountrycode] = useState('1')

    useEffect(() => {
        console.log('Countrycode', Countrycode)
        console.log('Id', Id)
        console.log('sId', sId)
    })

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleClickShowPassword1 = () => {
        setShowPassword1(!showPassword1)
    }

    const processNumber = (isValid, phone, country) => {
        let validateMobNum = /[1-9]{1}[0-9]{7}/
        if (validateMobNum.test(phone)) {
            setValidity(true)
            return phone
        } else {
            setValidity(false)
        }

        /* console.log('isvalidd state', isValid)
        console.log(country.dialCode + phone)
        return `+${country.dialCode} ${phone}`*/
        // if (isValid === true) {
        // } else {
        //     return (Formik.phone.error = 'Phone number no valid')
        // }
    }

    return (
        <AuthContainer authPage="signup-page">
            <Formik
                initialValues={{
                    firstname: '',
                    lastname: '',
                    email: '',
                    phone: '',
                    password: '',
                    confirmpassword: '',
                    zipcode: '',
                    addressline1: '',
                    addressline2: '',
                    country: 'United States',
                    state: 'Alabama',
                    city: '',
                }}
                validationSchema={signupSchema}
                onSubmit={(values) => {
                    console.log(values, '======>>>>>>^^^^^<<<<<')
                    registration(values)
                }}
            >
                {({
                    errors,
                    touched,
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldTouched,
                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit}>
                        {console.log(
                            'ppppppppppppppppppppppppppppppppppppppppppppppppppppppppppp',
                            errors,
                        )}
                        <div className="Registration-Banner custom-container-signup">
                            <div className="signup-Text">
                                <h1 className="auth-title">Sign Up</h1>
                            </div>
                            <div className="signup-inputfields row">
                                <div className="registration-inp col-sm-6">
                                    <CustomInput
                                        variant="standard"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label="First Name"
                                        name="firstname"
                                        value={values.firstname}
                                    />
                                    {errors.firstname && touched.firstname ? (
                                        <div className="errorMessage-1 errorTxt">
                                            {errors.firstname}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="registration-inp col-sm-6">
                                    <CustomInput
                                        variant="standard"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label="Last Name"
                                        name="lastname"
                                        value={values.lastname}
                                    />
                                    {errors.lastname && touched.lastname ? (
                                        <div className="errorMessage-2 errorTxt">
                                            {errors.lastname}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="registration-inp col-sm-6">
                                    <CustomInput
                                        variant="standard"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label="Email"
                                        name="email"
                                        value={values.email?.trim()}
                                    />
                                    {errors.email && touched.email ? (
                                        <div className="errorMessage-1 errorTxt">
                                            {errors.email}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="registration-inp intlIptWrpr col-sm-6">
                                    <label>Phone No.</label>
                                    <IntlTelInput
                                        {...props}
                                        containerClassName="intl-tel-input"
                                        inputClassName={telephoneValid ? 'valid' : 'invalid'}
                                        label="Phone No."
                                        defaultValue={values.phone}
                                        fieldName={'phone'}
                                        onPhoneNumberBlur={(isValid) => {
                                            //    setFieldTouched('phone', true)
                                            //setValidity(isValid)
                                        }}
                                        onPhoneNumberChange={(isValid, phone, country) => {
                                            setFieldValue(
                                                'phone',
                                                processNumber(isValid, phone, country),
                                            )
                                        }}
                                        separateDialCode={true}
                                    />
                                    {/* <IntlTelInput
                                            preferredCountries={['us']}
                                            onPhoneNumberChange={handleChange}
                                            onPhoneNumberBlur={handleBlur}

                                            // value={}
                                        /> */}
                                    {/* <CustomInput
                                            variant="standard"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Phone No."
                                            name="phone"
                                            value={values.phone}
                                        /> */}
                                    {!telephoneValid && (
                                        <p className="phone-number-error mt-2 mb-0">
                                            Phone number invalid
                                        </p>
                                    )}
                                    {telephoneValid && errors.phone && touched.phone ? (
                                        <div className="phone-number-error errorMessage-2 errorTxt">
                                            {errors.phone}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="registration-inp col-sm-6">
                                    <CustomInput
                                        variant="standard"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label="Password"
                                        name="password"
                                        value={values.password}
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {showPassword ? (
                                                        <span className="material-icons">
                                                            visibility
                                                        </span>
                                                    ) : (
                                                        <span className="material-icons">
                                                            visibility_off
                                                        </span>
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {errors.password && touched.password ? (
                                        <div className="errorMessage-1 errorTxt">
                                            {errors.password}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="registration-inp col-sm-6">
                                    <CustomInput
                                        variant="standard"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label="Confirm Password"
                                        name="confirmpassword"
                                        value={values.confirmpassword}
                                        type={showPassword1 ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword1}
                                                >
                                                    {showPassword1 ? (
                                                        <span className="material-icons">
                                                            visibility
                                                        </span>
                                                    ) : (
                                                        <span className="material-icons">
                                                            visibility_off
                                                        </span>
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {errors.confirmpassword && touched.confirmpassword ? (
                                        <div className="errorMessage-2 errorTxt">
                                            {errors.confirmpassword}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="registration-inp col-sm-6">
                                    <SimpleSelect
                                        dropCountr={Country}
                                        onChange={handleChange}
                                        countryID={(data) => setID(data)}
                                        ctrcode={(data) => setCountrycode(data)}
                                        value={values.country}
                                        name="country"
                                        label="Country"
                                        autoComplete="nope"
                                    />

                                    {/* <DropDown
                                            variant="standard"
                                            label="Country"
                                            name="country"
                                            value={values.country}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            dropCountr={Country}
                                            countryID={(data) => setID(data)}
                                            ctrcode={(data) => setCountrycode(data)}
                                        /> */}
                                    {errors.country && touched.country ? (
                                        <div className="errorMessage-2 errorTxt">
                                            {errors.country}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="registration-inp col-sm-6">
                                    {/* <CustomInput
                                        variant="standard"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="state"
                                        label="State"
                                        value={values.state}
                                    /> */}
                                    <SimpleSelect1
                                        selected={values.state}
                                        onChange={handleChange}
                                        countryID={(data) => setID(data)}
                                        ctrcode={(data) => setCountrycode(data)}
                                        name="state"
                                        name="state"
                                        dropState={State.filter(
                                            (data) => data.country_id === Id.toString(),
                                        )}
                                        stateID={(data) => setStateId(data)}
                                        autoComplete="nope"
                                    />
                                    {/* <DropDown
                                            variant="standard"
                                            label="State"
                                            name="state"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.state}
                                            dropState={State.filter(
                                                (data) => data.country_id === Id.toString(),
                                            )}
                                            stateID={(data) => setStateId(data)}
                                        /> */}
                                    {errors.state && touched.state ? (
                                        <div className="errorMessage-2 errorTxt">
                                            {errors.state}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="registration-inp col-sm-6">
                                    {/* <DropDown
                                            variant="standard"
                                            label="City"
                                            name="city"
                                            value={values.city}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            dropCity={cities.filter(
                                                (data) => data.state_id === sId.toString(),
                                            )}
                                        /> */}
                                    <CustomInput
                                        variant="standard"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="city"
                                        label="City"
                                        value={values.city}
                                    />
                                    {/* <SimpleSelect2
                                            value={values.city}
                                            onChange={handleChange}
                                            name="city"
                                            label="City"
                                            dropCity={cities.filter(
                                                (data) => data.state_id === sId.toString(),
                                            )}
                                            autoComplete="nope"
                                        /> */}
                                    {errors.city && touched.city ? (
                                        <div className="errorMessage-2 errorTxt">{errors.city}</div>
                                    ) : null}
                                </div>

                                <div className="registration-inp col-sm-6">
                                    <CustomInput
                                        variant="standard"
                                        label="Zip Code"
                                        name="zipcode"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.zipcode}
                                        inputProps={{ maxLength: 6 }}
                                    />
                                    {errors.zipcode && touched.zipcode ? (
                                        <div className="errorMessage-2 errorTxt">
                                            {errors.zipcode}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="registration-inp col-sm-6">
                                    <CustomInput
                                        variant="standard"
                                        label="Address Line 1"
                                        name="addressline1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.addressline1}
                                    />
                                    {errors.addressline1 && touched.addressline1 ? (
                                        <div className="errorMessage-1 errorTxt">
                                            {errors.addressline1}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="registration-inp col-sm-6">
                                    <CustomInput
                                        variant="standard"
                                        label="Address Line 2"
                                        name="addressline2"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.addressline2}
                                    />
                                    {errors.addressline2 && touched.addressline2 ? (
                                        <div className="errorTxt">{errors.addressline2}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="signup-btn">
                                <PrimaryButton label="Next" type="submit" />
                            </div>
                            <div className="bottomText signin-Instead">
                                <a href="/login">Sign in Instead</a>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </AuthContainer>
    )
}

export default Signup
