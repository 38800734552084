import axios from '../Utility/axiosconfig'
import Swal from 'sweetalert2'
const updateFav = async (body, isFav) => {
    let { data } = await axios.post(
        `mobileapi/${isFav == 0 ? 'watchlist' : 'removeFwatchlist'}`,
        body,
    )
    // console.log('data====>', data && data.success === true)
    // console.log('data====>', data && data.msg === 'success')
    // console.log('data===>', (data && data.success === true) || (data && data.msg === 'success'))
    if ((data && data.success === true) || (data && data.msg === 'success')) {
        if (isFav == 1) {
            Swal.fire({
                title: 'Removed from Favorite',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })
            return 'Removed from Favorite'
        } else {
            Swal.fire({
                title: 'Added to Favorite',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })
            return 'Added to Favorite'
        }
    } else {
        return ''
    }
}

export default updateFav
