import axios from '../Utility/axiosconfig'
import { TableBody } from '@material-ui/core'

const invoiceapi = async (id) => {
    try {
        const res = await axios.post('mobileapi/view-invoice', { id: id })
        if (res.data.success === 'yes') {
            return { inv: res.data.results, success: res.data.success }
        } else {
            return { success: res.data.success }
        }
    } catch (error) {
        console.log(error)
    }
}

export default invoiceapi
