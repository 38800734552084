import axiosInstance from '../Utility/axiosconfig'

const chatarray = async (data) => {
    try {
        const res = await axiosInstance.post('mobileapi/get_product_messages', {
            pid: data,
            type: 'product',
        })
        console.log(res.data.live_chat)

        if (res.data.success === true) {
            return res.data.live_chat
        }
    } catch (error) {
        console.log(error)
    }
}

const chatarrayclient = async (data) => {
    try {
        const res = await axiosInstance.post('mobileapi/product_message', {
            from_id: data.from_id,
            to_id: data.to_id,
            pro_id: data.pro_id,
            to_email: data.to_email,
            message: data.message,
            type: 'product',
        })
    } catch (error) {
        console.log(error)
    }
}

const chathistory = async (data) => {
    try {
        const res = await axiosInstance.post('mobileapi/get_chathistory', { chat_id: data })
        if (res.data.status === 'yes') {
            let chat_data = res.data.data
            return chat_data.reverse()
        }
    } catch (error) {
        console.log(error)
    }
}

const getadmin = async (data) => {
    try {
        const res = await axiosInstance.post('mobileapi/list_sub_admins')
        if (res.data.success === true) {
            return res.data
        }
    } catch (error) {
        console.log(error)
    }
}

export { chatarray, chatarrayclient, chathistory, getadmin }
