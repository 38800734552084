import axios from 'axios'
import isAuthenticate from '.././Utils/Utility/auth'

export const storeDetailsAction = (list) => ({
    type: 'STOREDETAILS',
    list,
})

const storeDetails = (body) => {
    return async (dispatch) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_AUCTION_URL}getstores`, body)
            if (res.data.status === 'success') {
                console.log('response value', res)
                dispatch(storeDetailsAction(res.data.response))
            } else {
                return `something went wrong`
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export default storeDetails
