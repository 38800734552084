// prettier-ignore
import React, { useContext, useEffect, useState } from 'react'
import CustomInput from '../../atoms/Inputs/CustomInput'
import PrimaryButton from '../../atoms/PrimaryButton'
import SecondaryButton from '../../atoms/SecondaryButton'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import { Button, Popover } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { handleRedirectInternal, useCustomMediaQuery } from '../../../common/components'
import isAuthenticate from '../../../Utils/Utility/auth'
import './Header.css'
import { NavLink } from 'react-router-dom'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { makeStyles } from '@material-ui/core/styles'
import Swal from 'sweetalert2'
import { connect, useDispatch } from 'react-redux'
import rapidSearch from '../../../actions/rapidSearch'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { logo } from '../../../Utils/UI'
import { useSelector } from 'react-redux'
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    list: {
        width: 300,
        // padding: '60px 70px 10px 60px',
    },
    fullList: {
        width: 'auto',
    },
}))

const Header = (props) => {
    const history = useHistory()
    const location = useLocation()
    const [text, setText] = React.useState('')
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef(null)
    const dispatch = useDispatch()
    const [searchText, setSearchText] = React.useState([])
    const [anchormenu, setanchormenu] = useState({ anchorEl: null, popno: null })
    const [display_submenu, setSubmenu] = useState(true)

    const state_loc = useSelector((state) => state.storeDetails)

    if (state_loc.storeDetails.nav_menu) {
        var nav_menu = state_loc.storeDetails.nav_menu
    } else {
        var nav_menu = []
    }
    if (nav_menu.length > 0) {
        var header_menu = nav_menu.filter((val) => val.menu_type == 'header')
    } else {
        var header_menu = []
    }

    let deviceWidth = useCustomMediaQuery('(max-width:991px)')

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }
    const hamMenu = useStyles()
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    })
    // top
    const toggleDrawers = (toppanel, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [toppanel]: open })
    }
    // top
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: open })
    }

    var pathname = window.location.pathname

    const onChange = (e) => setText(e.target.value)

    const handleMenu = (event, popno) => {
        setanchormenu({ anchorEl: event.currentTarget, popno: popno })
    }

    const handleClose1 = () => {
        setanchormenu({ anchorEl: null, popno: null })
        setSubmenu(false)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        console.log('test view', text)
        setState({
            top: false,
            right: false,
        })
        let query = new URLSearchParams(location.search)
        query.set('search', text)
        if (window.location.pathname === '/past_auctions') {
            query.set('page', 1)
            history.push({ pathname: '/past_auctions', search: '?' + query.toString() })
        } else {
            query.set('page', 1)
            history.push({ pathname: '/lotsearch', search: '?' + query.toString() })
        }
    }
    // top
    const toplists = (toppanel) => (
        <div
            className={clsx(hamMenu.list, {
                [classes.fullList]: toppanel === 'top' || toppanel === 'bottom',
            })}
            role="presentation"
            // onClick={toggleDrawer(anchor, true)}
            // onKeyDown={toggleDrawer(anchor, false)}
        >
            <header className="beforHead tpSliderWrpr">
                {/* <div className="navLogo">
                    <Link to="">
                        <img alt="LOGO" src="/images/Logo.png" />
                    </Link>
                    <Button className="headDrawerClose" onClick={toggleDrawers('top', false)}>
                        <span className="material-icons">close</span>
                    </Button>
                </div> */}
                <form className="Search d-flex justify-content-between" onSubmit={onSubmit}>
                    <div className="customInput">
                        <Autocomplete
                            freeSolo
                            options={searchText}
                            onChange={(event, newValue) => {
                                console.log('newValue', newValue?.title, newValue)
                                setText(
                                    newValue?.title != undefined && newValue?.title != null
                                        ? newValue.title
                                        : newValue != null
                                        ? newValue
                                        : '',
                                )
                            }}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason === 'clear') {
                                    setText('')
                                }
                            }}
                            getOptionLabel={(option) =>
                                option.sku ? `${option.title} (${option.sku})` : option.title
                            }
                            renderInput={(params) => (
                                <div className="customInput">
                                    <TextField
                                        {...params}
                                        onChange={onChange}
                                        variant={props.variant ? props.variant : 'outlined'}
                                        placeholder="Search"
                                    />
                                </div>
                            )}
                        />
                    </div>
                    <Button type="submit">
                        <span className="material-icons">search</span>
                    </Button>
                </form>
            </header>
        </div>
    )
    // top
    const list = (anchor) => (
        <div
            className={clsx(hamMenu.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            // onClick={toggleDrawer(anchor, true)}
            // onKeyDown={toggleDrawer(anchor, false)}
        >
            <header className="beforHead">
                <div className="navLogo">
                    <Link to="">
                        <img alt="LOGO" src={logo} />
                    </Link>
                    <Button className="headDrawerClose" onClick={toggleDrawer('right', false)}>
                        <span className="material-icons">close</span>
                    </Button>
                </div>
                <form
                    className="Search srchSidBar d-flex justify-content-between"
                    onSubmit={onSubmit}
                >
                    <Autocomplete
                        freeSolo
                        options={searchText}
                        onChange={(event, newValue) => {
                            console.log('newValue', newValue?.title, newValue)
                            setText(
                                newValue?.title != undefined && newValue?.title != null
                                    ? newValue.title
                                    : newValue != null
                                    ? newValue
                                    : '',
                            )
                        }}
                        onInputChange={(event, newInputValue, reason) => {
                            if (reason === 'clear') {
                                setText('')
                            }
                        }}
                        getOptionLabel={(option) =>
                            option.sku ? `${option.title} (${option.sku})` : option.title
                        }
                        renderInput={(params) => (
                            <div className="customInput">
                                <TextField
                                    {...params}
                                    onChange={onChange}
                                    variant={props.variant ? props.variant : 'outlined'}
                                    placeholder="Search"
                                />
                            </div>
                        )}
                    />
                    <Button type="submit">
                        <span className="material-icons">search</span>
                    </Button>
                </form>
                <>
                    {isAuthenticate() != null ? (
                        <>
                            <List onClick={toggleDrawer('right', false)}>
                                <ListItem
                                    className={pathname === '/search' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'search')}
                                >
                                    <i className="fas fa-gavel"></i>
                                    <ListItemIcon>Auctions</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    className={pathname === '/past_auctions' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'past_auctions')}
                                >
                                    <i className="fas fa-gavel"></i>
                                    <ListItemIcon>Past Auctions</ListItemIcon>
                                </ListItem>

                                {header_menu.length > 0
                                    ? header_menu.map((val, index) =>
                                          val.subLinks.length > 0 ? (
                                              <li className="mr-3">
                                                  <div className="nav-menu-from-store">
                                                      <a
                                                          //   target={'_blank'}
                                                          //   rel="noreferrer"
                                                          className="active"
                                                          href={val.path}
                                                      >
                                                          {val.label}
                                                      </a>

                                                      <Button
                                                          aria-controls={`simple-menu_${index}`}
                                                          aria-haspopup="true"
                                                          onClick={(event) =>
                                                              handleMenu(event, index)
                                                          }
                                                      >
                                                          {Boolean(anchormenu.anchorEl) &&
                                                          index === anchormenu.popno ? (
                                                              <span className="material-icons-outlined">
                                                                  expand_less
                                                              </span>
                                                          ) : (
                                                              <span className="material-icons-outlined">
                                                                  expand_more
                                                              </span>
                                                          )}
                                                      </Button>
                                                  </div>
                                                  <Popover
                                                      id={`simple-menu_${index}`}
                                                      open={
                                                          Boolean(anchormenu.anchorEl) &&
                                                          index === anchormenu.popno
                                                      }
                                                      anchorEl={anchormenu.anchorEl}
                                                      onClose={handleClose1}
                                                      anchorOrigin={{
                                                          vertical: 'bottom',
                                                          horizontal: 'right',
                                                      }}
                                                      transformOrigin={{
                                                          vertical: 'top',
                                                          horizontal: 'right',
                                                      }}
                                                  >
                                                      {index === anchormenu.popno && (
                                                          <ul className="headerDropMenu">
                                                              {val.subLinks.map((data) => (
                                                                  <a
                                                                      rel="noreferrer"
                                                                      //target={'_blank'}
                                                                      className="active"
                                                                      href={data.path}
                                                                  >
                                                                      <ListItem>
                                                                          {data.label}
                                                                      </ListItem>
                                                                  </a>
                                                              ))}
                                                          </ul>
                                                      )}
                                                  </Popover>
                                              </li>
                                          ) : (
                                              <li className="mr-3">
                                                  <a
                                                      //   target={'_blank'}
                                                      //   rel="noreferrer"
                                                      className="active"
                                                      href={val.path}
                                                  >
                                                      {val.label}
                                                  </a>
                                              </li>
                                          ),
                                      )
                                    : ''}

                                {/*<ListItem
                                    className={pathname === '/buynow' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'buynow')}
                                >
                                    <span className="material-icons">local_mall</span>
                                    <ListItemIcon>Buynow</ListItemIcon>
                                          </ListItem>*/}
                                <ListItem
                                    className={pathname === '/dashboard' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'dashboard')}
                                >
                                    <span className="material-icons">dashboard</span>
                                    <ListItemIcon>Dashboard</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    className={pathname === '/contactus' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'contactus')}
                                >
                                    <span className="material-icons">perm_phone_msg</span>
                                    <ListItemIcon>Contact Us</ListItemIcon>
                                </ListItem>
                                {/*<ListItem
                                    className={pathname === '/privacypolicy' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'privacypolicy')}
                                >
                                    <span className="material-icons">privacy_tip</span>
                                    <ListItemIcon>Privacy policy</ListItemIcon>
                                </ListItem>*/}
                                <ListItem
                                    className={pathname === '/termsancondition' ? 'active' : ''}
                                    button
                                    onClick={() =>
                                        handleRedirectInternal(history, 'TermsandConditions')
                                    }
                                >
                                    <span className="material-icons">fact_check</span>
                                    <ListItemIcon>Terms and Conditions</ListItemIcon>
                                </ListItem>
                                <ListItem>
                                    <PrimaryButton
                                        label="Log Out"
                                        onClick={() => {
                                            localStorage.removeItem('userToken')
                                            Swal.fire({
                                                icon: 'success',
                                                title: 'Logged out successfully',
                                                timer: 1500,
                                                showConfirmButton: false,
                                                position: 'center',
                                            })
                                            history.push('/login')
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </>
                    ) : (
                        <>
                            <List onClick={toggleDrawer('right', false)}>
                                <ListItem
                                    button
                                    onClick={() => handleRedirectInternal(history, 'search')}
                                >
                                    {/* <span className="material-icons">shopping_bag</span>
                                     */}
                                    <i className="fas fa-gavel"></i>
                                    <ListItemIcon>Auctions</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    className={pathname === '/past_auctions' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'past_auctions')}
                                >
                                    <i className="fas fa-gavel"></i>
                                    <ListItemIcon>Past Auctions</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => handleRedirectInternal(history, 'contactus')}
                                >
                                    <span className="material-icons">perm_phone_msg</span>
                                    <ListItemIcon>Contact Us</ListItemIcon>
                                </ListItem>
                                {/*<ListItem
                                    button
                                    onClick={() => handleRedirectInternal(history, 'privacypolicy')}
                                >
                                    <span className="material-icons">privacy_tip</span>
                                    <ListItemIcon>Privacy policy</ListItemIcon>
                                </ListItem>*/}
                                <ListItem
                                    button
                                    onClick={() =>
                                        handleRedirectInternal(history, 'TermsandConditions')
                                    }
                                >
                                    <span className="material-icons">fact_check</span>
                                    <ListItemIcon>Terms and Conditions</ListItemIcon>
                                </ListItem>
                                <ListItem>
                                    <SecondaryButton
                                        label="Login"
                                        onClick={() => handleRedirectInternal(history, 'login')}
                                    />
                                </ListItem>
                                <ListItem>
                                    <PrimaryButton
                                        className="dasda"
                                        label="Register for Auction"
                                        onClick={() => handleRedirectInternal(history, 'signup')}
                                    />
                                </ListItem>
                            </List>
                        </>
                    )}
                </>
                {/* {useCustomMediaQuery('(max-width: 991px)') ? (
                ) : null} */}
            </header>
        </div>
    )

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open)
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])

    // console.log('deviceWidth====>' asdasd, deviceWidth)

    useEffect(() => {
        console.log('use effect rendered')
        dispatch(
            rapidSearch({
                search_keyword: text,
                status: window.location.pathname == '/past_auctions' ? 'sold' : 'open',
                type: window.location.pathname == '/past_auctions' ? 'auction' : 'lots',
            }),
        )
    }, [text])

    useEffect(() => {
        if (props.searchText?.rapidSearch?.length > 0) {
            setSearchText(props.searchText.rapidSearch)
        }
    }, [props.searchText])

    return (
        <>
            {deviceWidth ? (
                <header>
                    <div className="resHeader customContainer d-flex justify-content-between align-items-center">
                        <a href="/">
                            <img className="logo siteLogo" src={logo} alt="Kzoobullion" />
                        </a>
                        <div className="quickNots">
                            <div className="headerNav">
                                {['top'].map((toppanel) => (
                                    <React.Fragment key={toppanel}>
                                        {isAuthenticate() != null ? (
                                            <div className="imgBtn">
                                                <Button onClick={toggleDrawers(toppanel, true)}>
                                                    <span className="material-icons">search</span>
                                                </Button>
                                            </div>
                                        ) : null}
                                        <Drawer
                                            anchor={toppanel}
                                            open={state[toppanel]}
                                            onClose={toggleDrawers(toppanel, false)}
                                        >
                                            {toplists(toppanel)}
                                        </Drawer>
                                    </React.Fragment>
                                ))}
                            </div>
                            {isAuthenticate() != null ? (
                                <>
                                    {/* // deviceWidth ? ( */}
                                    <div className="headerNav">
                                        <Button aria-haspopup="true">
                                            <span
                                                className="material-icons"
                                                onClick={() => {
                                                    localStorage.removeItem('userToken')
                                                    Swal.fire({
                                                        icon: 'success',
                                                        title: 'Logged out successfully',
                                                        timer: 1500,
                                                        showConfirmButton: false,
                                                        position: 'center',
                                                    })
                                                    history.push('/login')
                                                }}
                                            >
                                                power_settings_new
                                            </span>
                                        </Button>
                                    </div>
                                    {/* // ) : ( */}
                                    <div className="headerNav">
                                        {['right'].map((anchor) => (
                                            <React.Fragment key={anchor}>
                                                <div className="imgBtn">
                                                    <Button onClick={toggleDrawer(anchor, true)}>
                                                        <span className="material-icons">
                                                            segment
                                                        </span>
                                                    </Button>
                                                </div>
                                                <Drawer
                                                    anchor={anchor}
                                                    open={state[anchor]}
                                                    onClose={toggleDrawer(anchor, false)}
                                                >
                                                    {list(anchor)}
                                                </Drawer>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    {/* // ) */}
                                </>
                            ) : (
                                <div className="headerNav">
                                    {['right'].map((anchor) => (
                                        <React.Fragment key={anchor}>
                                            <div className="imgBtn">
                                                <Button onClick={toggleDrawer(anchor, true)}>
                                                    <span className="material-icons">segment</span>
                                                </Button>
                                            </div>
                                            <Drawer
                                                anchor={anchor}
                                                open={state[anchor]}
                                                onClose={toggleDrawer(anchor, false)}
                                            >
                                                {list(anchor)}
                                            </Drawer>
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </header>
            ) : (
                <header>
                    <div
                        class={` ${
                            window.location.pathname === '/'
                                ? 'hmcustomContainer'
                                : 'customContainer'
                        } d-flex align-items-center justify-content-between`}
                    >
                        <a href="/">
                            <img className="logo siteLogo" src={logo} alt="Kzoobullion-Logo" />
                        </a>
                        <form className="Search d-flex justify-content-between" onSubmit={onSubmit}>
                            <Autocomplete
                                freeSolo
                                options={searchText}
                                onChange={(event, newValue) => {
                                    console.log('newValue', newValue?.title, newValue)
                                    setText(
                                        newValue?.title != undefined && newValue?.title != null
                                            ? newValue.title
                                            : newValue != null
                                            ? newValue
                                            : '',
                                    )
                                }}
                                onInputChange={(event, newInputValue, reason) => {
                                    if (reason === 'clear') {
                                        setText('')
                                    }
                                }}
                                // onChange={onChange}
                                getOptionLabel={(option) =>
                                    option.sku ? `${option.title} (${option.sku})` : option.title
                                }
                                renderInput={(params) => (
                                    <div className="customInput">
                                        <TextField
                                            {...params}
                                            onChange={onChange}
                                            variant={props.variant ? props.variant : 'outlined'}
                                            placeholder="Search"
                                        />
                                    </div>
                                )}
                            />
                            <Button type="submit">
                                <span className="material-icons">search</span>
                            </Button>
                        </form>
                        <ul className="headerLinks d-flex">
                            {/* {window.location.pathname != '/' ? (
                                <NavLink
                                    to="/"
                                    className={window.location.pathname === '/' ? 'active' : ''}
                                    activeClassName="inactive"
                                >
                                    <li>Home</li>
                                </NavLink>
                            ) : (
                                ''
                            )} */}
                            <NavLink
                                to="/"
                                className={window.location.pathname === '/' ? 'active' : ''}
                                activeClassName="inactive"
                            >
                                <li>Auctions</li>
                            </NavLink>
                            <NavLink to="/past_auctions" activeClassName="active">
                                <li>Past Auctions</li>
                            </NavLink>

                            {header_menu.length > 0
                                ? header_menu.map((val, index) =>
                                      val.subLinks.length > 0 ? (
                                          <li className="mr-3">
                                              <div className="nav-menu-from-store">
                                                  <a
                                                      //   target={'_blank'}
                                                      //   rel="noreferrer"
                                                      className="active"
                                                      href={val.path}
                                                  >
                                                      {val.label}
                                                  </a>

                                                  <Button
                                                      aria-controls={`simple-menu_${index}`}
                                                      aria-haspopup="true"
                                                      onClick={(event) => handleMenu(event, index)}
                                                  >
                                                      {Boolean(anchormenu.anchorEl) &&
                                                      index === anchormenu.popno ? (
                                                          <span className="material-icons-outlined">
                                                              expand_less
                                                          </span>
                                                      ) : (
                                                          <span className="material-icons-outlined">
                                                              expand_more
                                                          </span>
                                                      )}
                                                  </Button>
                                              </div>
                                              <Popover
                                                  id={`simple-menu_${index}`}
                                                  open={
                                                      Boolean(anchormenu.anchorEl) &&
                                                      index === anchormenu.popno
                                                  }
                                                  anchorEl={anchormenu.anchorEl}
                                                  onClose={handleClose1}
                                                  anchorOrigin={{
                                                      vertical: 'bottom',
                                                      horizontal: 'right',
                                                  }}
                                                  transformOrigin={{
                                                      vertical: 'top',
                                                      horizontal: 'right',
                                                  }}
                                              >
                                                  {index === anchormenu.popno && (
                                                      <ul className="headerDropMenu">
                                                          {val.subLinks.map((data) => (
                                                              <a
                                                                  rel="noreferrer"
                                                                  //target={'_blank'}
                                                                  className="active"
                                                                  href={data.path}
                                                              >
                                                                  <ListItem>{data.label}</ListItem>
                                                              </a>
                                                          ))}
                                                      </ul>
                                                  )}
                                              </Popover>
                                          </li>
                                      ) : (
                                          <li className="mr-3">
                                              <a
                                                  //   target={'_blank'}
                                                  //   rel="noreferrer"
                                                  className="active"
                                                  href={val.path}
                                              >
                                                  {val.label}
                                              </a>
                                          </li>
                                      ),
                                  )
                                : ''}

                            {/* <NavLink to="#">
                                <li>Buy Now</li>
                            </NavLink> */}
                            {isAuthenticate() !== null ? (
                                <>
                                    <NavLink to="/dashboard">
                                        <li>Dashboard</li>
                                    </NavLink>
                                    <li className="prlDrop">
                                        <div className={classes.root}>
                                            <div>
                                                <Button
                                                    className="prflButton"
                                                    ref={anchorRef}
                                                    aria-controls={
                                                        open ? 'menu-list-grow' : undefined
                                                    }
                                                    aria-haspopup="true"
                                                    onClick={handleToggle}
                                                >
                                                    <i className="fa fa-user" aria-hidden="true">
                                                        &emsp;{' '}
                                                    </i>
                                                    {props.Profile.userdetails.first_name}
                                                </Button>
                                                <Popper
                                                    open={open}
                                                    anchorEl={anchorRef.current}
                                                    role={undefined}
                                                    transition
                                                    disablePortal
                                                >
                                                    {({ TransitionProps, placement }) => (
                                                        <Grow
                                                            {...TransitionProps}
                                                            style={{
                                                                transformOrigin:
                                                                    placement === 'bottom'
                                                                        ? 'center top'
                                                                        : 'center bottom',
                                                            }}
                                                        >
                                                            <Paper>
                                                                <ClickAwayListener
                                                                    onClickAway={handleClose}
                                                                >
                                                                    <MenuList
                                                                        autoFocusItem={open}
                                                                        id="menu-list-grow"
                                                                        onKeyDown={
                                                                            handleListKeyDown
                                                                        }
                                                                    >
                                                                        <MenuItem
                                                                            onClick={() => {
                                                                                localStorage.removeItem(
                                                                                    'userToken',
                                                                                )
                                                                                Swal.fire({
                                                                                    icon: 'success',
                                                                                    title: 'Logged out successfully',
                                                                                    timer: 1500,
                                                                                    showConfirmButton: false,
                                                                                    position:
                                                                                        'center',
                                                                                })
                                                                                window.location.href =
                                                                                    '/login'
                                                                            }}
                                                                        >
                                                                            Logout
                                                                        </MenuItem>
                                                                    </MenuList>
                                                                </ClickAwayListener>
                                                            </Paper>
                                                        </Grow>
                                                    )}
                                                </Popper>
                                            </div>
                                        </div>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li>
                                        <SecondaryButton
                                            label="Login"
                                            onClick={() => handleRedirectInternal(history, 'login')}
                                        />
                                    </li>
                                    <li className="pr-0">
                                        <PrimaryButton
                                            label="Register for Auction"
                                            onClick={() =>
                                                handleRedirectInternal(history, 'signup')
                                            }
                                        />
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </header>
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    Profile: state.User,
    searchText: state.rapidSearch,
})

export default connect(mapStateToProps, null)(Header)
