import axios from '.././Utils/Utility/axiosconfig'
import isAuthenticate from '.././Utils/Utility/auth'

export const liveLotAction = (list) => ({
    type: 'LIVELOTS',
    list,
})

const livelotDetails = (body) => {
    return async (dispatch) => {
        try {
            const res = await axios.post('api/lotDetails', body)
            if (res.data.status === 'success') {
                dispatch(liveLotAction(res.data.response))
            } else {
                return `something went wrong`
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export default livelotDetails
