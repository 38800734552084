import React, { useEffect, useState } from 'react'
import CustomInput from '../../atoms/Inputs/CustomInput'
import PrimaryButton from '../../atoms/PrimaryButton'
import { chathistory, getadmin } from '../../../Utils/API/chatapi'
import { socket } from '../../../common/socket'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import moment from 'moment'
import './chatclone.css'

function Chatclone(props) {
    const [adminId, setadminId] = useState('')
    const [chatHistory, setchatHistory] = useState([])
    const [Text, setText] = useState('')

    const admin = async () => {
        const data = await getadmin()
        console.log(data.data[0].id)
        setadminId(data.data[0].id)
        const chatdata = await chathistory(data.data[0].id)
        if (chatdata !== undefined) {
            setchatHistory(chatdata)
        }
    }

    const sendMesssage = () => {
        const details = {
            from_id: props.Profile.userdetails.id,
            to_id: adminId,
            message: Text,
            date_sent: moment().format(),
        }

        socket.emit('insertMessages', details)
        setText('')
    }

    const handlechange = (e) => {
        setText(e.target.value)
    }

    useEffect(() => {
        admin()
    }, [])

    useEffect(() => {
        let user_data = JSON.parse(localStorage.getItem('userData'))
        // if (props.chatdashboard.to_id != user_data.id) {
        setchatHistory((prevData) => [...prevData, props.chatdashboard])
        // }
    }, [props.chatdashboard])

    useEffect(() => {
        scollBottom()
    })

    function scollBottom() {
        var div = document.getElementById('inrWrppr')
        document.getElementById('otrWrpr').scrollBy(0, div.scrollHeight)
    }

    return (
        <div className="chatContainer">
            <div className="chatContent" id="otrWrpr">
                <div id="inrWrppr">
                    {!isEmpty(chatHistory) &&
                        chatHistory.map((data) =>
                            parseInt(data.to_id) == parseInt(props.Profile.userdetails.id) ? (
                                <div className="adminMsgContainer">
                                    <div className="adminAccount d-flex">
                                        <span className="material-icons">account_circle</span>
                                        <p className="adminAccountName">Admin</p>
                                    </div>
                                    <div className="adminMsgCnt">
                                        <p className="adminMsg">{data.message}</p>
                                    </div>
                                    <p className="adminMsgTimeDate">
                                        {moment(data.date_sent).format('MM/DD/YYYY hh:mm a')}
                                    </p>
                                </div>
                            ) : data.from_id === props.Profile.userdetails.id ? (
                                <div className="publicMsgContainer">
                                    <div className="publicAccount d-flex">
                                        <span className="material-icons">account_circle</span>
                                        <p className="publicAccountName">me</p>
                                    </div>
                                    <div className="publicMsgCnt">
                                        <p className="publicMsg">{data.message}</p>
                                    </div>
                                    <p className="publicMsgTimeDate">
                                        {' '}
                                        {moment(data.date_sent).format('MM/DD/YYYY hh:mm a')}
                                    </p>
                                </div>
                            ) : (
                                ''
                            ),
                        )}
                </div>
            </div>{' '}
            <div className="textArea d-flex">
                <CustomInput
                    placeholder="Type your message here"
                    onChange={handlechange}
                    value={Text}
                    onKeyPress={(e) => {
                        if (e.keyCode == 13 || e.which == 13) {
                            sendMesssage()
                        }
                    }}
                />
                <PrimaryButton label="Send" btnSize="large" onClick={sendMesssage} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    chatdashboard: state.chatsocket.buyerchat,
    Profile: state.User,
})

export default connect(mapStateToProps, null)(Chatclone)
