import axios from '../Utility/axiosconfig'

export default async function getAuctionLots(body) {
    let { data } = await axios.post('mobileapi/lotsearch', body)
    console.log(data, 'tjois os tje api')
    if (data.success) {
        return data
    } else {
        return { data: [], count: 0 }
    }
}
