import axios from '../Utility/axiosconfig'

export default async function getLotdata(body) {
    let { data } = await axios.post('mobileapi/auctionsearch', body)
    if (data.success === 'yes') {
        return {
            data: Array.isArray(data.results) ? data.results : [],
            count: data.total_pagecnt || 0,
        }
    } else {
        return { data: [], count: 0 }
    }
}
