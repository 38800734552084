import axios from '../Utility/axiosconfig'

const getbuynow = async (body) => {
    let { data } = await axios.post('mobileapi/buyerDashboard', body)
    try {
        if (data.success === 'yes') {
            return {
                results: data.results,
                total_pagecnt: data.total_pagecnt,
            }
        } else {
            return 'Something went wrong'
        }
    } catch (err) {
        console.log('buyerDashboard err====>', err)
        return 'Something went wrong'
    }
}

export { getbuynow }
