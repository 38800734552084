import React, { Component } from 'react'
import Slider from 'react-slick'
import './AboutSlider.css'

const AboutSlider = () => {
    const settings = {
        className: 'slider variable-width',
        dots: false,
        navs: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        variableWidth: true,
    }
    return (
        <div className="pictureSlider">
            <Slider {...settings}>
                <div>
                    <img src="/images/pictures/image_1.jpeg" />
                </div>
                <div>
                    <img src="/images/pictures/image_2.jpeg" />
                </div>
                <div>
                    <img src="/images/pictures/image_3.png" />
                </div>
                <div>
                    <img src="/images/pictures/image_4.jpeg" />
                </div>
                <div>
                    <img src="/images/pictures/image_5.jpeg" />
                </div>
                <div>
                    <img src="/images/pictures/image_6.jpeg" />
                </div>
                <div>
                    <img src="/images/pictures/image_7.png" />
                </div>
                <div>
                    <img src="/images/pictures/image_8.jpeg" />
                </div>
                <div>
                    <img src="/images/pictures/image_9.jpeg" />
                </div>
            </Slider>
        </div>
    )
}

export default AboutSlider
