import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import './Dropdown1.css'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}))

export default function SimpleSelect1(props) {
    const classes = useStyles()

    return (
        <div>
            <FormControl className={`${classes.formControl} dropDown w-100 text-left m-0`}>
                <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
                <Select
                    value={props.selected}
                    onChange={props.onChange}
                    className={classes.selectEmpty}
                    name={props.name}
                    inputProps={{ 'aria-label': 'Without label' }}
                    autoComplete={props.autoComplete || 'on'}
                >
                    {props.dropState
                        ? props.dropState.map((data, index) => (
                              <MenuItem
                                  onClick={() => {
                                      props.stateID(data.id)
                                  }}
                                  value={data.name}
                              >
                                  {data.name}
                              </MenuItem>
                          ))
                        : ''}
                </Select>
            </FormControl>
        </div>
    )
}
