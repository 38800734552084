import React, { useEffect, useContext, useState } from 'react'
import Login from '../views/Login'
import { Route, BrowserRouter as Router, withRouter, Switch } from 'react-router-dom'
import PrivateRoute from './privateRoute'
import Header from '../components/molecules/Header'
import FooterNavigation from '../components/molecules/FooterNavigation'
import Footer from '../components/molecules/Footer'
import Signup from '../views/Signup/signup'
import ResetPassword from '../views/Resetpassword/resetpassword'
import WonAuction from '../views/Dashboard/wonAuction'
import Profile from '../views/Profile/profile'
import FavouritesB from '../views/Favourites/Favourites'
import BankDetails from '../views/Achpayment/paymenttabs'
import Paymenttabs from '../views/Payment/paymenttabs'
import Forgotpassword from '../views/Forgotpassword/Forgotpassword'
import CardDetails from '../views/Signup/carddetails'
// import Registration from '../views/Registration'
// import RegistrationSuccess from '../views/Registration/success'
// import ForgotPassword from '../views/ForgotPassword'
// import ResetPassword from '../views/ResetPassword'
// import VerifyEmail from '../views/VerifyEmail'
import Home from '../views/Home'
import Notification from '../views/Notifications/Notification'
import MyAuction from '../views/Dashboard/MyAuctions'
import Message from '../views/Dashboard/Message'
import ProductView from '../views/ProductView/'
import Search from '../views/Search'
import Verification from '../views/Signup/verification'
import Contactus from '../views/Contactus/contactus'
import Inquires from '../views/Inquires/Inquires'
import Buynow from '../views/Buynow'
import { useCustomMediaQuery } from '../common/components'
import isAuthenticate from '../Utils/Utility/auth'
import Privacypolicy from '../views/StaticPages/Privacypolicy'
import TermsandConditions from '../views/StaticPages/TermsandConditions'
import Checkout from '../views/Checkout'
import Invoice from '../views/Invoice'
import LiveLots from '../views/LiveLots'
import AboutUs from '../views/StaticPages/AboutUs'
import AllReviews from '../views/StaticPages/AllReviews'
import { socket } from '../common/socket'
import PastAuctions from '../views/PastAuctions'
import LivePrice from '../views/LivePrice'
import LotView from '../views/LotView'
import TimedView from '../views/LotViewTimed'
import { useCookies } from 'react-cookie'
import storeDetails from '../actions/storeDetails'
import { useDispatch } from 'react-redux'
import AuctionView from '../views/AuctionView'
import LotViewSearch from '../views/Search/lotsearch'
import NewHome from '../views/Search/newHome'
// import Cart from '../views/Cart'
// import Checkout from '../views/Checkout'
// import MyAuctions from '../views/Dashboard/MyAuctions'
// import MyAccount from '../views/Dashboard/MyAccount'
// import Invoice from '../views/Invoice'
// import CheckoutSkeleton from '../views/Checkout/CheckoutSkeleton'
// import MyInvoices from '../views/Dashboard/MyInvoices'
// import StaticPage from '../views/Static'
// import Error404 from '../views/ErrorPages/Error404'
// import SavedCard from '../views/Dashboard/SavedCard'
// import AuthContext from '../context/auth/authContext'
// import CommonContext from '../context/common/commonConte0xt'
// import csc from 'country-state-city'

const Routes = (props) => {
    const [userIn, setUserin] = useState(false)
    const [cookies, setCookie] = useCookies(['user_session'])
    const dispatch = useDispatch()
    // const authContext = useContext(AuthContext)
    // const { loadUser } = authContext
    // const commonContext = useContext(CommonContext)
    // const { getGlobalVariable, setUSState } = commonContext

    // useEffect(() => {
    //     if (localStorage.token) {
    //         loadUser()
    //     }

    //     getGlobalVariable()
    // }, [])

    // useEffect(() => {
    //     const USStates = []
    //     console.log('getStatesofCountry', csc.getStatesOfCountry('231'))
    //     csc.getStatesOfCountry('231').map((lot) => {
    //         USStates.push({ value: lot.name, show: lot.name })
    //     })
    //     console.log('csc', USStates)
    //     setUSState(USStates)
    // }, [])

    const exclusionArrayHeader = [
        '/login',
        '/signup',
        '/resetpassword',
        '/Forgotpassword',
        '/resetPassword',
        '/cardDetails',
        '/verifyUser',
        // '/contactus',
        '/login/forgot_password',
    ]
    const exclusionArrayFooter = [
        '/login',
        '/signup',
        // '/resetpassword',
        // '/dashboard',
        // '/Dashboard/Notification',
        // '/dashboard',
        // '/Dashboard/Profile',
        // '/Dashboard/FavouritesB',
        // '/Dashboard/Payment',
        // '/Dashboard/Paymenttabs',
        '/Forgotpassword',
        '/resetPassword',
        '/cardDetails',
        '/verifyUser',
        // '/contactus',
        '/login/forgot_password',
    ]

    useEffect(() => {
        if (process.env.REACT_APP_STORE_ID) {
            let storeID = process.env.REACT_APP_STORE_ID
            let body = {
                storeID,
            }
            dispatch(storeDetails(body))
        }
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [props.location.pathname])

    useEffect(() => {
        if (props.location.pathname.includes('livelots')) {
            console.log('Im in')
            setUserin(true)
            let socket_data = {
                action: 'userConnected',
                session: cookies.user_session || '',
                site_id: process.env.REACT_APP_SITE_ID,
            }
            socket.emit('userWatch', socket_data)
        } else {
            if (userIn === true) {
                console.log('Im out')
                setUserin(false)
                let socket_data = {
                    action: 'userDisconnected',
                    session: cookies.user_session || '',
                    site_id: process.env.REACT_APP_SITE_ID,
                }
                socket.emit('userWatch', socket_data)
            }
        }
    }, [props.location.pathname])

    return (
        <>
            {useCustomMediaQuery('(max-width:500px)') && isAuthenticate() != null
                ? exclusionArrayHeader.indexOf(props.location.pathname) < 0 && <FooterNavigation />
                : null}
            {exclusionArrayHeader.indexOf(props.location.pathname) < 0 && <Header />}
            <Switch>
                <Route exact path="/" component={NewHome} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/autologin/:login_email" component={Login} />
                <Route exact path="/auto-login/:login_email" component={Login} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/resetpassword" component={ResetPassword} />
                <Route exact path="/WonAuction" component={WonAuction} />
                <Route exact path="/Dashboard/Notification" component={Notification} />
                <Route exact path="/dashboard" component={MyAuction} />
                <Route exact path="/Dashboard/Profile" component={Profile} />
                <Route exact path="/auction/:id" component={ProductView} />
                <Route exact path="/search/product-buyer-auction/:id" component={ProductView} />
                <Route exact path="/Dashboard/FavouritesB" component={FavouritesB} />
                <Route exact path="/Dashboard/Paymenttabs" component={Paymenttabs} />
                <Route exact path="/Dashboard/Message" component={Message} />
                <Route exact path="/login/forgot_password" component={Forgotpassword} />
                <Route exact path="/cardDetails" component={CardDetails} />
                <Route exact path="/Dashboard/bankDetails" component={BankDetails} />
                <Route exact path="/verifyUser" component={Verification} />
                <Route exact path="/contactus" component={Contactus} />
                <Route exact path="/inquires" component={Inquires} />
                <Route exact path="/Privacypolicy" component={Privacypolicy} />
                <Route exact path="/TermsandConditions" component={TermsandConditions} />
                <Route exact path="/liveLots/:auctionId/:user_id" component={LiveLots} />
                <Route exact path="/lotView/:auctionId/:lotId/:user_id" component={LotView} />
                <Route
                    exact
                    path="/timedlotView/:auctionId/:lotId/:user_id"
                    component={TimedView}
                />
                {/* <Route exact path="/registration" component={Registration} />
                <Route exact path="/activation/success" component={RegistrationSuccess} />
                <Route exact path="/forgot_password" component={ForgotPassword} />
                <Route exact path="/reset_password/:token" component={ResetPassword} />
                <Route exact path="/verify_email/:token" component={VerifyEmail} /> */}
                <Route exact path="/search" component={Search} />
                <Route exact path="/lotsearch" component={LotViewSearch} />
                <Route exact path="/buynow" component={Buynow} />
                <Route exact path="/Checkout" component={Checkout} />
                <Route exact path="/checkout/auction" component={Checkout} />
                <Route path="/Invoice/:id" component={Invoice} />
                <Route path="/aboutus" component={AboutUs} />
                <Route path="/allreviews" component={AllReviews} />
                <Route path="/past_auctions" component={PastAuctions} />
                <Route path="/auction/timed-auction/:id" component={AuctionView} />
                {/* <Route exact path="/newHome" component={NewHome} /> */}
                {/* <PrivateRoute exact path="/cart" component={Cart} />
                <PrivateRoute exact path="/checkout/:cart_id" component={Checkout} />
                <PrivateRoute exact path="/dashboard/:type" component={MyAuctions} />
                <PrivateRoute exact path="/invoices" component={MyInvoices} />
                <PrivateRoute exact path="/myaccount" component={MyAccount} />
                <PrivateRoute exact path="/invoice/:id" component={Invoice} />
                <Route exact path="/skeleton" component={CheckoutSkeleton} />
                <Route
                    exact
                    path={[
                        '/howitworks',
                        '/features',
                        '/pricing',
                        '/testimonials',
                        '/team',
                        '/careers',
                        '/press',
                        '/stores',
                        '/terms',
                        '/legal',
                        '/patents',
                    ]}
                    component={StaticPage}
                />
                <Route exact path="*" component={Error404} /> */}
            </Switch>
            {/* {props.location.pathname === '/login' || <Footer />} */}
            {exclusionArrayFooter.indexOf(props.location.pathname) < 0 && <Footer />}
        </>
    )
}
export default withRouter(Routes)
