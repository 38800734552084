const initial = {
    userdetails: {},
}

const userDetails = (state = initial, action) => {
    switch (action.type) {
        case 'USER':
            return {
                ...state,
                userdetails: action.list,
            }
        default:
            return state
    }
}

export default userDetails
