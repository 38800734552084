import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import './FooterNavigation.css'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import HomeIcon from '@material-ui/icons/Home'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded'
import DashboardIcon from '@material-ui/icons/Dashboard'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
// import { useHistory } from 'react-router-dom'
import { handleRedirectInternal } from '../../../common/components'

const useStyles = makeStyles({
    root: {
        width: 500,
    },
})

function FooterNavigation(props) {
    const classes = useStyles()
    const history = useHistory()
    // const location = useLocation()

    const [value, setValue] = React.useState('')

    const pathname = window.location.pathname

    return (
        <div className="footNavigator">
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue)
                }}
                showLabels
                className={classes.root}
            >
                <BottomNavigationAction
                    className={pathname === '/' && 'Mui-selected'}
                    label="Home"
                    onClick={() => handleRedirectInternal(history, '')}
                    icon={<HomeIcon />}
                />
                <BottomNavigationAction
                    className={pathname === '/search' && 'Mui-selected'}
                    label="Auction"
                    onClick={() => handleRedirectInternal(history, 'search')}
                    icon={<MonetizationOnRoundedIcon />}
                />
                {/* <BottomNavigationAction
                    activeClassName="active"
                    label="Buy Now"
                    onClick={() => handleRedirectInternal(history, 'WonAuction')}
                    icon={<LocalMallIcon />}
                /> */}
                <BottomNavigationAction
                    className={pathname === '/dashboard' && 'Mui-selected'}
                    exact
                    label="Dashboard"
                    onClick={() => handleRedirectInternal(history, 'dashboard')}
                    icon={<DashboardIcon />}
                />
                <BottomNavigationAction
                    exact
                    label="Profile"
                    onClick={() => handleRedirectInternal(history, 'dashboard/profile')}
                    icon={<AccountCircleIcon />}
                />
            </BottomNavigation>
        </div>
    )
}

FooterNavigation.propTypes = {}

export default FooterNavigation
