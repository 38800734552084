import moment from 'moment'
import { addLeadZero } from './index'

const getTimeInterval = (starts, ends, serverTimeSocket) => {
    if (moment(serverTimeSocket).isBefore(moment(starts))) {
        let duration = moment.duration(moment(starts).diff(moment(serverTimeSocket)))
        let timer = {}

        let startDate = new Date(serverTimeSocket)
        let endDate = new Date(starts)
        let timerTime = endDate.getTime() - startDate.getTime()
        let days = 0
        if (Math.floor(timerTime / (1000 * 60 * 60 * 24)).toString().length >= 2) {
            days = Math.floor(timerTime / (1000 * 60 * 60 * 24))
        } else {
            days = ('0' + Math.floor(timerTime / (1000 * 60 * 60 * 24))).slice(-2)
        }

        timer.days = days
        timer.hours = addLeadZero(duration.hours(), 2)
        timer.minutes = addLeadZero(duration.minutes(), 2)
        timer.seconds = addLeadZero(duration.seconds(), 2)
        timer.text = 'Live Auction Starts In:'
        timer.isCompleted = false
        return timer
    } else if (
        moment(serverTimeSocket).isSameOrAfter(moment(starts)) &&
        moment().isBefore(moment(ends))
    ) {
        //console.log("working2==>",ends)
        let duration = moment.duration(moment(ends).diff(moment(serverTimeSocket)))
        let timer = {}

        let start_Date = new Date(serverTimeSocket)
        let end_Date = new Date(ends)
        let timer_Time = end_Date.getTime() - start_Date.getTime()
        let days = 0
        if (Math.floor(timer_Time / (1000 * 60 * 60 * 24)).toString().length >= 2) {
            days = Math.floor(timer_Time / (1000 * 60 * 60 * 24))
        } else {
            days = ('0' + Math.floor(timer_Time / (1000 * 60 * 60 * 24))).slice(-2)
        }
        //console.log("duration===>",duration)
        timer.days = days
        timer.hours = addLeadZero(duration.hours(), 2)
        timer.minutes = addLeadZero(duration.minutes(), 2)
        timer.seconds = addLeadZero(duration.seconds(), 2)
        timer.text = 'Live Auction Ends In:'
        timer.isCompleted = false
        return timer
    } else {
        //console.log("working3")
        let timer = {}
        timer.isCompleted = true
        return timer
    }
}

export default getTimeInterval
