import axios from '.././Utils/Utility/axiosconfig'

export const categoryAction = () => {
    return async (dispatch) => {
        try {
            const { data } = await axios.get('mobileapi/getoverallcatgeory')
            if (data.success === true) {
                dispatch({ type: 'Category', category: data.category_val })
            }
        } catch (err) {
            console.log('categoryAction err======>', err)
        }
    }
}
