import React, { useState, useEffect } from 'react'
import Filter from '../../components/molecules/Filter/Filter'
import { Button } from '@material-ui/core'
import clsx from 'clsx'
import Dropdown from '../../components/atoms/DropDown'
import BuynowGrid from '../../components/molecules/BuynowCard/BuynowGrid'
import BuynowList from '../../components/molecules/BuynowCard/BuynowList'
import axios from '../../Utils/Utility/axiosconfig'
import { useParams, useHistory } from 'react-router-dom'
import qs from 'qs'
import getLotdata from '../../Utils/API/lotsearch'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Pagination from '@material-ui/lab/Pagination'
import { getPaginationCount } from '../../Utils/Utility'

function Buynow(props) {
    const [lotData, setLotData] = useState({})
    const [view, setView] = useState(true)
    const [auctionSearch, setAuctionSearch] = useState([])
    const [totalCount, setTotalCount] = useState('')
    const [body, setBody] = useState(undefined)
    const { id } = useParams()

    const history = useHistory()
    const FilterValue = [
        {
            label: 'Category',
            categoryName: 'Category 1',
        },
        {
            label: 'Sub Category',
            categoryName: 'Category 1',
        },
        {
            label: 'Location',
            categoryName: 'Category 1',
        },
        {
            label: 'Miles Away',
            categoryName: 'Category 1',
        },
    ]
    const searchResultCount = [
        { id: '10', data: '10' },
        { id: '20', data: '20' },
        { id: '30', data: '30' },
    ]
    const sortBy = [
        { id: '2', data: 'Recent' },
        { id: '3', data: 'Oldest' },
    ]
    const handleChange = (e) => {
        let query = new URLSearchParams(props.location.search)
        query.set(e.target.name, e.target.value)
        history.push({ pathname: '/search', search: '?' + query.toString() })
    }
    const BuynowSearch = [
        {
            img: '/images/pdtimg.png',
            ptitle: 'Special Coin',
            description: 'Special Coins Special Special',
            Price: '$ 1069',
            category: 'Old Coins',
        },
    ]
    return (
        <div className="SearchCnt customContainer d-flex">
            <div className="filterWrp">
                <div className="title">Filter</div>
                {FilterValue.map((data, index) => (
                    <Filter title={data.label} label={data.categoryName} />
                ))}
            </div>
            <div className="searchPdtCnt">
                <div className="searchTopCnt d-flex align-items-center justify-content-between">
                    {totalCount !== '' && <p className="result">Showing {totalCount} results</p>}
                    <div className="topcntSearchWrp d-flex">
                        <Button onClick={() => setView(true)}>
                            <span class={`material-icons ${view ? 'active' : ''}`}>apps</span>
                        </Button>
                        <Button onClick={() => setView(false)}>
                            <span class={`material-icons ${!view ? 'active' : ''}`}>
                                format_list_bulleted
                            </span>
                        </Button>
                        <div className="resultPrePage d-flex align-items-center">
                            <p>Results per page :</p>
                            <Dropdown
                                selected={body ? body.sh_limit : searchResultCount[0]['id']}
                                values={searchResultCount}
                                name="limit"
                                handleChange={handleChange}
                            />
                        </div>
                        <div className="resultPrePage d-flex align-items-center">
                            <p>Sort By :</p>
                            <Dropdown
                                selected={body ? body.orderby : sortBy[0]['id']}
                                values={sortBy}
                                name="orderby"
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    {view ? (
                        <div className="searchResult">
                            {BuynowSearch.map((data, index) => (
                                <BuynowGrid
                                    imgSrc={data.img}
                                    Name={data.ptitle}
                                    Price={data.Price}
                                    descrp={data.description}
                                    label="View Auction"
                                    id={data.id}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="searchList">
                            {BuynowSearch.map((data, index) => (
                                <BuynowList
                                    imgSrc={data.img}
                                    category={data.category}
                                    Name={data.ptitle}
                                    descrp={data.description}
                                    Price={data.Price}
                                    label="View Auction"
                                    id={data.id}
                                />
                            ))}
                        </div>
                    )}
                </div>
                <div className="pageNo">
                    {totalCount !== '' && Object.keys(body).length !== 0 && (
                        <Pagination
                            count={getPaginationCount(totalCount, body.sh_limit)}
                            onChange={(e, value) => {
                                let query = new URLSearchParams(props.location.search)
                                query.set('page', value)
                                history.push({
                                    pathname: props.location.pathname,
                                    search: '?' + query.toString(),
                                })
                            }}
                            page={parseInt(body.page)}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Buynow
