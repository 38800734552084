import React from 'react'
import moment from 'moment'
import ImageGallery from 'react-image-gallery'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CustomInput from '../../atoms/Inputs/CustomInput'
import PrimaryButton from '../../atoms/PrimaryButton'
import Timer from '../../molecules/Timer'
import '../../../views/ProductView/ProductView.css'
import 'react-image-gallery/styles/css/image-gallery.css'
import './ProductViewSlider.css'
import { setDecimalPoint } from '../../../Utils/Utility'
import isAuthenticate from '../../../Utils/Utility/auth'
import { Chip } from '@material-ui/core'
import { currencyFormat } from '../../../common/components'

const useStyles = makeStyles({
    list: {
        width: 785,
        padding: '60px 70px 10px 60px',
    },
    fullList: {
        width: 'auto',
    },
})

const ProductViewSlider = ({
    isOpen,
    onCloseClick,
    data,
    addFav,
    amount,
    handleOnChange,
    onKeyPress,
    handleClick,
    handleHistory,
    lotData,
    currentProject,
}) => {
    const classes = useStyles()
    const [currentLotImages, setCurrentLotImages] = React.useState([])
    React.useEffect(() => {
        let lotimages = []
        if (isOpen && data?.lotImages.length > 0) {
            data.lotImages.map((imgObj) =>
                lotimages.push({
                    original: process.env.REACT_APP_PRODUCT_IMG + imgObj.file_name,
                    thumbnail: process.env.REACT_APP_PRODUCT_IMG + imgObj.file_name,
                }),
            )
        }
        setCurrentLotImages(lotimages)
    }, [isOpen])

    return (
        <React.Fragment key={'right'}>
            <Drawer
                className="imgDrawer"
                anchor={'right'}
                open={isOpen}
                onClose={(event) => {
                    if (
                        event.type === 'keydown' &&
                        (event.key === 'Tab' || event.key === 'Shift')
                    ) {
                        return
                    }
                    onCloseClick(event)
                }}
            >
                <div
                    className={clsx(classes.list, {
                        [classes.fullList]: false,
                    })}
                    role="presentation"
                >
                    {/* {isAuthenticated ? (
            <div
              className={`biddingMessages ${
                state.bid_details.bidtopstatus
                  ? state.bid_details.bidtopstatus.includes("lost") ||
                    state.bid_details.bidtopstatus.includes("losing")
                    ? "lost"
                    : state.bid_details.bidtopstatus.includes("winning")
                    ? "winning"
                    : "won"
                  : null
              } d-flex justify-content-center align-items-center`}
            >
              <h3>
                {state.bid_details.bidtopstatus
                  ? state.bid_details.bidtopstatus.includes("lost") ||
                    state.bid_details.bidtopstatus.includes("losing")
                    ? Sentencecase(
                        state.bid_details.bidtopstatus
                          .split("autoBid")
                          .join("bid")
                      )
                    : state.bid_details.bidtopstatus.includes("winning") ||
                      state.bid_details.bidtopstatus.includes("won")
                    ? Sentencecase(
                        state.bid_details.bidtopstatus
                          .toString()
                          .split("$")
                          .join("€ ")
                      )
                    : ""
                  : ""}
              </h3>
            </div>
          ) : (
            ""
          )} */}
                    <span onClick={onCloseClick} className="material-icons closeBtn">
                        clear
                    </span>
                    <ImageGallery
                        items={
                            currentLotImages
                            // data &&
                            // Array.isArray(data.productImages) &&
                            // data.productImages.length !== 0
                            //     ? data.productImages
                            //     : [{ original: '', thumbnail: '' }]
                        }
                        thumbnailPosition="bottom"
                        showNav={false}
                        lazyLoad={true}
                        showBullets={false}
                        showFullscreenButton={true}
                        onErrorImageURL="/images/Logo.png"
                        showPlayButton={false}
                    />
                    <div className="titleFav d-flex justify-content-between align-items-start">
                        <div>
                            {data.sku ? <p className="lotId text-left">Lot Id: {data.sku}</p> : ''}
                            <p className="lotViewTitle mb-2">{data.title}</p>
                            <Chip
                                label={
                                    <span>
                                        EST: {currencyFormat(data.lowest)} -{' '}
                                        {currencyFormat(data.highest)}
                                    </span>
                                }
                            />
                        </div>
                        <span
                            name={data.id}
                            onClick={() => addFav(data.id, data.isFav)}
                            className="material-icons Favicon"
                        >
                            {data.isFav === 1 ? 'favorite' : 'favorite_border'}
                        </span>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="lotDecs">Description :</p>
                    </div>
                    <p className="lotDecsCnt mt-2 mb-2 pb-0">{data.description}</p>
                    <hr className="mb-4" />
                    <div className="bidDate d-flex justify-content-between">
                        <div className="bidWrp d-flex">
                            <p className="totlaBidsTitle">Total Bids :</p>
                            <a className="bidNo" onClick={(e) => handleHistory(data.id)}>
                                {data.bid_count}
                            </a>
                        </div>
                        <div className="dateWrp d-flex">
                            {/* <p className="DateTime">Closing Date :</p>
                            <p className="DateTime">
                                {moment(data.date_closed).format('MM/DD/YYYY HH:mm A')}{' '}
                            </p> */}
                        </div>
                    </div>
                    <div className="timerBid pdtVwSlider d-flex align-items-center">
                        <Timer
                            className="lotTimer"
                            productTime={{
                                start: moment().subtract(1, 'hour').format(),
                                end: data.date_closed,
                            }}
                        />
                        {new Date() < new Date(data.date_added) ? (
                            <div className="placeBid sliderPlaceBid d-flex justify-content-end align-items-end mt-4">
                                <>
                                    <CustomInput
                                        placeholder="Enter Your Bid"
                                        variant="standard"
                                        placeholder={`Enter $${setDecimalPoint(
                                            parseFloat(data.next_bid),
                                            2,
                                        )} or more`}
                                        onChange={handleOnChange}
                                        pattern="^\d*(.\d{0,2})?$"
                                        name={String(data.id)}
                                        value={amount[data.id] || ''}
                                        onKeyPress={(e) => onKeyPress(e, data.id)}
                                    />
                                    <PrimaryButton
                                        label="Place Maximum Bid"
                                        disabled={
                                            isAuthenticate() === undefined ||
                                            amount[data.id] === undefined ||
                                            amount[data.id] === ''
                                        }
                                        onClick={(e) =>
                                            handleClick(
                                                {
                                                    id: data.id,
                                                    wsprice: amount[data.id],
                                                    bid_increment: data.incrementamt,
                                                },
                                                parseFloat(data.next_bid),
                                                lotData.con_check,
                                                data.id,
                                                data.incrementamt,
                                                data.wprice,
                                                data.current_max_offer_bid,
                                            )
                                        }
                                    />
                                </>
                            </div>
                        ) : currentProject &&
                          data.market_status === 'open' &&
                          new Date() < new Date(data.date_closed) ? (
                            <div className="placeBid sliderPlaceBid d-flex justify-content-end align-items-end mt-4">
                                <>
                                    <CustomInput
                                        placeholder="Enter Your Bid"
                                        variant="standard"
                                        placeholder={`Enter $${setDecimalPoint(
                                            parseFloat(data.next_bid),
                                            2,
                                        )} or more`}
                                        onChange={handleOnChange}
                                        pattern="^\d*(.\d{0,2})?$"
                                        name={String(data.id)}
                                        value={amount[data.id] || ''}
                                        onKeyPress={(e) => onKeyPress(e, data.id)}
                                    />
                                    <PrimaryButton
                                        label="Place Maximum Bid"
                                        disabled={
                                            isAuthenticate() === undefined ||
                                            amount[data.id] === undefined ||
                                            amount[data.id] === ''
                                        }
                                        onClick={(e) =>
                                            handleClick(
                                                {
                                                    id: data.id,
                                                    wsprice: amount[data.id],
                                                    bid_increment: data.incrementamt,
                                                },
                                                parseFloat(data.next_bid),
                                                lotData.con_check,
                                                data.id,
                                                data.incrementamt,
                                                data.wprice,
                                                data.current_max_offer_bid,
                                            )
                                        }
                                    />
                                </>
                            </div>
                        ) : (
                            ' '
                            // <div className="enterLvTst p-2">
                            //     <span className="material-icons mb-3">gavel</span>
                            //     <br />
                            //     Enter Live Auction to Bid
                            // </div>
                        )}
                    </div>
                </div>
            </Drawer>
        </React.Fragment>
    )
}

export default ProductViewSlider
