import { Category } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import Categories from '../../components/molecules/Categories'
import GridView from '../../components/molecules/ProductCard/GridView'
import './Home.css'
import getLotdata from '../../Utils/API/lotsearch'
import { Userdetails } from '../../actions/useraction'
import { connect, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import VerticalSlider from '../../components/organisms/VerticalSlider'
import ImageGallerySlider from '../../components/organisms/ImageGallerySlider'
import HomeTestimonial from '../../components/organisms/HomeTestimonial'
import HomeContact from '../Contactus/HomeContact'
import HomeAuctionCard from '../../components/molecules/ProductCard/HomeAuctionCard'
import HomeAuction from './HomeAuction'
import { LazyLoadImage } from 'react-lazy-load-image-component'

function Home(props) {
    useEffect(() => {
        props.User()
    }, [])

    const state_loc = useSelector((state) => state.storeDetails)
    var banner_image = []
    if (state_loc.storeDetails?.banner_image_arr?.length > 0) {
        banner_image = state_loc.storeDetails.banner_image_arr
    }

    const categoryList = [
        {
            label: 'Category 1',
            imgSrc: './images/cat.png',
        },
        {
            label: 'Category 2',
            imgSrc: './images/cat.png',
        },
        {
            label: 'Category 3',
            imgSrc: './images/cat.png',
        },
        {
            label: 'Category 4',
            imgSrc: './images/cat.png',
        },
        {
            label: 'Category 5',
            imgSrc: './images/cat.png',
        },
        {
            label: 'Category 6',
            imgSrc: './images/cat.png',
        },
    ]

    const [auctionSearch, setAuctionSearch] = useState([])
    const isMobile = !useMediaQuery('(min-width:768px)')

    useEffect(() => {
        // getData({ orderby: '2', page: 1, searchbar: '', sh_limit: '9', auc_type: 1 })
        getData({
            orderby: '2',
            page: 1,
            searchbar: '',
            sh_limit: '10',
            custom_field_3: [],
            sub_cat_id: [],
        })
    }, [])

    async function getData(body) {
        body.is_auction = 1
        let { data, count } = await getLotdata(body)
        setAuctionSearch(Array.isArray(data) ? data : [])
    }

    const history = useHistory()

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        swipeToSlide: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    }

    const bannerSettings = {
        dots: false,
        infinite: true,
        autoplay: true,
        swipeToSlide: true,
        autoplaySpeed: 3500,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <img src="/images/bannernext.svg" />,
        prevArrow: <img src="/images/bannerprev.svg" />,
    }

    const hiwDtl = [
        {
            name: 'Buying',
            image: '/images/cart_home.svg',
        },
        {
            name: 'Selling',
            image: '/images/selling_home.svg',
        },
        {
            name: 'Trading',
            image: '/images/trading_home.svg',
        },
        {
            name: 'Auctions',
            image: '/images/gavel_home.svg',
        },
    ]

    const ourStaffs = [
        {
            name: 'Ken | CEO & President',
            image: '/images/image_1.png',
        },
        {
            name: 'David | Executive VP',
            image: '/images/image_2.png',
        },
        {
            name: 'Mina | Executive Assistant',
            image: '/images/image_3.png',
        },
        {
            name: 'Julia | Accounting & Administration',
            image: '/images/image_4.png',
        },
        {
            name: 'Takumi | Business Development',
            image: '/images/image_5.png',
        },
        {
            name: 'Roxana | Data Entry',
            image: '/images/image_6.png',
        },
        {
            name: 'Ilona | Lead Data Entry',
            image: '/images/image_7.png',
        },
        {
            name: 'Alina | Data Entry',
            image: '/images/image_8.png',
        },
        {
            name: 'Janelle | Data Entry',
            image: '/images/image_9.png',
        },
        {
            name: 'Bennett | Business Development Intern',
            image: '/images/image_10.png',
        },
    ]

    return (
        <div className="homeCnt">
            <div className="bnrCnt row justify-content-between align-items-stretch wrap-reverse">
                <div className="wlecomeNt mobilMarginBotton col-md-6 col-12 pr-0">
                    <div className="lftCntnr">
                        <h2>We are the Northwest’s most trusted resource for precious metals!</h2>
                        <p>Have Questions?</p>
                        {isMobile ? (
                            <div className="callUsLink">
                                <a href="tel:3602535565">Call us now!</a>
                            </div>
                        ) : (
                            <PrimaryButton
                                label="Call us now!"
                                btnSize="large"
                                onClick={() => history.push('/contactus')}
                            />
                        )}
                    </div>
                </div>
                <div className="col-md-6 col-12 pl-0">
                    <Slider {...bannerSettings}>
                        {banner_image.map((data, index) => (
                            <div>
                                <LazyLoadImage
                                    className="bnrImg"
                                    src={`${process.env.REACT_APP_SELLER_DOMAIN}uploads/store/${data.image_name}`}
                                    effect="blur"
                                    placeholderSrc="/images/imageLoading.svg"
                                    height="100%"
                                    width="100%"
                                    alt="Next Lot"
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            {auctionSearch.length !== 0 && (
                <div className="auctionCnt mtp120">
                    <h3 className="auctionHeading">Auctions</h3>
                    <div className="hmcustomContainer">
                        <HomeAuction auctionSearch={auctionSearch} />
                    </div>
                </div>
            )}
            <div className="hiwGrid hmcustomContainer mtp120">
                {hiwDtl.map((data, index) => (
                    <div className="hiwCard" key={index}>
                        <img src={data.image} />
                        <h5>{data.name}</h5>
                    </div>
                ))}
            </div>
            {/* <div className="categoriesCnt ">
                <p className="catHeading">Categories</p>
                <div className="category">
                    <Slider {...settings}>
                        {Array.isArray(props.category.category) &&
                            props.category.category.map((data, index) => (
                                <Categories
                                    filter={data.filters}
                                    label={data.name}
                                    id={data.id}
                                    imgSrc={'/images/cat_placeholder.png'}
                                />
                            ))}
                    </Slider>
                </div>
            </div> */}
            {/* <h3 className="auctionHeading mtp120">Meet Our Staff</h3>
            <div className="orStfWrpr mtp20 hmcustomContainer">
                {ourStaffs.map((data, index) => (
                    <>
                        <div className="ourSfCard" key={index}>
                            <img src={data.image} />
                            <h4>{data.name}</h4>
                        </div>
                    </>
                ))}
            </div> */}
            {/* <div className="imgGlryWrpr mtp120 hmcustomContainer">
                <div className="d-flex align-items-center justify-content-between mb-5">
                    <h4>Image Gallery</h4>
                    <div className="d-flex align-items-center">
                        <a
                            href="https://www.facebook.com/people/Kzoo-Bullion/100078354872857/?_ga=2.106915843.1820215765.1675491310-2039143036.1675491310&_gac=1.56975064.1675507339.Cj0KCQiA8t2eBhDeARIsAAVEga1i8jSYx9BrMh1m5YbbdnEq3x0SUVYTkTuWHKsypYZ3a9HwqgH5cTUaArcSEALw_wcB"
                            className="mr-5"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="/images/facebook.svg" />
                        </a>
                    </div>
                </div>
                <ImageGallerySlider />
            </div> */}
            <h3 className="auctionHeading mtp120">Our Reviews</h3>
            <div className="tstmnlCntnr hmcustomContainer pb-5">
                <HomeTestimonial />
            </div>
            <h3 className="auctionHeading mtp120">Contact Us</h3>
            <div className="hmcustomContainer mt-5">
                <HomeContact />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    category: state.category ? state.category : {},
})

const mapDispatchToProps = (dispatch) => {
    return {
        User: () => dispatch(Userdetails()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
