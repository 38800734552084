import { Button } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import CustomInput from '../../atoms/Inputs/CustomInput'
import PrimaryButton from '../../atoms/PrimaryButton'
import '../../molecules/ProductCard/ProductCard.css'
import SecondaryButton from '../../atoms/SecondaryButton'
import { handleRedirectInternal, currencyFormat } from '../../../common/components'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import commonBidding from '../../../Utils/API/nomalBidding'
import isAuthenticate from '../../../Utils/Utility/auth'
import Swal from 'sweetalert2'
const Bidding = (props) => {
    const [product, setProduct] = React.useState()
    const [product_id, setProductId] = useState()
    const history = useHistory()

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    useEffect(() => {
        setProductId(props.id)
        formik.setFieldValue('wsprice', '')
        formik.setErrors({})
        formik.setTouched(false)
    }, [props.id])

    const validationArray = Yup.object({
        wsprice: Yup.number()
            .min(props.next_bid ? props.next_bid : 0, `Min. Bid $${props ? props.next_bid : 0}`)
            .required('Enter Bid Amount'),
    })

    const formik = useFormik({
        initialValues: {
            wsprice: '',
            id: product_id,
            auction_io: 1,
            email: props.user.email,
            first_name: props.user.first_name,
            last_name: props.user.last_name,
            bid_increment: props.data.incrementamt,
            hardbid: 0,
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (new Date(product.date_added) > new Date()) {
                return Swal.fire({
                    icon: 'warning',
                    title: 'Wait until auction starts!',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            }
            values.id = product_id
            commonBidding(values)
            formik.values.wsprice = ''
        },
    })

    return (
        <>
            {product ? (
                <>
                    {product.market_status === 'open' ? (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="biddingCnt d-flex align-items-start mt-4">
                                <div className="mr-2">
                                    <CustomInput
                                        size="small"
                                        label="Enter bid"
                                        value={formik.values.wsprice}
                                        name="wsprice"
                                        inputProps={{
                                            min: props.next_bid,
                                        }}
                                        onChange={formik.handleChange}
                                        placeholder={
                                            props.next_bid
                                                ? `Min. Bid ` + currencyFormat(props.next_bid)
                                                : ''
                                        }
                                        min="0"
                                        type="number"
                                        error={
                                            formik.errors.wsprice &&
                                            formik.touched.wsprice &&
                                            formik.errors.wsprice
                                        }
                                        helperText={
                                            formik.errors.wsprice &&
                                            formik.touched.wsprice &&
                                            formik.errors.wsprice
                                        }
                                    />
                                </div>
                                {isAuthenticate() ? (
                                    product.bidtopstatus === 'winner' ? (
                                        <SecondaryButton
                                            type="submit"
                                            label="Increase Bid"
                                            btnSize="small"
                                        />
                                    ) : (
                                        <PrimaryButton
                                            type="submit"
                                            label="Place Bid"
                                            btnSize="small"
                                        />
                                    )
                                ) : (
                                    <PrimaryButton
                                        label="Login to Bid"
                                        btnSize="small"
                                        onClick={() => handleRedirectInternal(history, 'login')}
                                    />
                                )}
                            </div>
                        </form>
                    ) : null}
                </>
            ) : null}
        </>
    )
}

export default Bidding
