import React, { useState, useContext, useEffect } from 'react'
import ProductContext from '../../../context/product/productContext'
import AuthContext from '../../../context/auth/authContext'

import './FavoriteCheckbox.css'

const FavoriteCheckbox = ({ watchlisted, project_id, dummy, onchangedata, index, favIdChild }) => {
    // const productContext = useContext(ProductContext)
    // const authContext = useContext(AuthContext)
    // const { isAuthenticated } = authContext

    // const [checked, setChecked] = useState(parseInt(watchlisted) > 0 ? true : false)
    // const { addWatchlist, removeWatchlist } = productContext

    // useEffect(() => {
    //     setChecked(parseInt(watchlisted) > 0 ? true : false)
    //     return () => {
    //         setChecked(false)
    //     }
    // }, [project_id, watchlisted])

    const toggleFavourite = () => {
        // if (checked) {
        //     // removeWatchlist({ project_id })
        //     // setChecked(false)
        // } else {
        //     // addWatchlist({ project_id })
        //     // setChecked(true)
        // }
    }

    return (
        <>
            {/* {isAuthenticated && ( */}
            <div className="favoriteCheck d-flex justify-content-center align-items-center">
                <input
                    id={project_id}
                    type="checkbox"
                    // checked={checked}
                    onChange={(e) => {
                        console.log('datatatatatat')
                        if (dummy === 'dummy') {
                            onchangedata(favIdChild)
                        }
                    }}
                    checked={watchlisted === 0 ? false : true}
                />
                <label for={project_id}>
                    <span className="material-icons">favorite</span>
                </label>
            </div>
            {/* )} */}
        </>
    )
}
export default FavoriteCheckbox
