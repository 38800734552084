// import axios from '.././Utils/Utility/axiosconfig'
// import isAuthenticate from '.././Utils/Utility/auth'
// import axios from '.././Utils/Utility/axiosconfig'

export const livePriceAction = (list) => {
    console.log('livePriceAction', list)
    return {
        type: 'LIVEPRICE',
        list,
    }
}
// axios.defaults.baseURL = 'https://metals-api.com/api/'

// let priceToken = '3ka24qf6nf210yqdc5eh5myghrd0jgx58a5gd5zjhk421p1v6a07392rvxvk'

// const livePriceDetails = (body) => {
//     return async (dispatch) => {
//         try {
//             const res = await axios.get('/api/metals_price')
//             console.log('dispatch(livePriceAction(res.data.rates))', res)
//             if (res.data.status === 'success') {
//                 dispatch(livePriceAction(res.data.rates))
//             } else {
//                 return `something went wrong`
//             }
//         } catch (error) {
//             console.log(error)
//         }
//     }
//     // return async (dispatch) => {
//     //     try {
//     //         const res = await axios.get(`latest?access_key=${priceToken}&base=USD`)
//     //         console.log('live price response', res)
//     //         if (res.data.success === true) {
//     //             dispatch(livePriceAction(res.data.rates))
//     //         } else {
//     //             return `something went wrong`
//     //         }
//     //     } catch (error) {
//     //         console.log(error)
//     //     }
//     // }
// }

// export default livePriceDetails
