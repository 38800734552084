import React, { useState } from 'react'
import Dashboard from '../Dashboard/Dashboard'
import Chatclone from '../../components/organisms/Chat/chatclone'
import './Message.css'
function Message() {
    return (
        <div className="privateChat">
            <Dashboard>
                <Chatclone />
            </Dashboard>
        </div>
    )
}

export default Message
