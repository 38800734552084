const category = (state = { category: '' }, action) => {
    switch (action.type) {
        case 'Category':
            return {
                ...state,
                category: action.category,
            }
        default:
            return state
    }
}

export default category
