import React, { useEffect, useState } from 'react'
import './Filter.css'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useHistory, useLocation } from 'react-router-dom'
import CheckBox from '../../atoms/CheckBox'
import CustomInput from '../../atoms/Inputs/CustomInput'
import PrimaryButton from '../../atoms/PrimaryButton'
import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}))

export default function Filter(props) {
    const history = useHistory()
    const location = useLocation()
    const [bodvalue, setBodValue] = useState({
        price_from: '',
        price_to: '',
    })
    const handlechanges = (e) => {
        console.log('array===>', props)
        let query = new URLSearchParams(location.search)
        if (e.target.checked === true) {
            if (e.target.name === 'Origin') {
                let catarr = [...props.cat_id, e.target.value]
                query.set('cat_id', catarr.toString())
            } else {
                let arr = [...props.sub_cat_id, e.target.value]
                query.set('sub_cat_id', arr.toString())
            }
        } else {
            let arr = [...props.sub_cat_id, e.target.value]
            query.set('sub_cat_id', arr.filter((data) => data !== e.target.value).toString())
        }
        // query.set(e.target.name, arr.toString())
        history.push({ pathname: '/search', search: '?' + query.toString() })
    }

    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const hanchange = (e) => {
        if (e.target.checked == true) {
            var data_arr = props.body[props.org_name]
            data_arr.push(e.target.value)
            props.setBody({ ...props.body, [props.org_name]: data_arr, page: 1 })
        } else {
            var data_arr = props.body[props.org_name]
            data_arr = data_arr.filter((val) => val.toString() != e.target.value.toString())
            props.setBody({ ...props.body, [props.org_name]: data_arr, page: 1 })
        }
    }

    const handleChange1 = (e) => {
        setBodValue({ ...bodvalue, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        if (bodvalue.price_from != '' && bodvalue.price_to != '') {
            props.setBody({
                ...props.body,
                from_price_io: bodvalue.price_from,
                to_price_io: bodvalue.price_to,
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Please Enter Both Values',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })
        }
    }

    return (
        <div className={`${classes.root} filter`}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading}>{props.title}</Typography>
                    {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                    {props.price_filter ? (
                        <div className="pl-25">
                            <div className="mb-4">
                                <CustomInput
                                    variant="standard"
                                    onChange={handleChange1}
                                    //onBlur={handleBlur}
                                    type="number"
                                    label="$ From"
                                    name="price_from"
                                />
                            </div>
                            <div className="mb-4">
                                <CustomInput
                                    variant="standard"
                                    onChange={handleChange1}
                                    //onBlur={handleBlur}
                                    type="number"
                                    label="$ To"
                                    name="price_to"
                                />
                            </div>

                            <PrimaryButton
                                onClick={(e) => handleSubmit(e)}
                                label="Submit"
                            ></PrimaryButton>
                        </div>
                    ) : (
                        <>
                            {Array.isArray(props.label) &&
                                props.label.map((data) => (
                                    <Typography>
                                        <CheckBox
                                            label={data.name}
                                            onchange={
                                                props.change_handle ? hanchange : handlechanges
                                            }
                                            name={props.title}
                                            value={data.id}
                                            disable_filter={props.disabled_filter}
                                        />
                                    </Typography>
                                ))}
                        </>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
