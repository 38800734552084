const biddingReducer = (state = { bidding: {} }, action) => {
    switch (action.type) {
        case 'Bidding':
            return {
                ...state,
                bidding: action.bidding,
            }
        default:
            return state
    }
}

export default biddingReducer
