import React from 'react'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton/index'
import CustomInputs from '../../components/atoms/Inputs/CustomTextArea'
import { Formik } from 'formik'
import * as yup from 'yup'
import axios from '../../Utils/Utility/axiosconfig'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import './Inquires.css'

const Inquires = () => {
    const history = useHistory()
    const contactusschema = yup.object({
        name: yup
            .string()
            .required('Required')
            .matches(/^[aA-zZ]+$/, 'only alphabets are allowed'),
        email: yup
            .string()
            .matches(/^[a-zA-Z]+[0-9+_.-\]+@[a-zA-Z0-9.-]+$/, 'Please enter valid Email')
            .required('Enter your Email Id'),
        pnumber: yup.number().typeError('Enter valid number').required('Required!'),
        message: yup.string().required('Required!'),
    })

    const contactusapi = async (data) => {
        try {
            const res = await axios.post('mobileapi/send_contactus', {
                name: data.name,
                from_email: data.email,
                phone_number: data.pnumber,
                messages: data.message,
                subject: 'coins',
            })

            if (res.data.success === true) {
                Swal.fire({
                    title: 'Data sent successfully',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                    position: 'center',
                })
                history.push('/')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const acceptstring = (change) => (e) => {
        console.log('cccc', e.target.value)
        if (/^[aA-zZ]+$/.test(e.target.value) || e.target.value == '') {
            change(e)
        }
    }
    const acceptnumber = (change) => (e) => {
        console.log('cccc', e.target.value)
        if (/^[0-9]+$/.test(e.target.value) || e.target.value == '') {
            change(e)
        }
    }

    return (
        <div className="custom-container contactHead">
            <form>
                <div className="Contactus-Banner">
                    <Formik
                        initialValues={{ name: '', email: '', pnumber: '', message: '' }}
                        validationSchema={contactusschema}
                        onSubmit={(values) => {
                            contactusapi(values)
                        }}
                    >
                        {({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <h1>Consignment Inquires</h1>
                                    <div className="contact-form">
                                        <CustomInput
                                            variant="standard"
                                            label="Name"
                                            name="name"
                                            value={values.name}
                                            onChange={acceptstring(handleChange)}
                                            // onChange={handleChange}
                                            // pattern={'[0-9]{1,9}'}
                                            // onBlur={handleBlur}
                                            helperText={
                                                errors.name && touched.name ? errors.name : ''
                                            }
                                        />
                                        <CustomInput
                                            variant="standard"
                                            label="Email Address"
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={
                                                errors.email && touched.email ? errors.email : ''
                                            }
                                        />
                                        <CustomInput
                                            variant="standard"
                                            label="Phone Number"
                                            name="pnumber"
                                            value={values.pnumber}
                                            onChange={acceptnumber(handleChange)}
                                            onBlur={handleBlur}
                                            inputProps={{ maxLength: 10 }}
                                            helperText={
                                                errors.pnumber && touched.pnumber
                                                    ? errors.pnumber
                                                    : ''
                                            }
                                        />
                                        <CustomInput
                                            variant="standard"
                                            label="Message"
                                            multiline
                                            rows={7}
                                            name="message"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={
                                                errors.message && touched.message
                                                    ? 'Please Enter Message'
                                                    : ''
                                            }
                                        />
                                        <div className="addimages">
                                            <input type="file" />
                                            <button className="choosebtn">Add Images</button>
                                        </div>
                                        <PrimaryButton label="Submit" type="submit" />
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </form>
        </div>
    )
}

export default Inquires
