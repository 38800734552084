const serverTime = (state = { time: '' }, action) => {
    switch (action.type) {
        case 'Time':
            return {
                ...state,
                time: action.time,
            }
        default:
            return state
    }
}

export default serverTime
