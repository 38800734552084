import axios from '.././Utils/Utility/axiosconfig'
import isAuthenticate from '.././Utils/Utility/auth'

export const lotSearchAction = (list) => ({
    type: 'LOTSEARCH',
    list,
})

const lotSearchDetails = (body) => {
    return async (dispatch) => {
        try {
            const res = await axios.post('api/lotSearch', body)
            console.log('auction search response', res)
            if (res.data.status === 'success') {
                dispatch(lotSearchAction(res.data.response))
            } else {
                return `something went wrong`
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export default lotSearchDetails
