export const messageSocket = (data) => {
    return {
        type: 'chat',
        data,
    }
}

export const buyermessageScoket = (data) => {
    return {
        type: 'buyerchat',
        data,
    }
}
