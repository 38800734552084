import React, { useContext, useEffect, useState } from 'react'
import './FilterPanel.css'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckBox from '../../atoms/CheckBox'
import RadioBox, { GreenRadio } from '../../atoms/RadioBox'
import moment from 'moment'
import CommonContext from '../../../context/common/commonContext'
import { useFormik } from 'formik'
import { Button, TextField } from '@material-ui/core'
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dayjs } from 'dayjs'

const dateFormat = 'MM-DD-YYYY'

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        border: '1px solid #d3d3d3',
        minHeight: 56,
        '&$expanded': {
            minHeight: 50,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails)

const FilterPanel = (props) => {
    const [expanded, setExpanded] = React.useState('panel0')
    const [selectdata, setSelectDate] = useState(Dayjs || null)
    const sortValues = props.sorts
    const formik = useFormik({
        initialValues: {
            category: [],
            condition: [],
            location: [],
            radius: '',
            price: '',
        },
    })

    const filterValues = [
        {
            title: 'Auction Type',
            type: 'check',
            name: 'auction_type',
            org_name: 'timed_live',
            item: [
                { id: 'all', description: 'All Auctions' },
                { id: 'timed', description: 'Timed' },
                { id: 'live', description: 'Live' },
            ],
        },
        {
            title: 'Category',
            type: 'check',
            name: 'category',
            item: props.auctionCat,
            org_name: 'cat_id',
        },
        {
            title: 'Condition',
            type: 'check',
            name: 'condition',
            item: [
                { id: 1, description: 'New' },
                { id: 2, description: 'Damaged' },
                { id: 3, description: 'Open Box' },
            ],
            org_name: 'condition',
        },
        {
            title: 'Date Range',
            type: 'date',
            name: 'date',
        },
    ]

    const [filetrvalue, setFileterValue] = useState(filterValues)

    useEffect(() => {
        console.log('999999999999999999999999999999999999999999999', props)
        if (props?.body_val?.timed_live == 'timed') {
            setFileterValue([
                {
                    title: 'Auction Type',
                    type: 'check',
                    name: 'auction_type',
                    org_name: 'timed_live',
                    item: [
                        { id: 'all', description: 'All Auctions' },
                        { id: 'timed', description: 'Timed' },
                        { id: 'live', description: 'Live' },
                    ],
                },
                {
                    title: 'Category',
                    type: 'check',
                    name: 'category',
                    item: props.auctionCat,
                    org_name: 'cat_id',
                },
                {
                    title: 'Condition',
                    type: 'check',
                    name: 'condition',
                    item: [
                        { id: 1, description: 'New' },
                        { id: 2, description: 'Damaged' },
                        { id: 3, description: 'Open Box' },
                    ],
                    org_name: 'condition',
                },

                {
                    title: 'Date Range',
                    type: 'date',
                    name: 'date',
                },
            ])
        } else {
            setFileterValue([
                {
                    title: 'Auction Type',
                    type: 'check',
                    name: 'auction_type',
                    org_name: 'timed_live',
                    item: [
                        { id: 'all', description: 'All Auctions' },
                        { id: 'timed', description: 'Timed' },
                        { id: 'live', description: 'Live' },
                    ],
                },
                {
                    title: 'Category',
                    type: 'check',
                    name: 'category',
                    item: props.auctionCat,
                    org_name: 'cat_id',
                },
                {
                    title: 'Condition',
                    type: 'check',
                    name: 'condition',
                    item: [
                        { id: 1, description: 'New' },
                        { id: 2, description: 'Damaged' },
                        { id: 3, description: 'Open Box' },
                    ],
                    org_name: 'condition',
                },
                {
                    title: 'Date Range',
                    type: 'date',
                    name: 'date',
                },
            ])
        }
    }, [props])

    const handleChange = (panel) => (event, newExpanded) => {
        if (panel === expanded && !newExpanded) {
            setExpanded(newExpanded ? panel : false)
        } else {
            setExpanded(newExpanded ? panel : false)
        }
    }

    const handChange = (e) => {
        if (e.target.checked == true) {
            var data_arr = props.body_val[e.target.name]
            if (e.target.name == 'cat_id' || e.target.name == 'condition') {
                data_arr.push(e.target.value)
            } else {
                data_arr = e.target.value
            }

            props.setBody({ ...props.body_val, [e.target.name]: data_arr, page: 1 })
        } else {
            var data_arr = props.body_val[e.target.name]

            if (e.target.name == 'cat_id' || e.target.name == 'condition') {
                data_arr = data_arr.filter((val) => val.toString() != e.target.value.toString())
            } else {
                data_arr = e.target.value
            }
            props.setBody({ ...props.body_val, [e.target.name]: data_arr, page: 1 })
        }
    }

    const clearSearch = (e) => {
        props.setLoading(false)
        props.setBody({
            ...props.body_val,
            from_date: '',
            to_date: '',
            cat_id: [],
            timed_live: 'all',
            condition: [],
            page: 1,
        })
        setTimeout(() => {
            props.setLoading(true)
        }, 3000)
    }

    return (
        <div className="filterPanel">
            <div className="d-flex justify-content-between align-items-center filterTitleCnt">
                <h4 className="fpTitle">Filters</h4>
                <Button onClick={(e) => clearSearch(e)} className="filterClear">
                    <i className="material-icons">cancel</i> Clear all
                </Button>
            </div>
            <div className="filterAcc">
                {filetrvalue.map((data, index) => (
                    <Accordion
                        square
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1d-content"
                            id={`panel${index}d-header`}
                        >
                            <h6 className="accTitle">{data.title}</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="filterCheck d-flex align-items-center flex-wrap">
                                {data.type === 'check' &&
                                    data.item.map((d, i) => (
                                        <>
                                            <CheckBox
                                                name={data.org_name}
                                                label={d.description}
                                                checked={
                                                    props?.body_val[data?.org_name]
                                                        ?.toString()
                                                        .includes(d.id)
                                                        ? true
                                                        : false
                                                }
                                                value={d.id.toString()}
                                                onchange={handChange}
                                            />
                                        </>
                                    ))}

                                {data.type === 'date' && (
                                    <>
                                        <div className="form-group">
                                            <DatePicker
                                                label={'From'}
                                                value={props.body_val.from_date}
                                                onChange={(val) => {
                                                    props.setBody({
                                                        ...props.body_val,
                                                        from_date: val,
                                                    })
                                                }}
                                                renderInput={(params) => (
                                                    <>
                                                        <TextField {...params} />
                                                    </>
                                                )}
                                            ></DatePicker>
                                            <DatePicker
                                                label={'To'}
                                                value={props.body_val.to_date}
                                                onChange={(val) => {
                                                    props.setBody({
                                                        ...props.body_val,
                                                        to_date: val,
                                                    })
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            ></DatePicker>
                                        </div>
                                    </>
                                )}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </div>
    )
}
export default FilterPanel
