//LOGO
export const logo = '/images/Logo.png'

export const testimonial = [
    {
        message:
            'Kzoo Mint is my favorite place to purchase precious metals! He has not made one mistake with me. Which just goes to show you the professionalism we all have come to love so much! Also. their packaging is second to none! We’ve done a ton of business together! 100 percent, the most legit dealer I know.',
        userName: 'John A',
        stars: '5',
    },
    {
        message: `I have spent 10’s of thousands with Kzoo and they have never let me down EVER! Not only do they have the very best prices out there, but they’re very professional, courteous and my purchases always show up exceptionally well packaged. Buy with the utmost confidence!`,
        userName: 'Mike P',
        stars: '5',
    },

    {
        message:
            'Extremely professional from initiation to closure of a sale. Product knowledge and availability is one of the reasons I shop with them. The leverage they have with their suppliers makes K-ZOO mint one of the industry’s leaders in pricing.',
        userName: 'Dustin W',
        stars: '5',
    },
    {
        message: `K-Zoo Mint is a very professional and reputable company. They offer great selection with the best prices around. I have made numerous purchases with nothing but the upmost professionalism, great customer contact, and secure shipping. Will be a customer for life! Always looking forward to the next purchases.`,
        userName: 'Jeremy N',
        stars: '5',
    },
]

export const removeHTMLTags = (data) => {
    let tempEle = document.createElement('span')
    tempEle.innerHTML = data
    let convertedText = tempEle.textContent || tempEle.innerText || ''
    return convertedText
}
