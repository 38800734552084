import React, { useEffect } from 'react'
// import livePriceDetails from '../../actions/livePriceAction'
import './LivePrice.css'
import { connect, useDispatch } from 'react-redux'

const LivePrice = (props) => {
    // const dispatch = useDispatch()

    // useEffect(() => {
    //     dispatch(livePriceDetails())
    // }, [])

    return (
        <div className="lvPrcWrpr d-flex align-items-center justify-content-center">
            <p className="lvTtle">LIVE PRICES:</p>
            <p className="vlvs">
                GOLD{' '}
                <span>{props.livePrice.livePrice.gold ? props.livePrice.livePrice.gold : '-'}</span>
            </p>
            <p>|</p>
            <p className="vlvs">
                SILVER{' '}
                <span>
                    {props.livePrice.livePrice.silver ? props.livePrice.livePrice.silver : '-'}
                </span>
            </p>
            <p>|</p>
            <p className="vlvs">
                PLATINUM{' '}
                <span>
                    {props.livePrice.livePrice.platinum ? props.livePrice.livePrice.platinum : '-'}
                </span>
            </p>
            <p>|</p>
            <p className="vlvs">
                PALLADIUM{' '}
                <span>
                    {props.livePrice.livePrice.palladium
                        ? props.livePrice.livePrice.palladium
                        : '-'}
                </span>
            </p>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        livePrice: state.livePrice ? state.livePrice : '',
    }
}

export default connect(mapStateToProps, null)(LivePrice)
