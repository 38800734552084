import { chunk } from 'lodash'

const addLeadZero = (num, place) => String(num).padStart(place, '0')

const onImageError = (e) => {
    e.target.src = '/images/Logo.png'
}

const onImageErrorScroll = (e, scrollHeight) => {
    e.target.src = '/images/Logo.png'
    window.scrollTo({ top: scrollHeight, bottom: 0, behavior: 'smooth' })
}

const numberWithCommas = (x) => {
    if (x) {
        try {
            if (x.toString().includes('.')) {
                var parts = x.toString().split('.')
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                return parts.join('.')
            } else {
                let num = parseFloat(x).toLocaleString('en-US', { maximumFractionDigits: 2 })
                return num + '.00'
            }
        } catch (err) {
            return x
        }
    } else {
        return x
    }
}

const setDecimalPoint = (prices, decimals) => {
    let price = prices ? parseFloat(prices) : 0
    let decimal = decimals ? parseFloat(decimals) : 0
    if (typeof price !== 'number' || typeof decimal !== 'number') {
        return false
    }
    const Price = parseFloat(price) || 0
    return numberWithCommas(Price.toFixed(decimal))
}

const getPaginationCount = (count, split) => {
    let array = Array(count)
        .fill(null)
        .map((u, i) => i)
    let ch = chunk(array, split)
    return ch.length
}

const getnearteImages = (array) => {
    let product = array.map((data) => {
        let images = []
        let ava = ''
        if (data.productImagesName.length !== 0) {
            ava = `${process.env.REACT_APP_PRODUCT_IMG}${process.env.REACT_APP_SITE_ID}/${data.auctionlot_title}/${data.productImagesName[0]}`
            images = data.productImagesName.map((img) => {
                let image = `${process.env.REACT_APP_PRODUCT_IMG}${process.env.REACT_APP_SITE_ID}/${data.auctionlot_title}/${img}`
                return { original: image, thumbnail: image }
            })
        } else {
            images = [{ original: '', thumbnail: '' }]
        }

        return { ...data, productImages: images, productAva: ava }
    })
    return product
}

export {
    addLeadZero,
    onImageError,
    setDecimalPoint,
    getPaginationCount,
    getnearteImages,
    onImageErrorScroll,
}
