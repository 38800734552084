import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../atoms/PrimaryButton'
import Timer from '../Timer'
import './ProductCard.css'
import { onImageError, setDecimalPoint } from '../../../Utils/Utility'
import { Link, useHistory } from 'react-router-dom'
import { currencyFormat } from '../../../common/components'
import { Chip } from '@material-ui/core'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import isAuthenticate from '../../../Utils/Utility/auth'
import { removeHTMLTags } from '../../../Utils/UI'

function Gridview(props) {
    const history = useHistory()

    const [bidText, setBidText] = useState('Bid Now')

    const getBidText = (data) => {
        if (
            isAuthenticate() &&
            (data.latestbid_user != null || data.highest_bidder_user_id != null) &&
            props?.user?.id != undefined
        ) {
            if (data.auction) {
                if (
                    (data.latestbid_user == props?.user?.id ||
                        data.highest_bidder_user_id == props?.user?.id) &&
                    data.wprice >= data.rprice
                ) {
                    if (data.market_status == 'open') {
                        setBidText('Winning')
                    } else if (data.market_status == 'sold') {
                        setBidText('Won')
                    }
                } else if (
                    data.latestbid_user != props?.user?.id &&
                    (data.currentUserBidAmount || data.bid_or_not || data.cbidnot)
                ) {
                    if (data.market_status == 'open') {
                        setBidText('Losing')
                    } else if (data.market_status == 'sold') {
                        setBidText('Lost')
                    }
                } else {
                    setBidText('Bid Now')
                }
            }
        } else {
            setBidText('Bid Now')
        }
    }

    useEffect(() => {
        if (props.l_data != undefined) {
            getBidText(props.l_data)
        }
    }, [props.l_data])

    return (
        <div className="gridViewCnt" key={props.id}>
            <div className="cards lotCardWrpr">
                {props.card_type === 'lotView' && (
                    <div className="favBox">
                        <span
                            name={props.prodid}
                            onClick={props.onFavClick}
                            className="material-icons Favicon"
                        >
                            {props.isFav === 1 ? 'favorite' : 'favorite_border'}
                        </span>
                    </div>
                )}
                {props.redirectUrl ? (
                    <Link to={props.redirectUrl}>
                        <LazyLoadImage
                            className="cardImage"
                            src={props.imgSrc}
                            onError={onImageError}
                            onClick={props.onClick ? props.onClick : null}
                            effect="blur"
                            placeholderSrc="/images/imageLoading.svg"
                            height="100%"
                            width="100%"
                            alt="Next Lot"
                        />
                        <span className={`badge grid-badge ${props.con_check ? 'live' : 'timed'}`}>
                            {props.con_check ? 'Live' : 'Timed'}
                        </span>
                    </Link>
                ) : (
                    <>
                        <LazyLoadImage
                            className="cardImage"
                            src={props.imgSrc}
                            onError={onImageError}
                            onClick={props.onClick ? props.onClick : null}
                            effect="blur"
                            placeholderSrc="/images/imageLoading.svg"
                            height="100%"
                            width="100%"
                            alt="Next Lot"
                        />
                        <span className={`badge grid-badge ${props.con_check ? 'live' : 'timed'}`}>
                            {props.con_check ? 'Live' : 'Timed'}
                        </span>
                    </>
                )}
                <div className="pdtContent">
                    {props.redirectUrl ? (
                        <Link to={props.redirectUrl}>
                            <p
                                className="pdtName mb-2"
                                onClick={props.onClick ? props.onClick : null}
                                dangerouslySetInnerHTML={{
                                    __html: props.Name,
                                }}
                            >
                                {/* {props.Name} */}
                            </p>
                        </Link>
                    ) : (
                        <p
                            className="pdtName mb-2"
                            onClick={props.onClick ? props.onClick : null}
                            dangerouslySetInnerHTML={{
                                __html: props.Name,
                            }}
                        >
                            {/* {props.Name} */}
                        </p>
                    )}
                    {props.lotId ? (
                        <Chip label={<p className="m-0">Lot Id: {props.lotId}</p>} />
                    ) : props.lotId != undefined ? (
                        <Chip label={<p className="m-0">Lot Id: - </p>} />
                    ) : (
                        ''
                    )}
                    {props.closedAuction ? (
                        <>
                            {/* <p className="currPrice pb-3">
                                Sold Price: <span> $ {setDecimalPoint(props.currentPrice, 2)}</span>
                            </p> */}
                            {props.is_fav ? (
                                <PrimaryButton
                                    cstmClass="w-100 mt-0"
                                    className="viewAuctionBtn"
                                    onClick={props.onClick ? props.onClick : null}
                                >
                                    {props.label}
                                </PrimaryButton>
                            ) : (
                                <PrimaryButton
                                    cstmClass="w-100 mt-0"
                                    className="viewAuctionBtn"
                                    onClick={() =>
                                        history.push(
                                            encodeURI(
                                                props.con_check
                                                    ? `/auction/${props.id}?page=1`
                                                    : `/auction/timed-auction/${props.id}?page=1`,
                                            ),
                                        )
                                    }
                                >
                                    {props.label}
                                </PrimaryButton>
                            )}
                        </>
                    ) : (
                        <>
                            {props.lowest || props.highest ? (
                                <div className="spacing my-3">
                                    <p>
                                        Est: {currencyFormat(props.lowest) || 100} -{' '}
                                        {currencyFormat(props.highest) || 1000}
                                    </p>
                                </div>
                            ) : (
                                ''
                            )}
                            <p className="pdtDescrp mt-4">{removeHTMLTags(props.descrp)}</p>
                            {new Date() < new Date(props.startTime) ? (
                                <Timer
                                    productTime={{ start: props.startTime, end: props.endTime }}
                                    timerBasic={props.timerBasic}
                                    timedauction={props.con_check ? false : true}
                                />
                            ) : (
                                <>
                                    {props.con_check == 1 ? (
                                        <p className="red-text mb-0 mt-2">Live Auction Started</p>
                                    ) : (
                                        <Timer
                                            productTime={{
                                                start: props.startTime,
                                                end: props.endTime,
                                            }}
                                            timerBasic={props.timerBasic}
                                            timedauction={props.con_check ? false : true}
                                        />
                                    )}
                                </>
                            )}
                            {props.lotBidding ? (
                                <>
                                    {props.market_status === 'unsold' ||
                                    props.market_status === 'closed' ? (
                                        <p className="currPrice">Lot Passed</p>
                                    ) : (
                                        <p className="currPrice">
                                            {props.market_status === 'sold'
                                                ? 'Sold Price'
                                                : 'Current Price'}{' '}
                                            <span> $ {setDecimalPoint(props.currentPrice, 2)}</span>
                                        </p>
                                    )}
                                    {/* {props.lotBidding} */}
                                </>
                            ) : props.is_fav ? (
                                <PrimaryButton
                                    cstmClass="w-100 mt-0"
                                    className="viewAuctionBtn"
                                    onClick={props.onClick ? props.onClick : null}
                                >
                                    {props.label}
                                </PrimaryButton>
                            ) : (
                                <PrimaryButton
                                    cstmClass="w-100 mt-0"
                                    className="viewAuctionBtn"
                                    onClick={() =>
                                        history.push(
                                            encodeURI(
                                                props.con_check
                                                    ? `/auction/${props.id}?page=1`
                                                    : `/auction/timed-auction/${props.id}?page=1`,
                                            ),
                                        )
                                    }
                                >
                                    {props.label}
                                </PrimaryButton>
                            )}
                            {props.highbid_user_id &&
                                props.userDetails &&
                                props.highbid_user_id == props.userDetails.userdetails.id && (
                                    <h5 className="winningLabel">
                                        <b>You're Winning!</b>
                                    </h5>
                                )}

                            {props.con_check == 0 ? (
                                <>
                                    {bidText == 'Winning' ? (
                                        <p className="wngSts grnTxt pt-2 pb-2">You're Winning!</p>
                                    ) : bidText == 'Won' ? (
                                        <p className="wngSts grnTxt pt-2 pb-2">You Won!</p>
                                    ) : bidText == 'Losing' ? (
                                        <p className="wngSts LsgTxt pt-2 pb-2">You're Losing!</p>
                                    ) : bidText == 'Lost' ? (
                                        <p className="wngSts LsgTxt pt-2 pb-2">You Lost</p>
                                    ) : (
                                        <p className="wngSts"></p>
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Gridview
