import React from 'react'
import PrimaryButton from '../../atoms/PrimaryButton'
import { onImageError } from '../../../Utils/Utility'
import moment from 'moment'

function BuynowList(props) {
    return (
        <div className="buyNowList">
            <div className="listViewCnt" key={props.id}>
                <div className="listCard d-flex">
                    <img src={props.imgSrc} onError={onImageError} />
                    <div className="listPdtCnt">
                        <p className="pdtCategory">{props.category}</p>
                        <p className="pdtListName">{props.Name}</p>
                        <p
                            className="listPdtDescrp"
                            dangerouslySetInnerHTML={{ __html: props.descrp }}
                        ></p>
                        <p className="pdtPrice">{props.Price}</p>
                    </div>
                    <div className="timeBtn d-flex flex-column justify-content-end">
                        <span className="material-icons ListFavicon">favorite_border</span>
                        <PrimaryButton className="viewAuctionBtn">{props.label}</PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuynowList
