import React from 'react'
import SecondaryButton from '../../components/atoms/SecondaryButton/index'
import { isEmpty, findIndex } from 'lodash'
import moment from 'moment'
import Pagination from '@material-ui/lab/Pagination'
import { getPaginationCount } from '../../Utils/Utility'
import './BuyNow.css'

const BuyNow = (props) => {
    return (
        <div>
            <div className="Buynow--Table">
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Auction Name</th>
                            <th scope="col">Title</th>
                            <th scope="col">Brought On</th>
                            <th scope="col">Price</th>
                            {/* <th scope="col">Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {!isEmpty(props.data.results) ? (
                            props.data.results.map((data, index) => (
                                <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>{data.auctionlot_title}</td>
                                    <td>{data.title}</td>
                                    <td>{moment(data.date_added).format('MM/DD/YYYY')}</td>
                                    <td>$ {data.buynowamount}</td>
                                    {/* <td>
                                        <SecondaryButton label="Invoice" />
                                    </td> */}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colspan="12" className="border-none">
                                    <div className="card card-body text-center mt-2">
                                        <b>No Auction Found</b>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="buynow-pgn">
                <Pagination
                    onChange={(e, value) => {
                        props.paramsupd(value)
                    }}
                    count={
                        props.data.total_pagecnt
                            ? getPaginationCount(props.data.total_pagecnt, 10)
                            : '1'
                    }
                />
            </div>
        </div>
    )
}

export default BuyNow
