const chatreducer = (state = { chat: [], buyerchat: [] }, action) => {
    switch (action.type) {
        case 'chat':
            return {
                ...state,
                chat: action.data,
            }
        case 'buyerchat':
            return {
                ...state,
                buyerchat: action.data,
            }
        default:
            return state
    }
}

export default chatreducer
