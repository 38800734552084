import React, { useState, useEffect } from 'react'
import './Timer.css'
import { connect } from 'react-redux'
import getTimeInterval from '../../../Utils/Utility/timer'

function Timer({ serverTime, productTime, timerBasic, timedauction }) {
    const [time, setTime] = useState(undefined)

    useEffect(() => {
        if (serverTime) {
            var timer_obj = getTimeInterval(productTime.start, productTime.end, serverTime)
            if (timedauction && timer_obj?.text?.includes('Live')) {
                timer_obj.text = timer_obj.text.replace('Live', 'Timed')
            }
            setTime(timer_obj)
        }
    }, [serverTime])

    return time ? (
        time.isCompleted === false && parseInt(time.seconds) >= 0 ? (
            time.text !== 'Live Auction Ends In:' || timedauction ? (
                timerBasic ? (
                    <>
                        <p className="mb-0 tmrTxtTop">{time.text}</p>
                        <div className="timerBasic d-flex justify-content-center">
                            <h4>{time.days == '-1' ? '00' : time.days} D : </h4>
                            <h4>{time.hours} H : </h4>
                            <h4>{time.minutes} M : </h4>
                            <h4>{time.seconds} S</h4>
                        </div>
                    </>
                ) : (
                    <div className="timeCnt">
                        <p className="timeTitle">{time.text}</p>
                        <div className="timerCnt d-flex justify-content-center">
                            <div className="Days">
                                <h4>{time.days}</h4>
                                <p>DAYS</p>
                            </div>
                            <p className="dot">:</p>
                            <div className="Hrs">
                                <h4>{time.hours}</h4>
                                <p>HRS</p>
                            </div>
                            <p className="dot">:</p>
                            <div className="Mins">
                                <h4>{time.minutes}</h4>
                                <p>MIN</p>
                            </div>
                            <p className="dot">:</p>
                            <div className="Sec">
                                <h4>{time.seconds}</h4>
                                <p>SEC</p>
                            </div>
                        </div>
                    </div>
                )
            ) : (
                <div className="emptyTimerDiv"></div>
            )
        ) : (
            <div className="timerCnt closedTime d-flex justify-content-center">
                <p className="closed">Auction Closed</p>
            </div>
        )
    ) : (
        <div className="timerCnt d-flex justify-content-center"></div>
    )
}

const mapStateToProps = (state) => {
    return {
        serverTime: state.serverTime ? state.serverTime.time : '',
    }
}

export default connect(mapStateToProps, null)(Timer)
