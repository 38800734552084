import React from 'react'
import './VerticalSlider.css'
import Slider from 'react-slick'
import { testimonial } from '../../../Utils/UI'

const VerticalSlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        centerMode: false,
        slidesToShow: 5,
        slidesToScroll: 3,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
        vertical: true,
        verticalSwiping: true,
        beforeChange: function (currentSlide, nextSlide) {
            console.log('before change', currentSlide, nextSlide)
        },
        afterChange: function (currentSlide) {
            console.log('after change', currentSlide)
        },
    }

    return (
        <div className="">
            <Slider {...settings}>
                {testimonial.map((data, index) => (
                    <>
                        {data.stars > 3 ? (
                            <div key={index}>
                                <div className="carCardWrpr d-flex align-items-start">
                                    <div className="imageContainer">
                                        <img
                                            className={`${data.img_url === '' && 'plcHldImg'}`}
                                            src={data.img_url ? data.img_url : '/images/img.png'}
                                        />
                                    </div>
                                    <div className="rvwCnt w-100">
                                        <h5>{data.short_msg}</h5>
                                        <p>{data.message}</p>
                                        <div className="rvwrDtl mt-3 d-flex align-items-center justify-content-between w-100">
                                            <div>
                                                <h6 className="d-flex align-items-center">
                                                    {data.userName}{' '}
                                                    <span className="material-icons verifiedIcon ml-2">
                                                        verified
                                                    </span>
                                                </h6>
                                                <small>{data.location}</small>
                                            </div>
                                            <div className="text-right">
                                                <div className="rtngCntnr d-flex align-items-center">
                                                    {[1, 2, 3, 4, 5].map((values, index) => (
                                                        <span
                                                            className={`material-icons ${
                                                                index + 1 <= data.stars
                                                                    ? 'active'
                                                                    : ''
                                                            }`}
                                                        >
                                                            star
                                                        </span>
                                                    ))}
                                                </div>
                                                <small>{data.date}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </>
                ))}
            </Slider>
        </div>
    )
}

export default VerticalSlider
