const auctionLotReducer = (
    state = {
        auctiondetails: {},
        allauctionlots: {},
        totalAuctionLots: {},
        auctionRegistered: {},
        auctionRegisteredStatus: {},
    },
    action,
) => {
    switch (action.type) {
        case 'AUCTIONLOTS':
            console.log('auction lot reducer', action, action.list, action.list.results)
            return {
                ...state,
                auctiondetails: action.list.auctionList,
                allauctionlots: action.list.results,
                totalAuctionLots: action.list.total_results,
                auctionRegistered: action.list.isRegistered,
                auctionRegisteredStatus: action.list.isRegistered_list,
            }
        default:
            return state
    }
}

export default auctionLotReducer
