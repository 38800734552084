import React, { useState, useEffect, useContext, useRef, useCallback } from 'react'
import './LotView.css'
import axios from '../../Utils/Utility/axiosconfig'
import Timer from '../../components/molecules/Timer'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Chip, LinearProgress, Button } from '@material-ui/core'
import Swal from 'sweetalert2'
import { capitalize, currencyFormat, handleRedirectInternal } from '../../common/components'
// import BiddingItem from '../../components/molecules/Bidding/BiddingItem'
import { useMediaQuery } from 'react-responsive'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import 'react-photoswipe/lib/photoswipe.css'
import AuctionContext from '../../context/auction/auctionContext'
import ProductContext from '../../context/product/productContext'
import AuthContext from '../../context/auth/authContext'
import AlertContext from '../../context/alert/alertContext'
import UserContext from '../../context/user/userContext'
import Tooltip from '@material-ui/core/Tooltip'
// import AddCreditCard from '../AddCreditCard'
import ImageGallery from 'react-image-gallery'
import { withStyles } from '@material-ui/styles'
import ReactImageMagnify from 'react-image-magnify'
import 'react-image-gallery/styles/css/image-gallery.css'
import Popup from '../../components/organisms/Popup'

import ReactPlayer from 'react-player/lazy'
import { socket, reInitializeSocket, socketSeller } from '../../common/socket'
import Slider from 'react-slick'
import Loaders from '../../components/molecules/Loader'
import { useDispatch } from 'react-redux'
import liveLotsReducer from '../../reducers/liveLotsReducer'
import auctionlotDetails from '../../actions/auctionLotsAction'
import auctionMsgDetails from '../../actions/auctionMessageAction'
import { connect } from 'react-redux'
import livelotDetails from '../../actions/liveLotsAction'
import BiddingLive from '../../components/molecules/BiddingLiveLot/BiddingLive'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import auctionPercentage from '../../actions/auctionPercentage'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import lotSearchDetails from '../../actions/lotSearchAction'
import PreBidComponent from '../../components/molecules/PreBidComponent'
import { findIndex } from 'lodash'
import BidHistory from '../../components/organisms/BidHistory'
import qs from 'qs'
import Bidding from '../../components/molecules/Bidding/BiddingItem'
import isAuthenticate from '../../Utils/Utility/auth'
import { messageHandlerSingle } from '../../common/sockethandler'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div className="tabBody">{children}</div>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}))

const styles = (theme) => ({
    root: {},
})

function LotView(props) {
    const dispatch = useDispatch()
    const history = useHistory()
    const [auction, setAuction] = useState({})
    const [user, setUser] = useState()
    const [lotDetails, setLotDetails] = useState({})
    const [product_Details, setProductDetails] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [messageBoxContent, setMessageBoxContent] = useState([])
    const [currentLotIndex, setCurrentLotIndex] = useState('')
    const [currentLotImages, setCurrentLotImages] = useState([])
    const [completionPercentage, setCompletionPercentage] = useState(0)
    const [messageBoxElement, setMessageBoxElement] = useState()
    const [savedCards, setSavedCards] = useState([])
    const [viewAddCredit, setViewAddCredit] = useState(false)
    const noImageAvailable = '/assets/img.png'
    const [closedLot, setClosedLot] = useState(null)
    const [cancelBid, setCancelBid] = useState('')
    const [showTimer, setTimer] = useState(true)
    const [liveCountValue, setLiveCountValue] = useState(0)
    const [selectedProductImage, setSelectedProductImage] = useState([])
    const sliderRef = useRef(null)
    const [messageTemp, setMessageTemp] = useState('')
    const [updateBid, setUpdateBid] = useState('')
    const [historyId, setHistoryId] = useState(undefined)
    const [scrollLocationStore, setScrollLocationStore] = useState('')
    const [perPage, setPerPage] = useState(20)
    const [pageStore, setPageStore] = useState(1)
    const [reload, setReload] = useState(false)
    const [auctiondata, setauctionData] = useState({})
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        // prevArrow: <SamplePrevArrow />,
        // nextArrow: <SampleNextArrow />,
    }

    const lotSearchDetails = async (body) => {
        try {
            body.searchbar_with_lot_number = ''
            let { data } = await axios.post('mobileapi/lotSearch', body)

            if (data.success) {
                if (data?.result?.listOfProjects?.length !== 0) {
                    setProductDetails(data.result.listOfProjects)
                    //setLotDetails({ ...lotDetails, lotDetails: data.result.listOfProjects[0] })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getcurrentproduct = async (body) => {
        try {
            let { data } = await axios.post('mobileapi/getprodetails', body)

            if (data.success == 'yes') {
                if (data.biddetails.bidtopwinner) {
                    if (
                        !data.biddetails.bidtopstatus.includes('!') &&
                        !data.biddetails.bidtopstatus.includes('unfortunately,')
                    ) {
                        data.results.rprice = data.results.rprice || 0
                        let reserveCheck = data.biddetails.bidtopstatus.split('. ')
                        if (reserveCheck.length > 1) {
                            var autobid = data.biddetails.bidtopstatus
                                .split('. ')[0]
                                .replace(/[^\d.]/g, '')
                            var autobid1 = data.biddetails.bidtopstatus
                                .split('. ')[1]
                                .replace(/[^\d.]/g, '')
                            if (parseInt(autobid) < parseInt(data.results.rprice)) {
                                if (parseInt(autobid) === parseInt(autobid1)) {
                                    data.biddetails.bidtopstatus = `Your bid of $${parseInt(
                                        autobid1,
                                    ).toLocaleString(
                                        'en-US',
                                    )} on this item placed, but reserve price not yet met.`
                                } else {
                                    data.biddetails.bidtopstatus = `Your bid of $${parseInt(
                                        autobid,
                                    ).toLocaleString('en-US')} and auto bid of $${parseInt(
                                        autobid1,
                                    ).toLocaleString(
                                        'en-US',
                                    )} on this item placed, but reserve price not yet met.`
                                }
                            } else {
                                if (parseInt(autobid) === parseInt(autobid1)) {
                                    data.biddetails.bidtopstatus = `You are winning this item at $${parseInt(
                                        autobid1,
                                    ).toLocaleString('en-US')}.`
                                } else {
                                    data.biddetails.bidtopstatus = `You are winning this item at $${parseInt(
                                        autobid,
                                    ).toLocaleString('en-US')}. Your autoBid of $${parseInt(
                                        autobid1,
                                    ).toLocaleString('en-US')} has been placed successfully.`
                                }
                            }
                        }
                    } else {
                        if (data.biddetails.bidtopstatus.includes('losing')) {
                            data.biddetails.bidtopstatus = `You've been outbid on this item. Current leading bid is $${parseInt(
                                data.results.latestbid,
                            ).toLocaleString('en-US')}`
                        } else if (data.biddetails.bidtopstatus.includes('unfortunately,')) {
                            data.biddetails.bidtopstatus = `unfortunately you lost this item`
                        } else if (data.biddetails.bidtopstatus.includes('!')) {
                            data.biddetails.bidtopstatus = `Congratulations, you have bought this item for $${parseInt(
                                data.results.latestbid,
                            ).toLocaleString('en-US')}`
                        }
                    }
                } else {
                    if (data.biddetails.bidtopstatus.includes('losing')) {
                        data.biddetails.bidtopstatus = `You've been outbid on this item. Current leading bid is $${parseInt(
                            data.results.latestbid,
                        ).toLocaleString('en-US')}`
                    }
                }
                setLotDetails({
                    ...data.results,
                    ...data.attachments,
                    ...data.biddetails,
                    ...{ incrementamt: data.incrementamt },
                })
                let lotimages = []
                if (data.attachments.length > 0) {
                    data.attachments.map((imgObj) =>
                        lotimages.push({
                            original: process.env.REACT_APP_PRODUCT_IMG + imgObj.src,
                            thumbnail: process.env.REACT_APP_PRODUCT_IMG + imgObj.src,
                        }),
                    )
                } else {
                    lotimages.push({
                        original: '/images/Logo.png',
                        thumbnail: '/images/Logo.png',
                    })
                }
                setCurrentLotImages(lotimages)
                setIsLoading(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    // useEffect(() => {
    //     if (product_Details.length > 0) {
    //         getcurrentproduct({ product_id: product_Details[0].id })
    //     }
    // }, [product_Details])

    const getAuctions = async (body) => {
        let { data } = await axios.post('mobileapi/getauctionlotdetails', body)
        if (data && data.success) {
            if (Object.keys(data.data.auction_details).length !== 0) {
                setauctionData(data.data.auction_details)
            }
        }
    }

    useEffect(() => {
        let auctionId = props.match.params.auctionId
        let u_id = Number(props.match.params.user_id)
            ? props.match.params.user_id
            : props?.userDetails?.userdetails?.id
            ? props.userDetails.userdetails.id
            : 0
        if (
            (auctionId && u_id && Number(props.match.params.user_id) == 0) ||
            (auctionId && !isAuthenticate())
        ) {
            lotSearchDetails({
                limit: 1000,
                sortBy: 2,
                page: 1,
                lotof: auctionId,
                order: 1,
                userid: u_id,
                auction_io: 1,
            })
            getAuctions({
                auctionid: auctionId,
                need_more_details: 1,
            })
        } else {
            console.log('error occured')
        }
    }, [props.match.params.auctionId, props.userDetails.userdetails])

    useEffect(() => {
        let auctionId = props.match.params.auctionId
        let u_id = Number(props.match.params.user_id)
            ? props.match.params.user_id
            : props?.userDetails?.userdetails?.id
            ? props.userDetails.userdetails.id
            : 0
        if (auctionId && Number(props.match.params.user_id) != 0) {
            lotSearchDetails({
                limit: 1000,
                sortBy: 2,
                page: 1,
                lotof: auctionId,
                order: 1,
                userid: u_id,
                auction_io: 1,
            })
            getAuctions({
                auctionid: auctionId,
                need_more_details: 1,
            })
        } else {
            console.log('error occured')
        }
    }, [props.match.params.auctionId])

    useEffect(() => {
        if (props.location.search) {
            let query = qs.parse(props.location.search, { ignoreQueryPrefix: true })
            if (query.scrollLocation) {
                setScrollLocationStore(query.scrollLocation)
            }
            if (query.page) {
                setPageStore(query.page)
            }
            if (query.perPage) {
                setPerPage(query.perPage)
            }
        }
    }, [props.location.search])

    const lotDetailsRef = useRef(lotDetails)

    // useEffect(() => {
    //     socketForward.on('bidAddtime', (data) => {
    //         console.log('bid add time socket emit functions', data)
    //         // dispatch(
    //         //     livelotDetails({
    //         //         lotId: props.match.params.lotId,
    //         //         user_id: Number(props.match.params.user_id),
    //         //     }),
    //         // )
    //         setUpdateBid(data)
    //     })
    // }, [])

    // useEffect(() => {
    //     console.log('state updated')
    //     updateData(updateBid)
    // }, [updateBid])

    const handleHistory = (val) => setHistoryId(val)

    const viewProductRef = useRef(lotDetails)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = lotDetails
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandlerSingle(message, viewProductRef.current, userRef.current, setLotDetails, type)
    }

    useEffect(() => {
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        socket.on('cancelbidemitted', (data) => {
            console.log(
                'lllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll',
                data,
            )
            handler(data, 'cancelbidemitted')
        })
        return () => {
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.off('cancelbidemitted', (data) => {
                handler(data, 'cancelbidemitted')
            })
        }
    }, [])

    // if (data.status !== 'failed') {
    //     if (parseInt(data.id) === parseInt(newLot.lotDetails.id)) {
    //         newLot.lotDetails.bidcnt = data.bpop_bidcount
    //         newLot.lotDetails.wprice = data.bpop_wprice
    //         newLot.current_bid = data.bpop_wprice
    //         if (data.bpop_higher == props.userDetails.userdetails.id) {
    //             newLot.next_bid = data.bpop_wprice_morehigh + data.bpop_increment
    //         } else {
    //             newLot.next_bid = data.bpop_nextbid_org
    //         }
    //     }
    //     setLotDetails(newLot)
    //     console.log('after bid place socket function lot data', newLot)
    // }

    // useEffect(() => {
    //     socket.on('liveauctionclose', (data) => {
    //         console.log(
    //             'Inside Auction Close in livelotpage socket forward',
    //             data,
    //             data.auctionid,
    //             props.match.params.auctionId,
    //         )
    //         if (parseInt(data.auctionid) === parseInt(props.match.params.auctionId)) {
    //             console.log('Inside This Auction Close')
    //             setTimer(false)
    //             Swal.fire({
    //                 icon: 'success',
    //                 title: 'This Auction Has Been Ended By Seller Now.',
    //                 showConfirmButton: true,
    //                 position: 'center',
    //             }).then(function (data) {
    //                 if (data.isConfirmed) {
    //                     return history.push('/search')
    //                 }
    //             })
    //         }
    //     })
    // }, [])

    // useEffect(() => {
    //     socketForward.on('liveauctionAwarding', (data) => {
    //         console.log('closed auction emit', data)
    //         console.log('allauctionlots new', props.auctionLots.allauctionlots)
    //         let lotArray = props.allLotDetails.allLotdetails.results
    //         let closedIndex = lotArray.findIndex((ele) => ele.id === parseInt(data.lotid))
    //         let auctionDetails = auction
    //         if (
    //             closedIndex != null &&
    //             closedIndex < props.allLotDetails.allLotdetails.results.length - 1
    //         ) {
    //             auctionDetails.currentProjectId =
    //                 props.allLotDetails.allLotdetails.results[closedIndex + 1].id
    //             setAuction(auctionDetails)
    //         }
    //         // setClosedLot(data)
    //     })
    // }, [])

    const handleLotChangeClick = (e, type) => {
        if (type === 'next') {
            if (currentLotIndex < product_Details.length - 1) {
                setCurrentLotIndex(currentLotIndex + 1)
            }
        }
        if (type === 'prev') {
            if (currentLotIndex > 0) {
                setCurrentLotIndex(currentLotIndex - 1)
            }
        }
    }

    const handleLotImageClick = (e, index) => {
        if (currentLotIndex !== index) {
            setCurrentLotIndex(index)
        }
    }

    useEffect(() => {
        if (product_Details?.length && currentLotIndex !== '' && currentLotIndex !== undefined) {
            getcurrentproduct({ product_id: product_Details[currentLotIndex].id })
        }
    }, [currentLotIndex])

    useEffect(() => {
        if (product_Details?.length) {
            console.log(
                'currentLotIndex----------------->props.match.params',
                props.match.params.lotId,
            )
            product_Details.map((val, index) => {
                if (parseInt(val.id) == parseInt(props.match.params.lotId)) {
                    setCurrentLotIndex(index)
                }
            })
        }
    }, [product_Details])

    const isMobile = useMediaQuery({
        query: '(max-width: 600px)',
    })
    const [isOpen, setOpen] = useState(false)
    const classes = useStyles()

    const productDetails = [
        {
            title: 'Description',
            description: lotDetails && lotDetails.description,
        },
        {
            title: 'Shipping',
            description: auctiondata.store_comment_two ? auctiondata.store_comment_two : '',
        },
        {
            title: 'Terms',
            description: auctiondata.store_comment_four ? auctiondata.store_comment_four : '',
        },
        {
            title: 'Payment',
            description:
                (auctiondata.store_comment_one ? auctiondata.store_comment_one : '') +
                (auctiondata.store_comment_five ? auctiondata.store_comment_five : ''),
        },
    ]

    const [value, setValue] = useState(0)
    const [expanded, setExpanded] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleExpand = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    // useEffect(() => {
    //     if (auction.id) {
    //         init()
    //     }
    // }, [auction])

    const config = {
        iceServers: [
            {
                urls: 'turn:100.20.224.224:3478',
                credential: 'Aucsoft@!',
                username: 'AsUser',
            },
        ],
    }

    let peerConnection
    const videoRef = useRef()

    useEffect(() => {
        setUser(props.userDetails.userdetails)
    }, [props.userDetails])

    const tooltipLotdtls = (lot) => {
        return (
            <div className="toolTopInfo">
                <LazyLoadImage
                    src={process.env.REACT_APP_PRODUCT_IMG + lot.avatar}
                    effect="blur"
                    placeholderSrc="/images/imageLoading.svg"
                    height="100%"
                    width="100%"
                    onClick={(e) => handleLotImageClick(e)}
                    onError={onImageError}
                    alt="Next Lot"
                />

                <p>Lot Name : {lot.title}</p>
                <p>Current Price : US ${lot.wprice}</p>
            </div>
        )
    }

    const RenderImageZoom = () => {
        return (
            <>
                <ReactImageMagnify
                    {...{
                        smallImage: {
                            alt: '',
                            isFluidWidth: true,
                            src: currentLotImages[sliderRef?.current?.getCurrentIndex()]?.original,
                            enlargedImageClassName: 'enlargedImage',
                        },
                        largeImage: {
                            src: currentLotImages[sliderRef?.current?.getCurrentIndex()]?.original,
                            width: 800,
                            height: 800,
                        },
                        className: 'hoverZoom',
                        enlargedImagePosition: 'over',
                        isHintEnabled: true,
                        //enlargedImageContainerClassName: 'customZoom',
                        enlargedImageStyle: { objectFit: 'cover' },
                    }}
                />
            </>
        )
    }

    async function init() {
        const payload = {
            auctionid: auction.id,
        }
    }

    const onImageError = (e) => (e.target.src = '/images/Logo.png')

    // const handleClick = async (body, nPrice, auction_type, lot_id) => {
    //     if (isAuthenticate()) {
    //         if (lotData.con_check === 1) {
    //             if (parseInt(lotData.is_registered) === 0) {
    //                 Swal.fire({
    //                     icon: 'error',
    //                     title: 'Please Register Live Auction And Participate.',
    //                     timer: 2500,
    //                     showConfirmButton: false,
    //                     position: 'center',
    //                 })
    //                 return window.scrollTo(0, 0)
    //             } else {
    //                 body.hard_bid = 0
    //                 body.auction_io = 1
    //             }
    //         }
    //         if (
    //             moment(props.serverTime).isBefore(moment(lotData.date_added)) &&
    //             auction_type !== 1
    //         ) {
    //             toast.warning('Auction is not started!')
    //             return false
    //         }

    //         if (nPrice > body.wsprice) {
    //             toast.warning(`Enter $${nPrice} or more`)
    //         } else {
    //             if (auction_type === 1) {
    //                 await preAuctionBid({
    //                     wsprice: parseInt(body.wsprice),
    //                     userid: props.userDetails.userdetails.id,
    //                     id: parseInt(lot_id),
    //                     lotid: parseInt(lot_id),
    //                     bidplan: 'auto',
    //                     auctionid: parseInt(id),
    //                 })
    //                 setAmount({ ...amount, [body.id]: '' })
    //             } else {
    //                 await commonBidding({
    //                     ...body,
    //                     email: props.userDetails.userdetails.email,
    //                     userid: props.userDetails.id,
    //                 })
    //                 setAmount({ ...amount, [body.id]: '' })
    //             }
    //         }
    //     } else {
    //         history.push({ pathname: '/login', state: props.location })
    //     }
    // }
    console.log('auction and lot data', auction, lotDetails)

    useEffect(() => {
        console.log('ppppppppppppppppppppppppppppppppppppppppppppppppppppppppp', lotDetails)
    }, [lotDetails])

    return (
        <>
            {!isLoading && Object.keys(lotDetails ? lotDetails : {}).length > 0 ? (
                <div className="productCntWrp pdtVwRp stdlotViewWrapper">
                    <ToastContainer
                        autoClose={5000}
                        hideProgressBar={true}
                        pauseOnFocusLoss={false}
                    />
                    <div className="row lotMain mb-2">
                        <div className="col-12 col-md-6 lotSection ">
                            <div className="topShftNav">
                                <a
                                    href={`/auction/timed-auction/${props.match.params.auctionId}?page=${pageStore}&scrollLocation=${scrollLocationStore}&perPage=${perPage}`}
                                    className="vwActnBtn d-flex align-items-center mr-auto"
                                >
                                    <span className="material-icons">chevron_left</span> View
                                    Auction
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="topShftNav d-flex align-items-center justify-content-between">
                                <div className="prevLotShft mr-2">
                                    {currentLotIndex === 0 ? (
                                        ''
                                    ) : (
                                        <p
                                            className="d-flex cursorDecoy align-items-center mb-0"
                                            onClick={(e) => handleLotChangeClick(e, 'prev')}
                                        >
                                            <span className="material-icons">chevron_left</span>{' '}
                                            Prev Lot
                                        </p>
                                    )}
                                </div>
                                <div class="pdtNmInfo text-left lv-lot-id">
                                    <small>Lot {lotDetails.sku}</small>
                                </div>
                                <div className="rtLotShft">
                                    {product_Details &&
                                    currentLotIndex === product_Details.length - 1 ? (
                                        ''
                                    ) : (
                                        <p
                                            className="d-flex cursorDecoy align-items-center mb-0"
                                            onClick={(e) => handleLotChangeClick(e, 'next')}
                                        >
                                            Next Lot{' '}
                                            <span className="material-icons">chevron_right</span>
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row lotMain">
                        <div className="col-md-4 col-12 lotSection">
                            <div className="d-flex">
                                <div className="currentLot">
                                    <div className="imageSliderLive image-sliding-new">
                                        {/* <ImageGallery
                        items={currentLotImages}
                        thumbnailPosition="bottom"
                        showNav={false}
                        lazyLoad={true}
                        showBullets={false}
                        showFullscreenButton={true}
                        showPlayButton={false}
                        onErrorImageURL="/images/Logo.png"
                    /> */}
                                        <ImageGallery
                                            items={currentLotImages}
                                            thumbnailPosition="bottom"
                                            showNav={true}
                                            showBullets={false}
                                            showFullscreenButton={false}
                                            showPlayButton={false}
                                            onErrorImageURL={'/images/Logo.png'}
                                            originalClass="slidImg img-fluid"
                                            renderCustomControls={RenderImageZoom}
                                            // renderItem={RenderImage}
                                            ref={sliderRef}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-12">
                            <div class="productTimer1 mb-2">
                                <div class="pdtNmInfo text-left">
                                    <p
                                        class="productTitle mb-0"
                                        dangerouslySetInnerHTML={{
                                            __html: lotDetails.title,
                                        }}
                                    ></p>
                                </div>
                            </div>
                            {isAuthenticate() && lotDetails?.bidtopstatus && (
                                <div className="productStatus p-0">
                                    <h4
                                        className={`p4 ${
                                            lotDetails.bidtopstatus.includes('bought') ||
                                            lotDetails.bidtopstatus.includes('won')
                                                ? 'won'
                                                : lotDetails.bidtopstatus.includes('lost')
                                                ? 'lost'
                                                : lotDetails.bidtopstatus.includes('losing')
                                                ? 'losing'
                                                : lotDetails.bidtopstatus.includes('outbid')
                                                ? 'outbid'
                                                : lotDetails.bidtopstatus.includes('reserve')
                                                ? 'reserve'
                                                : lotDetails.bidtopstatus.includes('winning')
                                                ? 'winning'
                                                : lotDetails.bidtopstatus.includes('proxy')
                                                ? 'proxy'
                                                : ''
                                        }`}
                                    >
                                        {lotDetails.bidtopstatus.replace(/autoBid/g, 'proxy bid')}
                                        {console.log(
                                            'pppppppppppppppppppppppppppppppppppppppppppppppppppppppppppp',
                                            lotDetails.bidtopstatus,
                                        )}
                                    </h4>
                                </div>
                            )}
                            <div className="d-flex align-items-center justify-content-between mb-2 estmtWrpr">
                                <p className="mb-0 estMtTxt">
                                    <b>Estimate:</b> {currencyFormat(lotDetails.lowest)} -
                                    {currencyFormat(lotDetails.highest)}
                                </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2 estmtWrpr">
                                {lotDetails.buyerpremium_percentage ? (
                                    <p className="mb-0 estMtTxt">
                                        <b>Buyer's Premium:</b> {lotDetails.buyerpremium_percentage}{' '}
                                        %
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>

                            <div className="d-flex align-items-center justify-content-between mb-2 mt-2 estmtWrpr">
                                {lotDetails.shippingcost ? (
                                    <p className="mb-0 estMtTxt">
                                        <b>Shipping Cost:</b>{' '}
                                        {currencyFormat(lotDetails.shippingcost)}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>

                            {/* {console.log('lot details', lotDetails, lotDetails.current_bid)} */}
                            <div className="bidCnt">
                                <div className="d-flex flex-wrap text-left justify-content-between">
                                    <div>
                                        <h2 className="text-left">
                                            {currencyFormat(lotDetails.wprice)}
                                        </h2>
                                        {auction.con_check === 1 ? (
                                            <p className="cursorDecoy">{lotDetails.bidcnt} Bids</p>
                                        ) : (
                                            <p
                                                className="cursorDecoy"
                                                onClick={(e) => handleHistory(lotDetails.id)}
                                            >
                                                {lotDetails.bids} Bids
                                            </p>
                                        )}
                                    </div>

                                    <div className="tmrDv">
                                        <Timer
                                            productTime={{
                                                start: lotDetails.date_added,
                                                end: lotDetails.date_closed,
                                            }}
                                            timerBasic={true}
                                            timedauction={true}
                                        />
                                    </div>
                                </div>

                                <Bidding
                                    next_bid={
                                        lotDetails?.next_bid ? parseInt(lotDetails.next_bid) : ''
                                    }
                                    user={user}
                                    id={lotDetails?.id ? lotDetails.id : ''}
                                    data={lotDetails ? lotDetails : {}}
                                />

                                {/* {props.allLotDetails.allLotdetails &&
                props.allLotDetails.allLotdetails.results &&
                props.allLotDetails.allLotdetails.results.length &&
                new Date() < new Date(lotDetails.date_closed) && (
                    <>
                        {new Date() <
                        new Date(lotDetails.date_added) ? (
                            <PreBidComponent
                                lotdetails={lotDetails}
                                type="proxy"
                                size="medium"
                                auctionId={auction.id}
                                user={user}
                                is_not_approved={auction.isRegistered}
                                cancel_bid={lotDetails.cancel_bid || null}
                            />
                        ) : (
                            auction.currentProjectId !=
                                lotDetails.id && (
                                <PreBidComponent
                                    lotdetails={lotDetails}
                                    type="proxy"
                                    size="medium"
                                    auctionId={auction.id}
                                    user={user}
                                    is_not_approved={auction.isRegistered}
                                    cancel_bid={lotDetails.cancel_bid || null}
                                />
                            )
                        )}
                    </>
                )} */}
                                {/* <div className="byprmWrpr">
                <p className="mb-0 mt-3 text-left">
                    Buyer's Premium: {auction.buyer_premium}%
                </p>
            </div> */}
                            </div>
                        </div>
                    </div>
                    {lotDetails?.videos?.length > 0 && lotDetails.videos?.split(',').length && (
                        <div className="row mt-5">
                            <div className="col-md-12 text-left">
                                <h4 className="mb-4">Videos</h4>
                            </div>
                            <div className="col-md-12 vdosWrapper">
                                {lotDetails.videos?.split(',').map((url) => (
                                    <div className="vdopPlayer">
                                        <ReactPlayer url={url} controls={true} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <div className="row mt-5">
                        <div className="col-12 col-md-12 descFldDynTxt">
                            <div className={`${isMobile && 'mobileView'} productDetails`}>
                                {isMobile ? (
                                    <div className="pvAccordian mt-2 mb-2 w-100">
                                        {productDetails.map((data, index) => (
                                            <Accordion
                                                expanded={expanded === `panel${index}`}
                                                onChange={handleExpand(`panel${index}`)}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={`panel${index}a-content`}
                                                    id={`panel${index}a-header`}
                                                >
                                                    <Typography className={classes.heading}>
                                                        {data.title}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        {data.title === 'Description' ? (
                                                            data.description
                                                        ) : (
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        data.description &&
                                                                        data.description.trim(),
                                                                }}
                                                            />
                                                        )}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </div>
                                ) : (
                                    <>
                                        <AppBar position="static">
                                            <Tabs
                                                value={value}
                                                onChange={handleChange}
                                                aria-label="simple tabs example"
                                            >
                                                {productDetails.map((data, index) => (
                                                    <Tab
                                                        label={data.title}
                                                        {...a11yProps({ index })}
                                                    />
                                                ))}
                                            </Tabs>
                                        </AppBar>
                                        {productDetails.map((data, index) => (
                                            <TabPanel value={value} index={index}>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: data.description,
                                                    }}
                                                ></div>
                                            </TabPanel>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
    <div className="col-12 lotSection upcomming-sliebar">
        <div className="upcomingLots">
            <h5 className="upcmgLtsTtle text-left mb-4 mt-5">OTHERS LOTS</h5>
            <Slider {...settings}>
                {props.allLotDetails.allLotdetails.results &&
                    props.allLotDetails.allLotdetails.results.length > 0 &&
                    props.allLotDetails.allLotdetails.results.map(
                        (lot, index) => (
                            <Button
                                className={`ucImg  ${
                                    currentLotIndex === index
                                        ? 'active-slide'
                                        : ''
                                }`}
                                key={index}
                            >
                                <LazyLoadImage
                                    src={
                                        process.env.REACT_APP_PRODUCT_IMG +
                                        lot.avatar
                                    }
                                    effect="blur"
                                    placeholderSrc="/images/imageLoading.svg"
                                    height="100%"
                                    width="100%"
                                    onClick={(e) =>
                                        handleLotImageClick(e, index)
                                    }
                                    onError={onImageError}
                                    alt="Next Lot"
                                />
                            </Button>
                        ),
                    )}
            </Slider>
        </div>
    </div>
</div> */}
                </div>
            ) : (
                <div className="mnHtcontnr">
                    <div className="liveLotsLoader">
                        <Loaders name="live_auction" isLoading={isLoading} />
                    </div>
                </div>
            )}
            <BidHistory
                open={historyId !== undefined}
                productId={historyId}
                onClose={handleHistory}
                auctionType={auction.con_check === 1 ? 'liveAuction' : 'TimedAuction'}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.User ? state.User : {},
    }
}

export default connect(mapStateToProps, null)(LotView)
