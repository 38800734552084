import axios from 'axios'
import isAuthenticate from '../Utility/auth'

// const payAmountStripe = async (body) => {
//     const { data } = await axios.post('auctionpay/payAmountStripe', body)
//     try {
//         if (data.success) {
//             return data
//         } else {
//             return 'Something went wrong'
//         }
//     } catch (err) {
//         console.log('payAmountStripe====>', err)
//         return 'Something went wrong'
//     }
// }

const payAmountStripe = async (body) => {
    const token = localStorage.getItem('userToken')
    let userData = JSON.parse(localStorage.getItem('userData'))
    const cust_id = isAuthenticate() && userData?.card_paymentid ? userData.card_paymentid : ''
    if (body.payDirect === 1) {
        const data_res = await axios.post(
            `${process.env.REACT_APP_PAYMENT_API_URL}/api/charge/create`,
            body,
        )
        try {
            if (data_res.data.status == 'success') {
                return data_res
            } else {
                return 'Something went wrong'
            }
        } catch (err) {
            console.log('payAmountStripe====>', err)
            return 'Something went wrong'
        }
    } else {
        body.card_name = body.card_name
            ? body.card_name
            : userData?.first_name
            ? userData.first_name
            : ''
        body.card_address_city = body.card_address_city
            ? body.card_address_city
            : userData?.city
            ? userData.city
            : ''
        body.card_address_country = body.card_address_country
            ? body.card_address_country
            : userData?.country
            ? userData.country
            : ''
        body.card_address_state = body.card_address_state
            ? body.card_address_state
            : userData?.state
            ? userData.state
            : ''
        body.card_address_line1 = body.card_address_line1
            ? body.card_address_line1
            : userData?.address1
            ? userData.address1
            : ''
        body.card_address_line2 = body.card_address_line2
            ? body.card_address_line2
            : userData?.address2
            ? userData.address2
            : ''
        body.card_address_zip = body.card_address_zip
            ? body.card_address_zip
            : userData?.zip
            ? userData.zip
            : ''
        body.amount = ''
        body.card_token = true
        // if (userData?.state == null) {
        //     const formdata = new FormData()
        //     formdata.append('first_name', userData.first_name)
        //     formdata.append('last_name', userData.last_name)
        //     formdata.append('email', userData.email)
        //     formdata.append('country', body.card_address_country)
        //     formdata.append('state', body.card_address_state)
        //     formdata.append('city', body.card_address_city)
        //     formdata.append('phone', userData.phone)
        //     //formdata.append('user_profile_image', File.File)
        //     formdata.append('card_paymentid', userData.card_paymentid)
        //     formdata.append('zip', body.card_address_zip)
        //     formdata.append('address1', body.card_address_line1)
        //     formdata.append('address2', body.card_address_line2)
        //     const user_update = await axios.post(
        //         `${process.env.REACT_APP_DOMAIN}mobileapi/updateUserProfile`,
        //         formdata,
        //         {
        //             headers: {
        //                 Authorization: 'Bearer ' + token,
        //                 domain: 'dalshire-test.ecommerce.auction',
        //             },
        //         },
        //     )
        //     userData.state = body.card_address_state
        //     userData.city = body.card_address_city
        //     userData.country = body.card_address_country
        //     userData.address1 = body.card_address_line1
        //     userData.address2 = body.card_address_line2
        //     userData.zip = body.card_address_zip
        //     localStorage.setItem('userData', JSON.stringify(userData))
        // }
        if (cust_id != '' && cust_id != undefined) {
            body.customer_id = cust_id
            const data_res = await axios.post(
                `${process.env.REACT_APP_PAYMENT_API_URL}/api/customer/source/create`,
                body,
            )
            try {
                if (data_res.data.status == 'success') {
                    return data_res
                } else {
                    return { data: { status: 'failed', message: data_res.data.data.message } }
                    // return 'Something went wrong'
                }
            } catch (err) {
                console.log('payAmountStripe====>', err)
                return 'Something went wrong'
            }
        } else {
            var body_data = {
                account_id: process.env.REACT_APP_AUCTIONPAYKEY,
                name: body.card_name,
                email: body.email,
                description: 'Test Description',
            }
            const { data } = await axios.post(
                `${process.env.REACT_APP_PAYMENT_API_URL}/api/customer/create`,
                body_data,
            )
            try {
                if (data.status == 'success') {
                    body.customer_id = data.data.responseData.id

                    let userData = JSON.parse(localStorage.getItem('userData'))
                        ? JSON.parse(localStorage.getItem('userData'))
                        : { card_paymentid: '' }
                    userData.card_paymentid = data.data.responseData.id
                    localStorage.setItem('userData', JSON.stringify(userData))
                    if (token) {
                        const url = `${process.env.REACT_APP_DOMAIN}auctionpay/save_customer_id`
                        const { data_res } = await axios.post(
                            url,
                            { customer_id: body.customer_id },
                            {
                                headers: {
                                    Authorization: 'Bearer ' + token,
                                    domain: 'dalshire-test.ecommerce.auction',
                                },
                            },
                        )
                    }
                    const data_res1 = await axios.post(
                        `${process.env.REACT_APP_PAYMENT_API_URL}/api/customer/source/create`,
                        body,
                    )
                    if (data_res1.data.status == 'success') {
                        return data_res1
                    } else {
                        return { data: { status: 'failed', message: data_res1.data.data.message } }
                        // return 'Something went wrong'
                    }
                } else {
                    return 'Something went wrong'
                }
            } catch (err) {
                console.log('payAmountStripe====>', err)
                return 'Something went wrong'
            }
        }
    }
}

export default payAmountStripe
