import React from 'react'
import PrimaryButton from '../../atoms/PrimaryButton'
import { onImageError } from '../../../Utils/Utility'
import './Buynow.css'

function BuynowGrid(props) {
    return (
        <div className="gridViewCnt">
            <div className="cards">
                <img src={props.imgSrc} onError={onImageError} />
                <div className="buynowFav">
                    <span className="material-icons Favicon">favorite_border</span>
                </div>
                <div className="pdtContent">
                    <p className="pdtName">{props.Name}</p>
                    <p className="pdtPrice">{props.Price}</p>
                    <p className="pdtDescrp" dangerouslySetInnerHTML={{ __html: props.descrp }}></p>
                    <PrimaryButton cstmClass="w-100 mt-0" className="viewAuctionBtn">
                        {props.label}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
}

export default BuynowGrid
