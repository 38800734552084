const livePriceReducer = (state = { livePrice: {} }, action) => {
    switch (action.type) {
        case 'LIVEPRICE':
            return {
                ...state,
                livePrice: action.list,
            }
        default:
            return state
    }
}

export default livePriceReducer
