import React, { useState, useEffect } from 'react'
import Addnewcard from './Addnewcard'
import PaymentCards from './paymentCards'
import axios from '../../Utils/Utility/axiosconfig'
import axiosmethod from 'axios'
import _, { isEmpty } from 'lodash'
import Swal from 'sweetalert2'
import './cards.css'

const Cards = (props) => {
    const [AllCard, setAllCard] = useState([])
    const [selected, setSelected] = useState([])

    const allsavedCards = async () => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const cardID = userData.card_paymentid
        console.log('process.env ------', process.env.REACT_APP_DOMAIN)
        let body = {
            account_id: process.env.REACT_APP_AUCTIONPAYKEY,
            customer_id: cardID,
        }
        const { data } = await axiosmethod.post(
            `${process.env.REACT_APP_PAYMENT_API_URL}/api/customer/source/list`,
            body,
        )
        try {
            if (data.status === 'success') {
                console.log('data.responseData.data ', data.data.responseData.data)
                data.data.responseData.data &&
                    setAllCard(data.data.responseData.data.filter((data) => data.object == 'card'))
                // data.selectedCard && setSelected(data.selectedCard)
            } else {
                setAllCard([])
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        allsavedCards()
    }, [])

    const updateCards = () => {
        allsavedCards()
    }

    const makePrimary = async (data) => {
        const res = await axios.post(`mobileapi/updateCard`, data)
        try {
            if (res.data.success === true) {
                updateCards()
                Swal.fire({
                    title: 'Card selected successfully',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            }
        } catch (err) {
            console.log(err)
        }
    }

    const deleteCard = async (id) => {
        // const res = await axios.post(`auctionpay/deleteStripeCard`, {
        //     cardId: id,
        //     mode: process.env.REACT_APP_PAYMENTMODE,
        // })
        let card_paymentid = JSON.parse(localStorage.getItem('userData')).card_paymentid
        const res = await axiosmethod.post(
            `${process.env.REACT_APP_PAYMENT_API_URL}/api/customer/source/delete`,
            {
                account_id: process.env.REACT_APP_AUCTIONPAYKEY,
                customer_id: card_paymentid,
                source_id: id,
                mode: process.env.REACT_APP_PAYMENTMODE,
            },
        )
        try {
            if (res.data.status == 'success') {
                updateCards()
                Swal.fire({
                    title: 'Card Deleted Successfully',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div>
            <div className="cardContainer">
                <div className="card-1-Container">
                    <Addnewcard upd={updateCards} />
                </div>
                {!isEmpty(AllCard) &&
                    AllCard.map((data) => (
                        <div className="card-2-Container">
                            <PaymentCards
                                value={data}
                                selectedcard={selected}
                                pr={(data) => {
                                    makePrimary(data)
                                }}
                                del={(id) => {
                                    deleteCard(id)
                                }}
                            />
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default Cards
