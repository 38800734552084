import React, { useState, useEffect } from 'react'
import Addnewcard from './Addnewcard'
import PaymentCards from './paymentCards'
import axios from '../../Utils/Utility/axiosconfig'
// import axiosmethod from 'axios'
import _, { isEmpty } from 'lodash'
import Swal from 'sweetalert2'
import './cards.css'
import FormControlLabel from '@material-ui/core/FormControlLabel'
const Cards = (props) => {
    const [AllCard, setAllCard] = useState([])
    const [selected, setSelected] = useState([])
    const [pay_index, setPayindex] = useState(0)
    const [bankverifypopup, setBankVerifyPopup] = useState(false)
    const allsavedCards = async () => {
        const user = JSON.parse(localStorage.getItem('userData'))
        let body = {
            site_id: process.env.REACT_APP_SITE_ID,
            email: user.email ? user.email : '',
            user_id: user.id ? user.id : '',
        }
        const { data } = await axios.post(`plugin/ach/stripe/bank/getBankDetails`, body)
        try {
            if (data.status === 'success') {
                console.log('data.responseData.data ', data.data.message.data.responseData.data)
                data.data.message.data.responseData.data &&
                    setAllCard(data.data.message.data.responseData.data)
                // data.selectedCard && setSelected(data.selectedCard)
            } else {
                setAllCard([])
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        allsavedCards()
    }, [])

    const updateCards = () => {
        allsavedCards()
    }

    const makeVerify = async (index) => {
        setPayindex(index)
        setBankVerifyPopup(true)
    }

    const deleteCard = async (id) => {
        // const res = await axios.post(`auctionpay/deleteStripeCard`, {
        //     cardId: id,
        //     mode: process.env.REACT_APP_PAYMENTMODE,
        // })
        let card_paymentid = JSON.parse(localStorage.getItem('userData')).card_paymentid
        const res = await axios.post(
            `${process.env.REACT_APP_PAYMENT_API_URL}/api/customer/source/delete`,
            {
                account_id: process.env.REACT_APP_AUCTIONPAYKEY,
                customer_id: card_paymentid,
                source_id: id,
                mode: process.env.REACT_APP_PAYMENTMODE,
            },
        )
        try {
            if (res.data.status == 'success') {
                updateCards()
                Swal.fire({
                    title: 'Card Deleted Successfully',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            }
        } catch (err) {
            console.log(err)
        }
    }

    const update_card_new = () => {
        AllCard[parseInt(pay_index)].status = 'verified'
        setAllCard([...AllCard])
    }

    return (
        <div>
            <div className={`cardContainer ${props.from == 'checkout' ? 'adBankWrpr' : ''}`}>
                {props.from == 'checkout' ? (
                    <>
                        <div className="card-1-Container bankPaymentWrpr">
                            <Addnewcard
                                upd={updateCards}
                                bankverifypopup={bankverifypopup}
                                setBankVerifyPopup={setBankVerifyPopup}
                                pay_index={pay_index}
                                setAllCard={setAllCard}
                                AllCard={AllCard}
                                update_card={update_card_new}
                            />
                        </div>
                        {!isEmpty(AllCard) &&
                            AllCard.map((data, index) => (
                                <div className="card-2-Container bankDetailsContainer">
                                    <PaymentCards
                                        data={data}
                                        // selectedcard={selected}
                                        //setAllCard={setAllCard}
                                        del={() => {
                                            makeVerify(index)
                                        }}
                                        cc={(id) => props.cc_id(id)}
                                        value={props.selectedbank}
                                        from="checkout"
                                        // del={(id) => {
                                        //     deleteCard(id)
                                        // }}
                                    />
                                </div>
                            ))}
                    </>
                ) : (
                    <>
                        <div className="card-1-Container">
                            <Addnewcard
                                upd={updateCards}
                                bankverifypopup={bankverifypopup}
                                setBankVerifyPopup={setBankVerifyPopup}
                                pay_index={pay_index}
                                setAllCard={setAllCard}
                                AllCard={AllCard}
                                update_card={update_card_new}
                            />
                        </div>
                        {!isEmpty(AllCard) &&
                            AllCard.map((data, index) => (
                                <div className="card-2-Container">
                                    <PaymentCards
                                        data={data}
                                        // selectedcard={selected}
                                        //setAllCard={setAllCard}
                                        del={() => {
                                            makeVerify(index)
                                        }}
                                        // del={(id) => {
                                        //     deleteCard(id)
                                        // }}
                                    />
                                </div>
                            ))}
                    </>
                )}
            </div>
        </div>
    )
}

export default Cards
