import React, { useState } from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import { ListItem } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import Swal from 'sweetalert2'
import payAmountStripe from '../../../Utils/API/payamountstripe'
import { useHistory } from 'react-router-dom'
import './RadioBox.css'

export const GreenRadio = withStyles({
    root: {
        color: 'var(--primColor)',
        '&$checked': {
            color: 'var(--primColor)',
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />)

function RadioBox(props) {
    const history = useHistory()
    const card = async () => {}
    console.log('Radio box props.value', props)
    return (
        <div className={`RadioBox ${props.checkout && 'cardContainerRadio'}`}>
            <RadioGroup
                aria-label={props.name}
                name={props.name}
                // value={props.int === 1 ? parseInt(props.id) : props.id}
                value={props.value}
                //onChange={props.onchange}
            >
                {console.log('propsss=============>', props.value)}
                {props.items.map((d, i) => (
                    <div className={`${props.checkout && 'cardInnrRadio'}`}>
                        <FormControlLabel
                            onClick={() => {
                                const data = {
                                    customer: d.customer,
                                    amount: parseInt(props.price) * 100,
                                    source: d.id,
                                }
                                console.log(data)
                                props.cc(data)
                            }}
                            checked={props.value == d.id ? true : false}
                            value={d.id}
                            control={<GreenRadio />}
                            label={
                                props.checkout ? (
                                    <>
                                        <div className="w-100">
                                            <h6>
                                                {'*********'} {d.last4}
                                            </h6>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <p className="m-0">{d.name}</p>
                                                <p className="m-0">
                                                    {d.exp_month}/{d.exp_year}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    d.description
                                )
                            }
                        />
                        {console.log('checkValue=====>', props.value)}
                    </div>
                ))}
            </RadioGroup>
        </div>
    )
}

export default RadioBox
