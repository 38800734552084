import React, { useState, useEffect } from 'react'
import CheckoutCard from '../../components/molecules/CheckoutCard'
import CheckoutPayment from '../../components/molecules/CheckoutPayment'
import { connect, useSelector } from 'react-redux'
import { Userdetails } from '../../actions/useraction'
import './Checkout.css'
import _, { set } from 'lodash'
import axios from 'axios'
import Loader from '../../components/molecules/Loader'
function Checkout(props) {
    // console.log('state data ===========', props.location.state)
    const [viewCartItems, setViewCartItems] = useState([])
    const [ship_local, setShipLocal] = useState([])
    const [discount_details, setDiscount] = useState([])
    const [discount_amount, setDiscountamount] = useState()
    const [loading, setLoading] = useState(false)
    const [achtransaction, setAchtransaction] = useState(0)
    const [transactionfeeenable, setTransactionfeeEnable] = useState(0)
    const state_header = useSelector((state) => state.storeDetails)
    const id = new URLSearchParams(window.location.search.substring(1)).getAll('id')
    const getCheckoutAuction = async (body) => {
        const token = localStorage.getItem('userToken')
        const res = await axios.post(
            `${process.env.REACT_APP_DOMAIN}mobileapi/getCheckoutAuction`,
            body,
            {
                headers: {
                    Authorization: 'Bearer ' + token,
                    domain: process.env.REACT_CONTROL_HUB_DOMAIN,
                },
            },
        )
        if (res.data.success) {
            setLoading(false)
            var discount_det = [],
                discount_amt = 0,
                inv_details = []
            res.data.details.map((item) => {
                if (parseInt(item.hasshipping) == 0) {
                    item.total_payable_amount =
                        parseFloat(item.total_payable_amount) - parseFloat(item.shipping_fee)
                    item.shipping_fee = 0
                }
                ship_local.push({ id: item.buynow_id, hasshipping: item.hasshipping })
                if (
                    parseFloat(item.invoice_discount) > 0 &&
                    !inv_details.includes(item.common_invoice)
                ) {
                    discount_det.push({
                        project_id: item.product_id,
                        amount: item.invoice_discount,
                    })
                    inv_details.push(item.common_invoice)

                    discount_amt = parseFloat(discount_amt) + parseFloat(item.invoice_discount)
                }
            })
            setDiscount(discount_det)
            setDiscountamount(discount_amt)
            setViewCartItems(res.data.details)
            setShipLocal(ship_local)
        }
    }

    useEffect(() => {
        getCheckoutAuction({ invoice_id: id })
        setLoading(true)
    }, [])

    useEffect(() => {
        if (state_header?.storeDetails?.store_configuration?.length > 0) {
            state_header.storeDetails.store_configuration.map((data) => {
                if (data.variable == 'ach_transaction_fee') {
                    setAchtransaction(parseFloat(data.value))
                }
                if (data.variable == 'transaction_fee_changes') {
                    setTransactionfeeEnable(parseInt(data.value))
                }
            })
        }
    }, [state_header])

    const changetrasaction_fee_reload = () => {
        var viewCartItems_data = viewCartItems
        viewCartItems_data.map((data) => {
            data.total_payable_amount =
                parseFloat(data.total_payable_amount) - parseFloat(data.transaction_fee)
            data.transaction_fee = parseFloat(
                parseFloat(
                    parseFloat(
                        (parseFloat(
                            transactionfeeenable ? data.total_payable_amount : data.amount,
                        ) *
                            parseFloat(achtransaction)) /
                            100,
                    ),
                ).toFixed(2),
            )
            data.transaction_label = `${achtransaction + '%'}`
            data.total_payable_amount =
                parseFloat(data.total_payable_amount) + parseFloat(data.transaction_fee)
        })
        setViewCartItems([...viewCartItems_data])
    }
    const changetrasaction_fee_reload_card = () => {
        //var viewCartItems_data = viewCartItems
        //viewCartItems_data.map((data) => {
        // data.transaction_fee = parseFloat(
        //     parseFloat(parseFloat((parseFloat(data.amount) * 3.1) / 100) + 0.3).toFixed(2),
        // )
        //data.transaction_fee = data.transaction_fee
        //data.transaction_label = '3.1% + 30 Cents'
        //})
        //console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn', viewCartItems_data)
        //setViewCartItems([...viewCartItems_data])
        getCheckoutAuction({ invoice_id: id })
    }
    return (
        <div className="checkoutContainer d-flex justify-content-between customContainer">
            {loading && <Loader />}
            {viewCartItems.length !== 0 ? (
                <>
                    <div className="checkoutPdt">
                        <p className="checkoutTitle">Checkout</p>
                        {viewCartItems.map((data, index) => (
                            <CheckoutCard discount_amount={discount_amount} data={data} />
                        ))}
                    </div>
                    <div className="paymentContainer ml-3">
                        <p className="paymentTitle">Payment Details</p>

                        <CheckoutPayment
                            // totalPrice={data.pdtPrice}
                            // name={data.pdtName}
                            // inv={data.inv}
                            // additional_amount_1={data.additional_amount_1}
                            // additional_amount_2={data.additional_amount_2}
                            // additional_amount_3={data.additional_amount_3}
                            // profile={props.userDetails.userdetails}
                            // conFee={data.Fee}
                            // taxPrice={data.Tax}
                            // grandTotal={data.Total}
                            amount={_.sumBy(viewCartItems, (x) => parseFloat(x.amount))}
                            invoice_id={id}
                            buyers_premium={_.sumBy(viewCartItems, (x) =>
                                parseFloat(x.buyers_premium + x.charge_fee),
                            )}
                            transaction_fee={_.sumBy(viewCartItems, (x) =>
                                parseFloat(x.transaction_fee),
                            )}
                            ship_local={ship_local}
                            shipping_fee={_.sumBy(viewCartItems, (x) => parseFloat(x.shipping_fee))}
                            sales_tax={_.sumBy(viewCartItems, (x) => parseFloat(x.sales_tax))}
                            total_payable_amount={_.sumBy(viewCartItems, (x) =>
                                parseFloat(x.total_payable_amount),
                            )}
                            changetrasaction_fee={changetrasaction_fee_reload}
                            changetrasaction_fee_card={changetrasaction_fee_reload_card}
                            trasaction_per_fee={achtransaction}
                            discount_details={discount_details}
                            discount_amount={discount_amount}
                            disble_checkout={
                                viewCartItems.length > 0 ? viewCartItems[0].buynow_check : 0
                            }
                        />
                    </div>
                </>
            ) : (
                <p className="text-center w-100">No Data Found</p>
            )}
        </div>
    )
}
const mapDispatchToProps = (dispatch) => ({
    User: () => dispatch(Userdetails()),
})

const mapStateToProps = (state) => {
    return {
        userDetails: state.User,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
