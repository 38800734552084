import React from 'react'
import './CheckoutCard.css'
import { currencyFormat } from '../../../common/components'
function CheckoutCard(props) {
    return (
        <div className="cardHolder">
            <div className="checkoutCardCnt d-flex">
                <img
                    src={`${process.env.REACT_APP_PRODUCT_IMG + props.data.avatar}`}
                    onError={(e) => (e.target.src = '/images/Logo.png')}
                />
                <div className="checkoutCntWrp">
                    <div className="checkoutTitle">
                        <p className="checkoutPdtTitle">Title</p>
                        <p
                            className="checkoutPdtName"
                            dangerouslySetInnerHTML={{
                                __html: props.data.title,
                            }}
                        ></p>
                    </div>
                    <div className="checkoutPrice">
                        <p className="priceTitle">Amount</p>
                        <p className="checkoutPdtPrice">
                            {currencyFormat(parseFloat(props.data.amount))}
                        </p>
                    </div>
                    <div className="checkoutPrice">
                        <p className="priceTitle">{`Buyer's Premium (${
                            props.data.buyers_premium_percentage + props.data.charge_percentage
                        } %)`}</p>
                        <p className="checkoutPdtPrice">
                            {' '}
                            {currencyFormat(
                                parseFloat(props.data.buyers_premium + props.data.charge_fee),
                            )}
                        </p>
                    </div>
                    <div className="checkoutPrice">
                        <p className="priceTitle">{`Sales Tax (${props.data.sales_tax_percentage} %)`}</p>
                        <p className="checkoutPdtPrice">
                            {' '}
                            {currencyFormat(parseFloat(props.data.sales_tax))}
                        </p>
                    </div>
                    <div className="checkoutPrice">
                        <p className="priceTitle">{`Transaction Fee (${props.data.transaction_label})`}</p>
                        <p className="checkoutPdtPrice">
                            {' '}
                            {currencyFormat(parseFloat(props.data.transaction_fee))}
                        </p>
                    </div>
                    <div className="checkoutPrice">
                        <p className="priceTitle">{`Shipping Fee ${
                            props.data.shipping_type == 1 && props.data.hasshipping == 1
                                ? `(${props.data.shipping_percentage} %)`
                                : ''
                        }`}</p>
                        <p className="checkoutPdtPrice">
                            {' '}
                            {currencyFormat(
                                parseFloat(props.data.hasshipping ? props.data.shipping_fee : 0),
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckoutCard
