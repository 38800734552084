import React, { useState, useEffect } from 'react'
import auctionSearchDetails from '../../actions/auctionSearchAction'
import { useDispatch, connect } from 'react-redux'
import GridView from '../../components/molecules/ProductCard/GridView'
import './PastAuction.css'
import Pagination from '@material-ui/lab/Pagination'
import { getPaginationCount, onImageError, setDecimalPoint } from '../../Utils/Utility'
import { useHistory } from 'react-router-dom'
import Loader from '../../components/molecules/Loader'
import qs from 'qs'

const PastAuctions = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [pastAuctions, setPastAuctions] = useState([])
    const [totalCount, setTotalCount] = useState('')
    const [loading, setLoading] = useState(false)

    let query = qs.parse(props.location.search, { ignoreQueryPrefix: true })

    useEffect(() => {
        dispatch(
            auctionSearchDetails({
                custom_field_3: [],
                // auc_type: '',
                is_auction: 1,
                // auc_type: 2,
                // auction_only: 1,
                // lot_images: 1,
                orderby: '2',
                page: query.page ? query.page : 1,
                searchbar: query.search ? query.search : '',
                sh_limit: '10',
                type: 'closed',
                // limit: 30,
            }),
        )
        setLoading(true)
    }, [props.location.search])

    useEffect(() => {
        if (props.auctionSearch.auctionData?.results) {
            setPastAuctions(
                props.auctionSearch.auctionData?.results?.length > 0
                    ? props.auctionSearch.auctionData.results
                    : [],
            )
            setTotalCount(
                props.auctionSearch.auctionData?.count ? props.auctionSearch.auctionData.count : '',
            )
            setLoading(false)
        }
    }, [props.auctionSearch.auctionData])

    console.log('auctionSearch', props.auctionSearch.auctionData)

    return (
        <>
            {loading ? (
                <Loader isLoading={loading} />
            ) : (
                <div className="customContainer pastAuctionContainer">
                    <h3 className="text-center pt-5 pb-5">
                        <b>Past Auctions</b>
                    </h3>
                    {pastAuctions.length > 0 ? (
                        <div className="pastGridView">
                            <>
                                {pastAuctions.map((data, index) => (
                                    <GridView
                                        imgSrc={process.env.REACT_APP_PRODUCT_IMG + data.file_name}
                                        Name={data.ptitle}
                                        descrp={data.description}
                                        label="View Auction"
                                        id={data.id}
                                        startTime={data.date_added}
                                        endTime={data.date_closed}
                                        con_check={0}
                                        closedAuction
                                    />
                                ))}
                            </>
                        </div>
                    ) : (
                        <div className="noPdtsFnd text-center">
                            <span className="material-icons">search</span>
                            <p>No Products Found</p>
                        </div>
                    )}
                    <div className="pageNo">
                        {totalCount !== '' && (
                            <Pagination
                                count={getPaginationCount(totalCount, 10)}
                                onChange={(e, value) => {
                                    let query = new URLSearchParams(props.location.search)
                                    query.set('page', value)
                                    history.push({
                                        pathname: props.location.pathname,
                                        search: '?' + query.toString(),
                                    })
                                }}
                                page={query.page ? parseInt(query.page) : 1}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        auctionSearch: state.auctionSearch ? state.auctionSearch : '',
    }
}

export default connect(mapStateToProps, null)(PastAuctions)
