import { CircularProgress } from '@material-ui/core'
import React from 'react'
import './Loader.css'

const Loader = () => {
    return (
        <div className="loaderWrapper">
            <CircularProgress />
        </div>
    )
}

export default Loader
