import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import './Dropdown.css'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}))

export default function Commondropdown(props) {
    const classes = useStyles()

    return (
        <div>
            <FormControl className={`${classes.formControl} dropDown`}>
                <Select
                    value={props.selected}
                    onChange={props.handleChange}
                    className={classes.selectEmpty}
                    name={props.name}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    {props.values.map((data, index) => (
                        <MenuItem value={data.id}>{data.data}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}
