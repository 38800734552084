import axios from '.././Utils/Utility/axiosconfig'
import isAuthenticate from '.././Utils/Utility/auth'

export const Useraction = (list) => ({
    type: 'USER',
    list,
})

export const Userdetails = () => {
    return async (dispatch) => {
        try {
            if (isAuthenticate()) {
                const res = await axios.post('mobileapi/getUserProfileDetails', {})
                if (res.data.success === 'yes') {
                    dispatch(Useraction(res.data.data))
                } else {
                    return `something went wrong`
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
}
