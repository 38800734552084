import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { ListItem } from '@material-ui/core'

const GreenCheckbox = withStyles({
    root: {
        color: 'var(--secColor)',
        '&$checked': {
            color: 'var(--secColor)',
        },
        MuiFormControlLabelRoot: {
            marginBottom: 0,
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />)

const CheckBox = (props) => {
    return (
        <FormGroup row>
            {props.disable_filter !== 'undeifined' && parseInt(props.disable_filter) == 1 ? (
                <div style={{ paddingRight: '50px' }}>
                    <ListItem>{props.label}</ListItem>
                </div>
            ) : (
                <FormControlLabel
                    control={
                        <GreenCheckbox
                            name={props.name}
                            checked={props.checked}
                            value={props.value}
                            onChange={props.onchange ? (e) => props.onchange(e) : null}
                        />
                    }
                    label={props.label && <ListItem button>{props.label}</ListItem>}
                />
            )}
        </FormGroup>
    )
}

export default CheckBox
