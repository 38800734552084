import React, { useState, useEffect } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Dashboard from '../Dashboard/Dashboard'
import Payment from './payment'
import Cards from './cards'
import axios from '../../Utils/Utility/axiosconfig'
import './paymenttabs.css'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
})

const Paymenttabs = (props) => {
    const classes = useStyles()
    const [value, setValue] = React.useState(0)
    const [data, setdata] = useState([])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const buyerTransactions = async () => {
        const res = await axios.post('mobileapi/buyerTransaction')
        try {
            if (res.data.success === 'yes') {
                setdata(res.data.results)
            } else {
                return 'something went wrong'
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        buyerTransactions()
    }, [])

    return (
        <div>
            <Dashboard>
                <div className="Paymentab-Dashboard-Tabpanel">
                    {/* <Tabs value={value} onChange={handleChange}>
                        <Tab label="Payments" />
                        <Tab label="Cards" />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Payment data={data} />
                    </TabPanel> */}
                    {/* <TabPanel value={value} index={1}> */}
                    <Cards />
                    {/* </TabPanel> */}
                </div>
            </Dashboard>
        </div>
    )
}

export default Paymenttabs
